<template>
  <div class="manual-flight-container">
    <div class="journey-details-container">
      <label class="body1-strong">
        {{ $t('journey_details') }}
      </label>
      <div class="journey-details-box">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                v-if="selectedFieldLanguage"
                :prop="`name_${selectedFieldLanguage.toLowerCase()}`"
                :label="`${$t('journey_name')}`"
                class="translation-dialog"
              >
                <ElInput
                  maxlength="40"
                  show-word-limit
                  class="no-background"
                  :placeholder="$t('journey_name_placeholder')"
                  v-model="form[`name_${selectedFieldLanguage.toLowerCase()}`]"
                >
                  <template #prepend>
                    <ElFormItem>
                      <ElSelect
                        v-model="selectedFieldLanguage"
                        :autocomplete="true"
                        :placeholder="$t('select_an_option')"
                        :fallback-placements="['top', 'bottom']"
                        class="no-background"
                        :popper-options="getSelectPopperOptions()"
                        popper-class="custom-selector"
                      >
                        <template #prefix>
                          <img
                            v-if="selectedFieldLanguageFlag"
                            :src="selectedFieldLanguageFlag.flag"
                          />
                        </template>
                        <el-option
                          v-for="item in languages"
                          :key="item.iso_code"
                          :label="item.label"
                          :value="item.iso_code"
                        />
                      </ElSelect>
                    </ElFormItem>
                  </template>
                </ElInput>
                <Icons
                  name="20px/translation"
                  @click="
                    translateField(
                      `name_${selectedFieldLanguage.toLowerCase()}`
                    )
                  "
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="customer_price" :label="$t('total_price')">
                <ElInput
                  v-model="form.customer_price"
                  type="number"
                  class="no-background"
                  min="0"
                  step="0.01"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="currency" :label="$t('currency')">
                <ElSelect
                  v-model="form.currency"
                  :autocomplete="true"
                  :filterable="true"
                  style="width: 100%"
                  :placeholder="$t('select_currency')"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  class="no-background"
                  clearable
                  clear-icon="Close"
                >
                  <el-option
                    v-for="item in currencies"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="6">
              <ElFormItem :label="$t('adults')">
                <div class="first-block">
                  <span class="body2">{{ $t('adults_subtitle') }}</span>
                </div>
                <div class="second-block">
                  <ElInputNumber
                    class="landing-input is-number body2-strong no-background"
                    type="number"
                    step="1"
                    min="1"
                    v-model="form.adults"
                    @change="updatePassengers()"
                  />
                </div>
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="6">
              <ElFormItem :label="$t('children')">
                <div class="first-block">
                  <span class="body2">{{ $t('children_subtitle') }}</span>
                </div>
                <div class="second-block">
                  <ElInputNumber
                    class="landing-input is-number body2-strong no-background"
                    type="number"
                    step="1"
                    min="0"
                    v-model="form.children"
                    @change="updatePassengers()"
                  />
                </div>
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="6">
              <ElFormItem :label="$t('infants')">
                <div class="first-block">
                  <span class="body2">{{ $t('infants_subtitle') }}</span>
                </div>
                <div class="second-block">
                  <ElInputNumber
                    class="landing-input is-number body2-strong no-background"
                    type="number"
                    step="1"
                    min="0"
                    v-model="form.infants"
                    @change="updatePassengers()"
                  />
                </div>
              </ElFormItem>
            </ElCol>
          </ElRow>
        </ElForm>
      </div>
    </div>
    <div class="segments-container">
      <div
        class="segment"
        v-for="(segment, segmentIndex) in form.transport_segments"
        :key="segment.uuid"
      >
        <label class="body1-strong">
          {{ $t('transport_segment') }} {{ segmentIndex + 1 }}
        </label>
        <ElButton
          type="link"
          @click="removeSegment(segment)"
          v-if="segmentIndex > 0"
        >
          {{ $t('delete') }}
        </ElButton>
        <el-collapse accordion v-model="segmentActive">
          <el-collapse-item
            :name="(segmentIndex + 1).toString()"
            :class="getSegmentTitle(segment) ? 'with-title' : ''"
          >
            <template #title>
              <div class="segment-title" v-if="getSegmentTitle(segment)">
                <span class="body2">{{ getSegmentTitle(segment) }}</span>
              </div>
              <Icons
                name="16px/chevron_up"
                class="filter-icon"
                v-if="segmentActive === (segmentIndex + 1).toString()"
              />
              <Icons name="16px/chevron_down" class="filter-icon" v-else />
            </template>
            <div class="content-item">
              <ItinerariesRoutesFlightsSegmentsSegmentForm
                :ref="`segmentRefs-${segment.uuid}`"
                :itinerary-day="itineraryDay"
                :segment="form.transport_segments[segmentIndex]"
                :index="segmentIndex"
                @newSegment="form.transport_segments[segmentIndex] = $event"
              />
              <ElForm
                v-for="(passenger, passengerIndex) in segment.passengers"
                :key="passenger.uuid"
                :ref="
                  (el) =>
                    (passengerRefs[`${segment.uuid}-${passengerIndex}`] = el)
                "
                :model="passenger"
                require-asterisk-position="right"
                :rules="createPassengerRules(segmentIndex, passengerIndex)"
                label-position="top"
              >
                <ElRow :gutter="20">
                  <ElCol :span="width <= 700 ? 24 : 12" :lg="12" class="mt-4">
                    <label class="body1-strong">
                      {{ $t('passenger') }} {{ passengerIndex + 1 }}
                      <ElTag
                        type="info"
                        style="position: relative; top: -1px; left: 5px"
                      >
                        {{ getPassengerType(passenger.passenger_type) }}
                      </ElTag>
                    </label>
                  </ElCol>
                  <ElCol
                    :span="24"
                    :xs="24"
                    :sm="24"
                    :md="24"
                    :lg="24"
                    :xl="24"
                  >
                    <ElFormItem
                      prop="uuid"
                      :label="`${$t('traveller')} (${$t(
                        'optional'
                      ).toLowerCase()})`"
                    >
                      <ElSelect
                        v-model="passenger.uuid"
                        :placeholder="$t('select_a_traveller_or_fill_the_form')"
                        filterable
                        :fallback-placements="['top', 'bottom']"
                        :popper-options="getSelectPopperOptions()"
                        popper-class="custom-selector"
                        class="no-background"
                        @change="
                          selectPassenger($event, segmentIndex, passengerIndex)
                        "
                      >
                        <ElOption
                          v-for="item in passengers"
                          :label="`${item.first_name} ${item.last_name}`"
                          :value="item.uuid"
                          :key="item.uuid"
                        />
                      </ElSelect>
                    </ElFormItem>
                  </ElCol>
                  <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
                    <ElFormItem prop="first_name" :label="$t('first_name')">
                      <ElInput
                        v-model="passenger.first_name"
                        class="no-background"
                      />
                    </ElFormItem>
                  </ElCol>
                  <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
                    <ElFormItem prop="last_name" :label="$t('last_name')">
                      <ElInput
                        v-model="passenger.last_name"
                        class="no-background"
                      />
                    </ElFormItem>
                  </ElCol>
                  <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
                    <ElFormItem
                      prop="ticket_number"
                      :label="$t('ticket_number')"
                    >
                      <ElInput
                        v-model="passenger.ticket_number"
                        class="no-background"
                      />
                    </ElFormItem>
                  </ElCol>
                  <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
                    <ElFormItem
                      prop="voucher_number"
                      :label="$t('voucher_number')"
                    >
                      <ElInput
                        v-model="passenger.voucher_number"
                        class="no-background"
                      />
                    </ElFormItem>
                  </ElCol>
                  <ElCol :span="width <= 700 ? 24 : 6" :lg="6">
                    <ElFormItem prop="seat_class" :label="$t('seat_class')">
                      <ElSelect
                        v-model="passenger.seat_class"
                        :placeholder="$t('select_class')"
                        filterable
                        :fallback-placements="['top', 'bottom']"
                        :popper-options="getSelectPopperOptions()"
                        popper-class="custom-selector"
                        class="no-background"
                      >
                        <ElOption
                          v-for="item in seatClasses"
                          :label="item.label"
                          :value="item.value"
                          :key="item.value"
                        />
                      </ElSelect>
                    </ElFormItem>
                  </ElCol>
                  <ElCol :span="width <= 700 ? 24 : 6" :lg="6">
                    <ElFormItem prop="seat_number" :label="$t('row')">
                      <ElInput
                        v-model="passenger.seat_number"
                        type="number"
                        :placeholder="$t('row_placeholder')"
                        class="no-background"
                      />
                    </ElFormItem>
                  </ElCol>
                  <ElCol :span="width <= 700 ? 24 : 6" :lg="6">
                    <ElFormItem prop="seat_letter" :label="$t('seat_letter')">
                      <ElInput
                        v-model="passenger.seat_letter"
                        :placeholder="$t('seat_letter_placeholder')"
                        class="no-background"
                      />
                    </ElFormItem>
                  </ElCol>
                  <ElCol :span="width <= 700 ? 24 : 6" :lg="6">
                    <ElFormItem
                      prop="is_emergency_exit"
                      :label="$t('emergency_exit')"
                    >
                      <ElCheckbox
                        :model-value="passenger.is_emergency_exit === true"
                        :label="$t('yes')"
                        class="no-background"
                        name="is_emergency_exit"
                        style="position: relative; top: 12px"
                        @change="passenger.is_emergency_exit = true"
                      />
                      <ElCheckbox
                        :model-value="passenger.is_emergency_exit === false"
                        :label="$t('no')"
                        class="no-background"
                        name="is_emergency_exit"
                        style="position: relative; top: 12px"
                        @change="passenger.is_emergency_exit = false"
                      />
                    </ElFormItem>
                  </ElCol>
                  <ElCol :span="24" style="margin-top: 10px">
                    <label class="body1-strong">
                      {{ $t('baggage') }}
                    </label>
                    <ItinerariesRoutesFlightsBaggageListForm
                      :ref="`baggageListRefs-${segmentIndex}-${passengerIndex}-${baggage.uuid}`"
                      v-for="(
                        baggage, baggageIndex
                      ) in passenger.transportBaggages"
                      :key="baggage.uuid"
                      :baggage="baggage"
                      :segment-index="segmentIndex"
                      :baggage-index="baggageIndex"
                      :passenger-index="passengerIndex"
                      @newBaggage="
                        form.transport_segments[segmentIndex].passengers[
                          passengerIndex
                        ].transportBaggages[baggageIndex] = $event
                      "
                      @addBaggage="addBaggage"
                      @removeBaggage="removeBaggage"
                    />
                  </ElCol>
                </ElRow>
              </ElForm>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="actions">
        <ElButton type="secondary" @click="addTransportSegment()">
          {{ $t('add_transport_segment') }}
        </ElButton>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import 'moment/dist/locale/es'
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/de'
import 'moment/dist/locale/sv'
import 'moment/dist/locale/ar'
import { cloneDeep } from 'lodash'
import { ElLoading, ElMessageBox } from 'element-plus'
import CREATE_ROUTE from '~/graphql/routes/mutation/createRoute.gql'
import CREATE_TRANSPORT from '~/graphql/transports/mutation/createTransport.gql'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default defineComponent({
  props: {
    transportTypeUuid: {
      type: String,
      required: false,
      default: ''
    },
    itineraryDayLocation: {
      type: Object,
      default: () => ({})
    },
    itineraryDay: {
      type: Object,
      default: () => ({})
    },
    route: {
      type: String,
      default: ''
    },
    model: {
      type: Object,
      default: () => ({})
    },
    modelType: {
      type: String,
      default: ''
    },
    user: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const { width } = useBreakpoints()
    const { t } = useI18n()
    const ruleFormRef = ref()

    const form = reactive({
      name_es: '',
      name_en: '',
      name_fr: '',
      name_de: '',
      name_sv: '',
      name_ar: '',
      transport_type_uuid: props.transportTypeUuid,
      customer_price: 0,
      paradise_price: 0,
      commission: 0,
      prepayment: false,
      payment_by_customer: false,
      currency: 'eur',
      adults: 1,
      children: 0,
      infants: 0,
      transport_segments: [
        {
          uuid: String(Math.random()).slice(2, 10),
          departure_station_uuid: '',
          departure_station_city: '',
          departure_station_type: 'Airport',
          arrival_station_uuid: '',
          arrival_station_city: '',
          arrival_station_type: 'Airport',
          departure_datetime: props.itineraryDay?.original_date
            ? moment(props.itineraryDay.original_date).add(12, 'hours')
            : moment().startOf('day').add(12, 'hours'),
          arrival_datetime: props.itineraryDay?.original_date
            ? moment(props.itineraryDay.original_date).add(14, 'hours')
            : moment().startOf('day').add(14, 'hours'),
          carrier_type: 'Airline',
          carrier_uuid: '',
          carrier_name: '',
          carrier_code: '',
          segment_number: '',
          passengers: [
            {
              uuid: '',
              first_name: '',
              last_name: '',
              passenger_type: 'ADULT',
              ticket_number: '',
              voucher_number: '',
              seat_letter: '',
              seat_number: '',
              seat_class: '',
              is_emergency_exit: false,
              transportBaggages: [
                {
                  uuid: String(Math.random()).slice(2, 10),
                  baggage_type: '',
                  quantity: 0,
                  weight: 0,
                  height: 0,
                  width: 0,
                  length: 0
                }
              ]
            }
          ]
        }
      ]
    })

    const inputRules = reactive({
      transport_type_uuid: [
        {
          required: true,
          message: t('type_is_required'),
          trigger: ['blur', 'change']
        }
      ]
    })

    const rules = {
      transport_type_uuid: { required }
    }

    const v$ = useVuelidate(rules, form)

    return {
      width,
      t,
      v$,
      inputRules,
      ruleFormRef,
      form,
      ...getFormInitialVariables()
    }
  },
  data() {
    return {
      loadingDialog: null,
      loading: false,
      passengerRefs: {},
      selectedFieldLanguage: 'EN',
      languages: getLanguages(),
      currencies: getCurrencies(),
      passengerTypes: getPassengerTypes(),
      seatClasses: getSeatClasses(),
      segmentActive: '1',
      passengers: []
    }
  },
  computed: {
    selectedFieldLanguageFlag() {
      return this.languages.find(
        (item) => item.iso_code === this.selectedFieldLanguage
      )
    }
  },
  mounted() {
    this.setFieldLanguage()
    this.getItineraryPassengers()
  },
  methods: {
    isUuid(str) {
      const uuidRegex =
        /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
      return uuidRegex.test(str)
    },
    setFieldLanguage() {
      if (getAuthUser()?.value?.language?.toLowerCase() === 'en') {
        this.selectedFieldLanguage = 'EN'
      } else if (getAuthUser()?.value?.language?.toLowerCase() === 'es') {
        this.selectedFieldLanguage = 'ES'
      } else if (getAuthUser()?.value?.language?.toLowerCase() === 'fr') {
        this.selectedFieldLanguage = 'FR'
      } else if (getAuthUser()?.value?.language?.toLowerCase() === 'de') {
        this.selectedFieldLanguage = 'DE'
      } else if (getAuthUser()?.value?.language?.toLowerCase() === 'sv') {
        this.selectedFieldLanguage = 'SV'
      } else if (getAuthUser()?.value?.language?.toLowerCase() === 'ar') {
        this.selectedFieldLanguage = 'AR'
      }
    },
    async translateField(field) {
      try {
        this.loadingDialog = ElLoading.service({
          fullscreen: true,
          background: '#F7F7F7',
          svg: useLoaderSvg(),
          svgViewBox: useLoaderSvgViewBox()
        })
        const response = await mutationTranslation(
          {
            text: this.form[field],
            source_lang: this.getSourceLanguage(field).name,
            target_lang: this.getTargetLang(field)
          },
          this.t,
          this.getSourceLanguage(field).name
        )
        const fieldsByLang = this.fieldsByLang(field)

        if (response?.length) {
          response.forEach((item) => {
            const fieldName = fieldsByLang[item.target_lang]
            if (fieldName) {
              this.form[fieldName] = item.translated_text
            }
          })
          this.$emit('input')
        }
      } catch (err) {
        this.$showError(err, this.t)
        this.$sentry(err, 'translateField')
      } finally {
        this.loadingDialog.close()
      }
    },
    async getItineraryPassengers() {
      try {
        this.loadingDialog = ElLoading.service({
          fullscreen: true,
          background: '#F7F7F7',
          svg: useLoaderSvg(),
          svgViewBox: useLoaderSvgViewBox()
        })
        this.loading = true
        const passengers = await apolloUserPassengers({
          user_uuid: this.user.uuid
        })
        if (!passengers || (passengers && !passengers.getAllPassengers)) {
          throw showError({
            statusCode: 404,
            message: 'Page not found',
            fatal: true
          })
        }
        this.passengers = cloneDeep(passengers?.getAllPassengers?.data ?? [])
      } catch (err) {
        this.$showError(err, this.t)
        this.$sentry(err, 'getItineraryPassengers')
      } finally {
        this.loadingDialog.close()
        this.loading = false
      }
    },
    createPassengerRules(segmentIndex, passengerIndex) {
      return {
        first_name: [
          {
            required: true,
            message: this.$t('first_name_is_required_segment_passenger', {
              segmentIndex: segmentIndex + 1,
              passengerIndex: passengerIndex + 1
            }),
            trigger: ['blur', 'change']
          }
        ],
        last_name: [
          {
            required: true,
            message: this.$t('last_name_is_required_segment_passenger', {
              segmentIndex: segmentIndex + 1,
              passengerIndex: passengerIndex + 1
            }),
            trigger: ['blur', 'change']
          }
        ],
        seat_number: [
          {
            required: true,
            message: this.$t('row_is_required_segment_passenger', {
              segmentIndex: segmentIndex + 1,
              passengerIndex: passengerIndex + 1
            }),
            trigger: ['blur', 'change']
          }
        ],
        seat_letter: [
          {
            required: true,
            message: this.$t('seat_letter_is_required_segment_passenger', {
              segmentIndex: segmentIndex + 1,
              passengerIndex: passengerIndex + 1
            }),
            trigger: ['blur', 'change']
          }
        ]
      }
    },
    async validateSegment(segment) {
      let response = true
      if (
        this.$refs[`segmentRefs-${segment.uuid}`] &&
        this.$refs[`segmentRefs-${segment.uuid}`][0]
      ) {
        response = await this.$refs[
          `segmentRefs-${segment.uuid}`
        ][0].validateForm()
      }
      return response
    },
    async validateAllSegments() {
      const validations = await Promise.all(
        this.form.transport_segments.map((segment) =>
          this.validateSegment(segment)
        )
      )
      return validations.every((isValid) => isValid)
    },
    async addTransportSegment() {
      const isValid = await this.validateAllSegments()
      if (!isValid) {
        return
      }
      this.form.transport_segments.push({
        uuid: String(Math.random()).slice(2, 10),
        departure_station_uuid: '',
        departure_station_city: '',
        departure_station_type: 'Airport',
        arrival_station_uuid: '',
        arrival_station_city: '',
        arrival_station_type: 'Airport',
        departure_datetime: this.itineraryDay?.original_date
          ? moment(this.itineraryDay.original_date).add(12, 'hours')
          : moment().startOf('day').add(12, 'hours'),
        arrival_datetime: this.itineraryDay?.original_date
          ? moment(this.itineraryDay.original_date).add(14, 'hours')
          : moment().startOf('day').add(14, 'hours'),
        carrier_type: 'Airline',
        carrier_uuid: '',
        carrier_name: '',
        carrier_code: '',
        segment_number: '',
        passengers: []
      })
      this.updatePassengers()
      this.segmentActive = String(this.form.transport_segments.length)
    },
    async validatePassenger(segment, passenger, passengerIndex) {
      let response = true
      if (this.passengerRefs[`${segment.uuid}-${passengerIndex}`]) {
        response = await this.passengerRefs[
          `${segment.uuid}-${passengerIndex}`
        ].validate((valid, fields) => {
          if (fields && Object.values(fields)?.length) {
            for (const field of Object.values(fields)) {
              if (field[0]?.message) {
                this.$showError(field[0].message)
                return false
              }
            }
            return true
          }
          return true
        })
      }
      return response
    },
    async validateAllPassengers() {
      const validations = await Promise.all(
        this.form.transport_segments.reduce((acc, segment) => {
          return acc.concat(
            segment.passengers.map((passenger, passengerIndex) =>
              this.validatePassenger(segment, passenger, passengerIndex)
            )
          )
        }, [])
      )
      return validations.every((isValid) => isValid)
    },
    updatePassengers() {
      const totalAdults = this.form.adults
      const totalChildren = this.form.children
      const totalInfants = this.form.infants

      const updateList = (passengerList) => {
        const updateType = (type, count) => {
          let passengersOfType = passengerList.filter(
            (p) => p.passenger_type === type
          )

          if (passengersOfType.length < count) {
            for (let i = passengersOfType.length; i < count; i++) {
              passengerList.push({
                uuid: '',
                first_name: '',
                last_name: '',
                passenger_type: type,
                ticket_number: '',
                voucher_number: '',
                seat_letter: '',
                seat_number: '',
                seat_class: '',
                is_emergency_exit: false,
                transportBaggages: [
                  {
                    uuid: String(Math.random()).slice(2, 10),
                    baggage_type: '',
                    quantity: 0,
                    weight: 0,
                    height: 0,
                    width: 0,
                    length: 0
                  }
                ]
              })
            }
          } else if (passengersOfType.length > count) {
            let excess = passengersOfType.length - count
            let newList = []
            for (let p of passengerList) {
              if (p.passenger_type !== type || excess-- <= 0) {
                newList.push(p)
              }
            }
            passengerList.length = 0
            passengerList.push(...newList)
          }
        }

        updateType('ADULT', totalAdults)
        updateType('CHILD', totalChildren)
        updateType('INFANT', totalInfants)
      }

      this.form.transport_segments.forEach((segment) => {
        updateList(segment.passengers)
      })
    },
    async validateBaggage(segmentIndex, passengerIndex, baggage) {
      let response = true
      if (
        this.$refs[
          `baggageListRefs-${segmentIndex}-${passengerIndex}-${baggage.uuid}`
        ] &&
        this.$refs[
          `baggageListRefs-${segmentIndex}-${passengerIndex}-${baggage.uuid}`
        ][0]
      ) {
        response = await this.$refs[
          `baggageListRefs-${segmentIndex}-${passengerIndex}-${baggage.uuid}`
        ][0].validateForm()
      }
      return response
    },
    async validateAllSegmentPassengerBaggages(segmentIndex, passengerIndex) {
      const validations = await Promise.all(
        this.form.transport_segments[segmentIndex].passengers[
          passengerIndex
        ].transportBaggages.reduce((acc, baggage) => {
          acc.push(this.validateBaggage(segmentIndex, passengerIndex, baggage))
          return acc
        }, [])
      )
      return validations.every((isValid) => isValid)
    },
    async validateAllBaggages() {
      const validations = await Promise.all(
        this.form.transport_segments.reduce((acc, segment, segmentIndex) => {
          return acc.concat(
            segment.passengers.map((_, passengerIndex) =>
              this.validateAllSegmentPassengerBaggages(
                segmentIndex,
                passengerIndex
              )
            )
          )
        }, [])
      )
      return validations.every((isValid) => isValid)
    },
    async addBaggage(segmentIndex, passengerIndex) {
      const isValid = await this.validateAllSegmentPassengerBaggages(
        segmentIndex,
        passengerIndex
      )
      if (!isValid) {
        return
      }
      this.form.transport_segments[segmentIndex].passengers[
        passengerIndex
      ].transportBaggages.push({
        uuid: String(Math.random()).slice(2, 10),
        baggage_type: '',
        quantity: 0,
        weight: 0,
        height: 0,
        width: 0,
        length: 0
      })
    },
    removeBaggage(segmentIndex, passengerIndex, baggage) {
      this.form.transport_segments[segmentIndex].passengers[
        passengerIndex
      ].transportBaggages = this.form.transport_segments[
        segmentIndex
      ].passengers[passengerIndex].transportBaggages.filter(
        (i) => i.uuid !== baggage.uuid
      )
    },
    async validateForm() {
      const isValid = await this.validateAllSegments()
      if (!isValid) {
        return
      }
      const isValidPassengers = await this.validateAllPassengers()
      if (!isValidPassengers) {
        return
      }
      const isValidBaggages = await this.validateAllBaggages()
      if (!isValidBaggages) {
        return
      }
      this.createTransport()
    },
    async createTransport() {
      this.loadingDialog = ElLoading.service({
        fullscreen: true,
        background: '#F7F7F7',
        svg: useLoaderSvg(),
        svgViewBox: useLoaderSvgViewBox()
      })
      this.loading = true
      let variables =
        this.modelType && this.model?.uuid
          ? this.getRouteVariables()
          : this.getTransportVariables()
      const response = await mutation(
        this.modelType && this.model?.uuid ? CREATE_ROUTE : CREATE_TRANSPORT,
        variables
      )
      if (response.error) {
        this.loading = false
        this.loadingDialog.close()
        this.$showError(response.error, this.t)
        this.$sentry(response.error, 'create')
      }
      if (response.result) {
        this.$showSuccess(this.$t('new_entity_success'))
        this.$emit(
          'create',
          this.modelType && this.model?.uuid
            ? response.result?.data?.createRoute ?? null
            : response.result?.data?.createTransport ?? null
        )
        this.loading = false
        this.loadingDialog.close()
      }
    },
    getSegmentTitle(segment) {
      moment.locale('es')
      let text = ''
      if (segment.departure_station_city) {
        text += segment.departure_station_city
      }
      if (segment.arrival_station_city) {
        if (segment.departure_station_city) {
          text += ' - '
        }
        text += segment.arrival_station_city
      }
      if (segment.departure_datetime) {
        if (segment.arrival_station_city || segment.departure_station_city) {
          text += ' / '
        }
        text += moment(segment.departure_datetime).format(
          "ddd, DD MMM 'YY - HH:mm"
        )
      }
      return text
    },
    disabledDepartureDate(date) {
      if (
        moment(date).isSame(moment(this.itineraryDay?.original_date), 'day')
      ) {
        return false
      }
      return true
    },
    removeSegment(segment) {
      this.form.transport_segments = this.form.transport_segments.filter(
        (i) => i.uuid !== segment.uuid
      )
    },
    selectPassenger(event, segmentIndex, passengerIndex) {
      const passengerSelected = this.passengers.find((p) => p.uuid === event)
      this.form.transport_segments[segmentIndex].passengers[
        passengerIndex
      ].uuid = passengerSelected.uuid
      this.form.transport_segments[segmentIndex].passengers[
        passengerIndex
      ].first_name = passengerSelected.first_name
      this.form.transport_segments[segmentIndex].passengers[
        passengerIndex
      ].last_name = passengerSelected.last_name
    },
    getTransportVariables() {
      moment.locale('es')
      let variables = {
        name_es: this.form.name_es,
        name_en: this.form.name_en,
        name_fr: this.form.name_fr,
        name_de: this.form.name_de,
        name_sv: this.form.name_sv,
        name_ar: this.form.name_ar,
        transport_type_uuid: this.form.transport_type_uuid,
        route_uuid: this.route,
        departure_date: this.form.transport_segments[0]?.departure_datetime
          ? moment(this.form.transport_segments[0].departure_datetime).format(
              'YYYY-MM-DD'
            )
          : null,
        departure_time: this.form.transport_segments[0].departure_datetime
          ? moment(this.form.transport_segments[0].departure_datetime).format(
              'HH:mm:00'
            )
          : null,
        arrival_date: this.form.transport_segments[
          this.form.transport_segments.length - 1
        ]?.arrival_datetime
          ? moment(
              this.form.transport_segments[
                this.form.transport_segments.length - 1
              ]?.arrival_datetime
            ).format('YYYY-MM-DD')
          : null,
        arrival_time: this.form.transport_segments[
          this.form.transport_segments.length - 1
        ]?.arrival_datetime
          ? moment(
              this.form.transport_segments[
                this.form.transport_segments.length - 1
              ]?.arrival_datetime
            ).format('HH:mm:00')
          : null,
        travel_days: this.getTravelDays(),
        customer_price: this.form.customer_price
          ? parseInt(this.form.customer_price * 100)
          : 0,
        paradise_price: this.form.paradise_price
          ? parseInt(this.form.paradise_price * 100)
          : 0,
        commission: this.form.commission
          ? parseInt(this.form.commission * 100)
          : 0,
        prepayment: this.form.prepayment,
        payment_by_customer: this.form.payment_by_customer,
        currency: this.form.currency,
        transport_segments: this.form.transport_segments.map(
          (segment, sequenceIndex) => {
            return {
              create: {
                departure_station_uuid: segment.departure_station_uuid,
                departure_station_type: segment.departure_station_type,
                arrival_station_uuid: segment.arrival_station_uuid,
                arrival_station_type: segment.arrival_station_type,
                departure_datetime: segment.departure_datetime
                  ? moment(segment.departure_datetime).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )
                  : null,
                arrival_datetime: segment.arrival_datetime
                  ? moment(segment.arrival_datetime).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )
                  : null,
                carrier_type: segment.carrier_uuid
                  ? segment.carrier_type
                  : null,
                carrier_uuid: segment.carrier_uuid ?? null,
                carrier_code: segment.carrier_uuid
                  ? segment.carrier_code ?? null
                  : null,
                carrier_name: segment.carrier_uuid
                  ? segment.carrier_name ?? null
                  : null,
                sequence: sequenceIndex + 1,
                segment_number: segment.segment_number,
                passengers:
                  segment.passengers?.map((passenger) => {
                    return {
                      create: {
                        passenger: this.isUuid(passenger.uuid)
                          ? {
                              connect: passenger.uuid
                            }
                          : {
                              create: {
                                first_name: passenger.first_name,
                                last_name: passenger.last_name
                              }
                            },
                        passenger_type: passenger.passenger_type,
                        ticket_number: passenger.ticket_number,
                        voucher_number: passenger.voucher_number,
                        transport_segment_passengers: {
                          create: [
                            {
                              seat_number: parseInt(passenger.seat_number),
                              seat_letter: passenger.seat_letter,
                              seat_class: passenger.seat_class,
                              is_emergency_exit: passenger.is_emergency_exit,
                              transportBaggages: {
                                create: passenger.transportBaggages.map(
                                  (baggage) => {
                                    return {
                                      baggage_type: baggage.baggage_type,
                                      quantity: parseInt(baggage.quantity),
                                      weight: parseInt(baggage.weight * 1000),
                                      dimensions: JSON.stringify({
                                        height: parseInt(baggage.height * 100),
                                        width: parseInt(baggage.width * 100),
                                        length: parseInt(baggage.length * 100)
                                      })
                                    }
                                  }
                                )
                              }
                            }
                          ]
                        }
                      }
                    }
                  }) ?? []
              }
            }
          }
        )
      }

      return variables
    },
    getRouteVariables() {
      let variables = {
        model_uuid: this.model.uuid,
        model_type: this.modelType,
        departure_date: this.form.transport_segments[0]?.departure_datetime
          ? moment(this.form.transport_segments[0].departure_datetime).format(
              'YYYY-MM-DD'
            )
          : null,
        departure_time: this.form.transport_segments[0].departure_datetime
          ? moment(this.form.transport_segments[0].departure_datetime).format(
              'HH:mm:00'
            )
          : null,
        arrival_date: this.form.transport_segments[
          this.form.transport_segments.length - 1
        ]?.arrival_datetime
          ? moment(
              this.form.transport_segments[
                this.form.transport_segments.length - 1
              ]?.arrival_datetime
            ).format('YYYY-MM-DD')
          : null,
        arrival_time: this.form.transport_segments[
          this.form.transport_segments.length - 1
        ]?.arrival_datetime
          ? moment(
              this.form.transport_segments[
                this.form.transport_segments.length - 1
              ]?.arrival_datetime
            ).format('HH:mm:00')
          : null,
        travel_days: this.getTravelDays(),
        customer_price: this.form.customer_price
          ? parseInt(this.form.customer_price * 100)
          : 0,
        paradise_price: this.form.paradise_price
          ? parseInt(this.form.paradise_price * 100)
          : 0,
        commission: this.form.commission
          ? parseInt(this.form.commission * 100)
          : 0,
        prepayment: this.form.prepayment,
        payment_by_customer: this.form.payment_by_customer,
        currency: this.form.currency,
        transports: [
          {
            name_es: this.form.name_es,
            name_en: this.form.name_en,
            name_fr: this.form.name_fr,
            name_de: this.form.name_de,
            name_sv: this.form.name_sv,
            name_ar: this.form.name_ar,
            transport_type: {
              connect: this.form.transport_type_uuid
            },
            departure_date: this.form.transport_segments[0]?.departure_datetime
              ? moment(
                  this.form.transport_segments[0].departure_datetime
                ).format('YYYY-MM-DD')
              : null,
            departure_time: this.form.transport_segments[0].departure_datetime
              ? moment(
                  this.form.transport_segments[0].departure_datetime
                ).format('HH:mm:00')
              : null,
            arrival_date: this.form.transport_segments[
              this.form.transport_segments.length - 1
            ]?.arrival_datetime
              ? moment(
                  this.form.transport_segments[
                    this.form.transport_segments.length - 1
                  ]?.arrival_datetime
                ).format('YYYY-MM-DD')
              : null,
            arrival_time: this.form.transport_segments[
              this.form.transport_segments.length - 1
            ]?.arrival_datetime
              ? moment(
                  this.form.transport_segments[
                    this.form.transport_segments.length - 1
                  ]?.arrival_datetime
                ).format('HH:mm:00')
              : null,
            travel_days: this.getTravelDays(),
            price: {
              create: {
                customer_price: this.form.customer_price
                  ? parseInt(this.form.customer_price * 100)
                  : 0,
                paradise_price: this.form.paradise_price
                  ? parseInt(this.form.paradise_price * 100)
                  : 0,
                commission: this.form.commission
                  ? parseInt(this.form.commission * 100)
                  : 0,
                prepayment: this.form.prepayment,
                payment_by_customer: this.form.payment_by_customer,
                currency: this.form.currency
              }
            },
            transport_segments:
              this.getTransportVariables()?.transport_segments ?? []
          }
        ]
      }
      return variables
    },
    getTravelDays() {
      if (
        this.form.transport_segments[0]?.departure_datetime &&
        this.form.transport_segments[this.form.transport_segments.length - 1]
          ?.arrival_datetime
      ) {
        const diffDays = moment(
          this.form.transport_segments[0].departure_datetime
        ).diff(
          moment(
            this.form.transport_segments[
              this.form.transport_segments.length - 1
            ].arrival_datetime
          ),
          'days'
        )
        return parseInt(diffDays ?? 0)
      }
      return 0
    },
    getPassengerType(passengerType) {
      return this.passengerTypes.find((p) => p.value === passengerType).label
    }
  }
})
</script>

<style scoped lang="scss">
.manual-flight-container {
  margin-top: 16px;
  @media (min-width: 1100px) {
    max-height: 66vh;
  }
  @media (max-width: 1100px) {
    max-height: 58vh;
  }
  overflow-y: auto;
  padding-bottom: 5px;
  .journey-details-container {
    .journey-details-box {
      margin-top: 8px;
      padding: 0 24px 24px 24px;
      background-color: var(--brand-off-white);
      :deep(.el-form-item__content) {
        .first-block {
          width: calc(30% - 2px);
          margin-right: 2px;
          span {
            display: block;
            &:nth-of-type(1) {
              color: var(--text-secondary);
            }
          }
        }
        .second-block {
          width: calc(70% - 2px);
        }
      }
    }
  }
  .segments-container {
    .segment {
      margin-top: 8px;
      position: relative;
      .el-button--link {
        position: absolute;
        right: 24px;
        top: 3px;
        color: var(--error-main) !important;
      }
      .el-collapse {
        margin-top: 8px;
        border: 0 !important;
        .el-collapse-item {
          border: 0 !important;
          :deep(.el-collapse-item__header) {
            border: 0 !important;
            border-radius: 8px;
            padding: 24px;
            &.is-active {
              padding: 24px 24px 0 24px;
              border-radius: 8px 8px 0px 0px;
            }
            position: relative;
            text-align: left;
            background-color: var(--brand-off-white) !important;
            i.el-icon {
              svg {
                display: none;
              }
            }
            i.filter-icon {
              position: absolute;
              right: 24px !important;
            }
            .segment-title {
              padding: 12px 12px 12px 16px;
              border-radius: 8px;
              background-color: var(--brand-white);
              color: var(--text-secondary);
              height: 40px;
              .body2 {
                position: relative;
                top: -17px;
              }
            }
          }
          &.with-title:not(.is-active) {
            :deep(.el-collapse-item__header) {
              padding: 48px 24px 48px 24px;
            }
          }
          :deep(.el-collapse-item__wrap) {
            border-radius: 0px 0px 8px 8px;
            padding: 24px;
            border-bottom: 1px solid var(--brand-off-white) !important;
            background-color: var(--brand-off-white) !important;
          }
        }
      }
    }
    .actions {
      margin-top: 20px;
    }
  }
}
</style>