import ALL_TRANSPORT_TYPES from '@/graphql/transport_types/query/getAllTransportTypes.gql'

export function apolloTransportTypes(
  params: any,
  filterExists = false,
  filterSaved = null,
  fetchPolicy = null
) {
  return new Promise(async (resolve, reject) => {
    let apolloQuery
    let variables = {}

    try {
      if (filterExists) {
        const orderBy = {
          column: params.sortBy.toUpperCase() === 'NAME' ? getAuthUser()?.value?.language?.toLowerCase() === 'en'
            ? 'NAME_EN'
            : getAuthUser()?.value?.language?.toLowerCase() === 'es'
              ? 'NAME_ES'
              : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
                ? 'NAME_FR'
                : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                  ? 'NAME_DE'
                  : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                    ? 'NAME_SV'
                    : 'NAME_AR'
            : params.sortBy.toUpperCase(),
          order: params.sortDesc ? 'DESC' : 'ASC',
          first: params.first,
          page: params.page
        }
        let searchField = null
        if (params.search?.length) {
          searchField = {
            field: 'name',
            value: '%' + params.search + '%'
          }
        }
        apolloQuery = generateQuery('getAllTransportTypes', orderBy, searchField, filterSaved, subSelection())
      } else {
        variables = {
          orderBy: [
            {
              column: params.sortBy.toUpperCase() === 'NAME' ? getAuthUser()?.value?.language?.toLowerCase() === 'en'
                ? 'NAME_EN'
                : getAuthUser()?.value?.language?.toLowerCase() === 'es'
                  ? 'NAME_ES'
                  : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
                    ? 'NAME_FR'
                    : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                      ? 'NAME_DE'
                      : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                        ? 'NAME_SV'
                        : 'NAME_AR'
                : params.sortBy.toUpperCase(),
              order: params.sortDesc ? 'DESC' : 'ASC'
            }
          ],
          search: null,
          page: params.page,
          first: params.first
        }
        apolloQuery = ALL_TRANSPORT_TYPES
      }

      resolve(await query(apolloQuery, variables, { fetchPolicy }))
    } catch (err) {
      reject(err)
    }
  })
}

function subSelection() {
  return (
    '{\n' +
    '    data {\n' +
    '      uuid\n' +
    '      code\n' +
    '      name\n' +
    '      name_en\n' +
    '      name_es\n' +
    '      name_fr\n' +
    '      name_de\n' +
    '      name_sv\n' +
    '      name_ar\n' +
    '      image {\n' +
    '        uuid\n' +
    '        url\n' +
    '        file_mime_type\n' +
    '      }\n' +
    '      translated\n' +
    '    }\n' +
    '    paginatorInfo {\n' +
    '      currentPage\n' +
    '      lastPage\n' +
    '      total\n' +
    '    }\n' +
    '  }'
  )
}