<template>
  <ElForm
    v-if="segmentCopy"
    ref="ruleFormRef"
    :model="segmentCopy"
    require-asterisk-position="right"
    :rules="inputRules"
    label-position="top"
  >
    <ElRow :gutter="20">
      <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
        <ElFormItem prop="departure_station_uuid" :label="$t('depart_airport')">
          <el-select-v2
            :key="getAirportKey()"
            v-loading="getAirportKey() === 0"
            v-model="segmentCopy.departure_station_uuid"
            :remote-method="searchAirports"
            :placeholder="$t('search_for_airport')"
            remote
            filterable
            autocomplete
            clearable
            clear-icon="Close"
            class="no-background"
            :fallback-placements="['top', 'bottom']"
            :popper-options="getSelectPopperOptions()"
            popper-class="custom-selector"
            :options="getSearchAirports(segmentCopy.departureStation, null)"
            style="width: 100%"
            @change="setAirportValue($event, 'departure')"
          />
        </ElFormItem>
      </ElCol>
      <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
        <ElFormItem prop="arrival_station_uuid" :label="$t('arrival_airport')">
          <el-select-v2
            :key="getDestinationAirportKey()"
            v-loading="getDestinationAirportKey() === 0"
            v-model="segmentCopy.arrival_station_uuid"
            :remote-method="searchDestinationAirports"
            :placeholder="$t('search_for_airport')"
            remote
            filterable
            autocomplete
            clearable
            clear-icon="Close"
            class="no-background"
            :fallback-placements="['top', 'bottom']"
            :popper-options="getSelectPopperOptions()"
            popper-class="custom-selector"
            :options="
              getSearchDestinationAirports(segmentCopy.arrivalStation, null)
            "
            style="width: 100%"
            @change="setAirportValue($event, 'arrival')"
          />
        </ElFormItem>
      </ElCol>
      <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
        <ElFormItem
          prop="departure_datetime"
          :label="$t('departure_date_and_time')"
        >
          <ElDatePicker
            v-model="segmentCopy.departure_datetime"
            class="!w-full no-background"
            type="datetime"
            format="DD/MM/YYYY HH:mm"
            placeholder="dd/mm/yyyy, --:--"
            popper-class="custom-datepicker"
            :editable="false"
            :clearable="false"
            :disabled-date="disabledDepartureDate"
            @input="$emit('newSegment', segmentCopy)"
          />
        </ElFormItem>
      </ElCol>
      <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
        <ElFormItem
          prop="arrival_datetime"
          :label="$t('arrival_date_and_time')"
        >
          <ElDatePicker
            v-model="segmentCopy.arrival_datetime"
            class="!w-full no-background"
            type="datetime"
            format="DD/MM/YYYY HH:mm"
            placeholder="dd/mm/yyyy, --:--"
            popper-class="custom-datepicker"
            :editable="false"
            :clearable="false"
            @input="$emit('newSegment', segmentCopy)"
          />
        </ElFormItem>
      </ElCol>
      <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
        <ElFormItem prop="carrier_uuid" :label="$t('airline')">
          <el-select-v2
            :key="getAirlineKey()"
            v-loading="getAirlineKey() === 0"
            v-model="segmentCopy.carrier_uuid"
            :remote-method="searchAirlines"
            :placeholder="$t('search_for_airline')"
            remote
            filterable
            autocomplete
            clearable
            clear-icon="Close"
            class="no-background"
            :fallback-placements="['top', 'bottom']"
            :popper-options="getSelectPopperOptions()"
            popper-class="custom-selector"
            :options="getSearchAirlines(segmentCopy.carrier, null)"
            style="width: 100%"
            @change="setAirlineValue($event)"
          />
        </ElFormItem>
      </ElCol>
      <ElCol :span="width <= 700 ? 24 : 12" :lg="12">
        <ElFormItem prop="segment_number" :label="$t('segment_number')">
          <ElInput
            class="no-background"
            :placeholder="$t('segment_number_placeholder')"
            v-model="segmentCopy.segment_number"
            @input="$emit('newSegment', segmentCopy)"
          />
        </ElFormItem>
      </ElCol>
    </ElRow>
  </ElForm>
</template>

<script>
import { cloneDeep } from 'lodash'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import 'moment/dist/locale/es'
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/de'
import 'moment/dist/locale/sv'
import 'moment/dist/locale/ar'

export default defineComponent({
  props: {
    index: {
      type: Number,
      required: false,
      default: 0
    },
    segment: {
      type: Object,
      required: false,
      default: () => {}
    },
    itineraryDay: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  setup(props) {
    const { width } = useBreakpoints()
    const ruleFormRef = ref(null)
    const { t } = useI18n()

    const inputRules = reactive({
      departure_station_uuid: [
        {
          required: true,
          message: t('origin_is_required_segment', {
            segmentIndex: props.index + 1
          }),
          trigger: ['blur', 'change']
        }
      ],
      arrival_station_uuid: [
        {
          required: true,
          message: t('destination_is_required_segment', {
            segmentIndex: props.index + 1
          }),
          trigger: ['blur', 'change']
        }
      ],
      departure_datetime: [
        {
          required: true,
          message: t('depart_date_is_required_segment', {
            segmentIndex: props.index + 1
          }),
          trigger: ['blur', 'change']
        }
      ],
      arrival_datetime: [
        {
          required: true,
          message: t('arrival_date_is_required_segment', {
            segmentIndex: props.index + 1
          }),
          trigger: ['blur', 'change']
        }
      ]
    })

    return {
      width,
      ruleFormRef,
      inputRules
    }
  },
  data() {
    return {
      segmentCopy: null
    }
  },
  watch: {
    segment: {
      handler() {
        if (this.segment) {
          this.segmentCopy = cloneDeep(this.segment)
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.segment) {
      this.segmentCopy = cloneDeep(this.segment)
      if (this.segmentCopy.departureStation) {
        searchAirports(
          this.segmentCopy.departureStation.name,
          this.segmentCopy.departureStation,
          null
        )
      }
      if (this.segmentCopy.arrivalStation) {
        searchDestinationAirports(
          this.segmentCopy.arrivalStation.name,
          this.segmentCopy.arrivalStation,
          null
        )
      }
    }
  },
  methods: {
    disabledDepartureDate(date) {
      if (
        moment(date).isSame(moment(this.itineraryDay?.original_date), 'day')
      ) {
        return false
      }
      return true
    },
    setAirportValue(event, field) {
      if (field === 'departure' && getSearchAirports()?.length && event) {
        const airport = getSearchAirports().find((item) => item.value === event)
        this.segmentCopy[`${field}_station_city`] = airport.city
        this.segmentCopy[`${field}_station_uuid`] = event
        this.$emit('newSegment', this.segmentCopy)
      } else if (field === 'departure' && !event) {
        this.segmentCopy[`${field}_station_city`] = ''
        this.segmentCopy[`${field}_station_uuid`] = ''
        this.$emit('newSegment', this.segmentCopy)
      } else if (
        field === 'arrival' &&
        getSearchDestinationAirports()?.length &&
        event
      ) {
        const airport = getSearchDestinationAirports().find(
          (item) => item.value === event
        )
        this.segmentCopy[`${field}_station_city`] = airport.city
        this.segmentCopy[`${field}_station_uuid`] = event
        this.$emit('newSegment', this.segmentCopy)
      } else if (field === 'arrival' && !event) {
        this.segmentCopy[`${field}_station_city`] = ''
        this.segmentCopy[`${field}_station_uuid`] = ''
        this.$emit('newSegment', this.segmentCopy)
      }
    },

    setAirlineValue(event) {
      if (event && getSearchAirlines()?.length) {
        const airline = getSearchAirlines().find((item) => item.value === event)
        this.segmentCopy.carrier_uuid = event
        this.segmentCopy.carrier_name = airline.label
        this.segmentCopy.carrier_code = airline.iata_code
        this.$emit('newSegment', this.segmentCopy)
      }
    },
    async validateForm() {
      let response = true
      response = await this.ruleFormRef.validate((valid, fields) => {
        if (fields && Object.values(fields)?.length) {
          for (const field of Object.values(fields)) {
            if (field[0]?.message) {
              this.$showError(field[0].message)
              return false
            }
          }
          return true
        }
        return true
      })
      return response
    }
  }
})
</script>

<style>
</style>