<template>
  <div v-if="reservationCopy" class="passenger-form">
    <div class="form-close">
      <Icons name="24px/close" @click="$emit('close')" />
    </div>
    <div class="form-title">
      <span class="dashboard-heading">
        {{ $t('passengers') }}
      </span>
    </div>
    <el-collapse accordion v-model="passengerAccordion" v-if="reservationCopy">
      <el-collapse-item
        v-for="(passenger, index) in reservationCopy.passengers"
        :key="index"
        :name="(index + 1).toString()"
      >
        <template #title>
          <span class="dashboard-title">
            {{ $t(passenger.passengerType.toLowerCase()) }}
            {{ parseInt(passenger.passengerTypeIndex) + 1 }}
          </span>
          <Icons
            name="16px/chevron_up"
            class="filter-icon"
            v-if="passengerAccordion === '1'"
          />
          <Icons name="16px/chevron_down" class="filter-icon" v-else />
        </template>
        <div class="content-item">
          <ElRow :gutter="20">
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div class="info-passenger">
                <Icons name="16px/info_main" />
                <span class="small">
                  {{
                    $t('enter_names_exactly_as_they_appear_on_travel_documents')
                  }}
                </span>
              </div>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                prop="uuid"
                :label="`${$t('traveller')} (${$t('optional').toLowerCase()})`"
              >
                <ElSelect
                  v-model="passenger.uuid"
                  :placeholder="$t('select_a_traveller_or_fill_the_form')"
                  filterable
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  @change="selectPassenger($event, index)"
                >
                  <ElOption
                    v-for="item in passengers"
                    :label="`${item.first_name} ${item.last_name}`"
                    :value="item.uuid"
                    :key="item.uuid"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
              <ElFormItem :label="`${$t('name')} *`">
                <ElInput v-model="passenger.first_name" />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
              <ElFormItem :label="`${$t('last_name')} *`">
                <ElInput v-model="passenger.last_name" />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                :label="`${$t('email')} ${
                  passenger.passengerType === 'ADULT' ? '*' : ''
                }`"
              >
                <ElInput v-model="passenger.email" />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
              <ElFormItem
                :label="`${$t('phone')} ${
                  passenger.passengerType === 'ADULT' ? '*' : ''
                }`"
              >
                <ElInput
                  v-model="passenger.phone"
                  class="landing-input prepend body2-strong"
                  type="tel"
                  :placeholder="$t('phone')"
                >
                  <template #prepend>
                    <ElSelect
                      v-model="passenger.phone_country"
                      filterable
                      placeholder=""
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                    >
                      <template #prefix>
                        <img
                          v-if="getPassengerPhoneCountryFlag(passenger)"
                          :src="getPassengerPhoneCountryFlag(passenger).flag"
                        />
                      </template>
                      <ElOptionGroup
                        v-for="(group, index) in countries"
                        :key="index"
                      >
                        <ElOption
                          :class="
                            passenger.phone_country === item.phone_country
                              ? 'selected'
                              : ''
                          "
                          v-for="item in group.options"
                          label=""
                          :value="item.phone_country"
                          :key="item.iso_code"
                        >
                          <template #default>
                            <img :src="item.flag" :alt="item.iso_code" />
                            <span
                              >{{ item.iso_code }} ({{
                                item.phone_country
                              }})</span
                            >
                          </template>
                        </ElOption>
                      </ElOptionGroup>
                    </ElSelect>
                  </template>
                </ElInput>
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
              <ElFormItem :label="`${$t('gender')} *`">
                <ElSelect
                  v-model="passenger.gender"
                  placeholder=""
                  filterable
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                >
                  <ElOption
                    v-for="item in genders"
                    :label="item.label"
                    :value="item.value"
                    :key="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem :label="`${$t('date_of_birth')} *`">
                <ElDatePicker
                  class="!w-full"
                  placeholder="DD/MM/YYYY"
                  v-model="passenger.date_of_birth"
                  type="date"
                  :disabled-date="
                    ($event) => isDisableDateOfBirth(passenger, $event)
                  "
                  format="DD/MM/YYYY"
                  popper-class="custom-datepicker"
                  :editable="false"
                  :clearable="false"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
              <ElFormItem :label="`${$t('document_type')} *`">
                <ElSelect
                  v-model="passenger.document_type_country"
                  placeholder=""
                  style="width: 70px; display: inline-block"
                  filterable
                  class="prepend"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                >
                  <template #prefix>
                    <img
                      v-if="getPassengerDocumentTypeCountry(passenger)"
                      :src="getPassengerDocumentTypeCountry(passenger).flag"
                    />
                  </template>
                  <ElOptionGroup
                    v-for="(group, index) in countries"
                    :key="index"
                  >
                    <ElOption
                      :class="
                        passenger.document_type_country === item.iso_code
                          ? 'selected'
                          : ''
                      "
                      v-for="item in group.options"
                      label=""
                      :value="item.iso_code"
                      :key="item.iso_code"
                    >
                      <template #default>
                        <img :src="item.flag" :alt="item.iso_code" />
                        <span>{{ item.iso_code }}</span>
                      </template>
                    </ElOption>
                  </ElOptionGroup>
                </ElSelect>
                <ElSelect
                  v-model="passenger.document_type"
                  :placeholder="$t('select_a_document_type')"
                  style="width: calc(100% - 70px); display: inline-block"
                  class="append"
                  filterable
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                >
                  <ElOption
                    v-for="item in documentTypesArray"
                    :label="`${t(
                      'document_types.' + item.label.toLowerCase()
                    )}`"
                    :value="item.value"
                    :key="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
              <ElFormItem :label="`${$t('document_number')} *`">
                <ElInput v-model="passenger.document_number" />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem :label="`${$t('expiration_date')}`">
                <ElDatePicker
                  class="!w-full"
                  :class="passenger.no_expiry ? 'no-background' : ''"
                  placeholder="DD/MM/YYYY"
                  v-model="passenger.expiration_date"
                  :disabled="passenger.no_expiry"
                  type="date"
                  format="DD/MM/YYYY"
                  popper-class="custom-datepicker"
                  :editable="false"
                  :clearable="false"
                />
                <ElCheckbox
                  class="no-expiry-checkbox"
                  v-model="passenger.no_expiry"
                  @change="passenger.expiration_date = null"
                />
                <div class="no-expiry-label">
                  <label class="caption">{{ $t('no_expiry') }}</label>
                </div>
              </ElFormItem>
            </ElCol>
          </ElRow>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import 'moment-timezone'
import 'moment/dist/locale/es'
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/de'
import 'moment/dist/locale/sv'
import 'moment/dist/locale/ar'

export default defineComponent({
  props: {
    reservation: {
      type: Object,
      required: false,
      default: () => {}
    },
    passengers: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  setup() {
    const { t } = useI18n()

    return {
      t,
      genders: getGenders().filter((i) => i.value.toLowerCase() !== 'other'),
      countries: getModelCountries(),
      ...getFormInitialVariables(),
      documentTypesArray: getDocumentTypes(),
      passengerTypes: getPassengerTypes()
    }
  },
  data() {
    return {
      reservationCopy: null,
      passengerAccordion: '1'
    }
  },
  watch: {
    reservation(newVal) {
      this.reservationCopy = cloneDeep(newVal)
    }
  },
  mounted() {
    if (this.reservation) {
      this.reservationCopy = cloneDeep(this.reservation)
    }
  },
  methods: {
    validateForm() {
      for (let i = 0; i < this.reservationCopy.passengers.length; i++) {
        const passenger = this.reservationCopy.passengers[i]
        if (!passenger.first_name) {
          this.$showError(this.$t('name_is_required'))
          this.passengerAccordion = `${i + 1}`
          return false
        }
        if (!passenger.last_name) {
          this.$showError(this.$t('last_name_is_required'))
          this.passengerAccordion = `${i + 1}`
          return false
        }
        if (!passenger.date_of_birth) {
          this.$showError(this.$t('date_of_birth_is_required'))
          this.passengerAccordion = `${i + 1}`
          return false
        }
        if (passenger.passengerType === 'ADULT' && !passenger.phone) {
          this.$showError(this.$t('phone_is_required'))
          this.passengerAccordion = `${i + 1}`
          return false
        }
        if (
          passenger.passengerType === 'ADULT' &&
          !phoneNumber(passenger.phone, passenger)
        ) {
          this.$showError(this.$t('invalid_phone'))
          this.passengerAccordion = `${i + 1}`
          return false
        }
        if (passenger.passengerType === 'ADULT' && !passenger.email) {
          this.$showError(this.$t('email_is_required'))
          this.passengerAccordion = `${i + 1}`
          return false
        }
        if (
          passenger.passengerType === 'ADULT' &&
          !this.isValidEmail(passenger.email)
        ) {
          this.$showError(this.$t('invalid_email'))
          this.passengerAccordion = `${i + 1}`
          return false
        }
        if (!passenger.gender) {
          this.$showError(this.$t('gender_is_required'))
          this.passengerAccordion = `${i + 1}`
          return false
        }
        if (!passenger.document_type_country) {
          this.$showError(this.$t('document_type_country_is_required'))
          this.passengerAccordion = `${i + 1}`
          return false
        }
        if (!passenger.document_type) {
          this.$showError(this.$t('document_type_is_required'))
          this.passengerAccordion = `${i + 1}`
          return false
        }
        if (!passenger.document_number) {
          this.$showError(this.$t('document_number_is_required'))
          this.passengerAccordion = `${i + 1}`
          return false
        }
        if (
          passenger.document_number &&
          !documentNumber(passenger.document_number, passenger)
        ) {
          this.$showError(this.$t('document_number_not_valid'))
          this.passengerAccordion = `${i + 1}`
          return false
        }
      }
      return true
    },
    getPassengers() {
      return this.reservationCopy.passengers
    },
    getPassengerPhoneCountryFlag(passenger) {
      for (const elem of this.countries) {
        const country = elem.options.find((item) =>
          passenger.phone_country === '+1'
            ? passenger.phone && this.isCanadianNumber(passenger.phone)
              ? item.iso_code === 'CA'
              : item.iso_code === 'US'
            : item.phone_country === passenger.phone_country
        )
        if (country) {
          return country
        }
      }
      return null
    },
    getPassengerDocumentTypeCountry(passenger) {
      for (const elem of this.countries) {
        const country = elem.options.find(
          (item) => item.iso_code === passenger.document_type_country
        )
        if (country) {
          return country
        }
      }
      return null
    },
    isValidEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    isDisableDateOfBirth(passenger, time) {
      moment.locale('es')
      let birthDate = moment(time)
      let now = moment()

      let twelveYearsAgo = now.clone().subtract(12, 'years')
      let twoYearsAgo = now.clone().subtract(2, 'years')

      switch (passenger.passengerType.toLowerCase()) {
        case 'adult':
          return birthDate.isAfter(twelveYearsAgo)
        case 'child':
          return (
            birthDate.isAfter(twoYearsAgo) || birthDate.isBefore(twelveYearsAgo)
          )
        case 'infant':
          return birthDate.isBefore(twoYearsAgo) || birthDate.isAfter(now)
        default:
          return true
      }
    },
    selectPassenger(event, index) {
      const passengerSelected = this.passengers.find((p) => p.uuid === event)
      this.reservationCopy.passengers[index].uuid = passengerSelected.uuid
      this.reservationCopy.passengers[index].first_name =
        passengerSelected.first_name
      this.reservationCopy.passengers[index].last_name =
        passengerSelected.last_name
      this.reservationCopy.passengers[index].date_of_birth =
        passengerSelected.date_of_birth
      this.reservationCopy.passengers[index].phone = passengerSelected.phone
      this.reservationCopy.passengers[index].phone_country =
        passengerSelected.phone_country ?? '+1'
      this.reservationCopy.passengers[index].email = passengerSelected.email
      this.reservationCopy.passengers[index].gender = passengerSelected.gender
      if (passengerSelected.documents?.length) {
        this.reservationCopy.passengers[index].document_type =
          passengerSelected.documents[0].type
        this.reservationCopy.passengers[index].document_type_country =
          passengerSelected.documents[0].country
        this.reservationCopy.passengers[index].document_number =
          passengerSelected.documents[0].number
        this.reservationCopy.passengers[index].expiration_date =
          passengerSelected.documents[0].expiry_date
      }
    }
  }
})
</script>

<style scoped lang="scss">
.passenger-form {
  max-height: 80vh;
  overflow-y: auto;
  @media (max-width: 1100px) {
    padding: 0 20px 20px 20px;
  }
  .form-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .form-title {
    margin-top: 20px;
    color: var(--brand-off-black);
  }
  .el-collapse {
    margin-top: 20px;
    border: 0 !important;
    .el-collapse-item {
      border: 0 !important;
      margin-bottom: 24px;
      :deep(.el-collapse-item__header) {
        border: 0 !important;
        position: relative;
        text-align: left;
        background-color: var(--brand-white) !important;
        i.el-icon {
          svg {
            display: none;
          }
        }
        i.filter-icon {
          position: absolute;
          right: 0 !important;
        }
      }
      :deep(.el-collapse-item__wrap) {
        border-bottom: 1px solid var(--brand-off-white) !important;
        background-color: var(--brand-white) !important;
        .content-item {
          .el-row {
            .el-col {
              .info-passenger {
                background-color: var(--info-light);
                padding: 8px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                i {
                  display: inline-block;
                  margin-right: 5px;
                }
                span.small {
                  display: inline-block;
                  color: var(--info-main);
                  vertical-align: top;
                }
              }
              .el-form-item {
                flex-direction: column;
                .el-form-item__label {
                  display: block;
                }
                .el-form-item__content {
                  display: block;
                  .no-expiry-checkbox {
                    display: inline-block;
                    margin-top: 16px;
                    margin-right: 8px;
                  }
                  .no-expiry-label {
                    position: relative;
                    top: -5px;
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>