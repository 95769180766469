import ALL_AIRLINES from '@/graphql/airlines/query/getAllAirlines.gql'

export function apolloAirlines(
  params: any,
  filterExists = false,
  filterSaved = null,
  fetchPolicy = null
) {
  return new Promise(async (resolve, reject) => {
    let apolloQuery
    let variables = {}
    try {
      if (filterExists) {
        const orderBy = {
          column: params.sortBy.toUpperCase(),
          order: params.sortDesc ? 'DESC' : 'ASC',
          first: params.first,
          page: params.page
        }
        let searchField = null
        if (params.search?.length) {
          searchField = {
            field: 'search',
            value: '%' + params.search + '%'
          }
        }
        apolloQuery = generateQuery('getAllAirlines', orderBy, searchField, filterSaved, subSelection())
      } else {
        variables = {
          orderBy: [
            {
              column: params.sortBy.toUpperCase(),
              order: params.sortDesc ? 'DESC' : 'ASC'
            }
          ],
          search: '%' + params.search + '%',
          page: params.page,
          first: params.first
        }
        apolloQuery = ALL_AIRLINES
      }

      resolve(await query(apolloQuery, variables, { fetchPolicy }))
    } catch (err) {
      reject(err)
    }
  })
}

function subSelection() {
  return (
    '{\n' +
    '    data {\n' +
    '      uuid\n' +
    '      name\n' +
    '      iata_code\n' +
    '    }\n' +
    '    paginatorInfo {\n' +
    '      currentPage\n' +
    '      lastPage\n' +
    '      total\n' +
    '    }\n' +
    '  }'
  )
}
