import ALL_TOURS from '@/graphql/airlines/query/getAllAirlines.gql'

const airlines = ref<{label: string; value: string, iata_code: string}[]>([])
const key = ref(0)
const firstTime = ref(true)

export async function airlinesAutocomplete(params: any) {
  const apolloQuery = ALL_TOURS
  const variables = {
    search: '%' + params.search + '%',
    orderBy: [
      {
        column: 'NAME',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchAirlinesCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await airlinesAutocomplete({ search: queryString })
    cb(results?.getAllAirlines?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchAirlinesCb')
  }
}

export function getSearchAirlines(entityAirline: any, entityAirlines: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchAirlines('', entityAirline, entityAirlines)
  }

  return airlines?.value ?? []
}

export async function searchAirlines(queryString: any, entityAirline: any, entityAirlines: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await airlinesAutocomplete({ search: queryString })
    const remoteAirlines = results?.getAllAirlines?.data?.map((i: any) => {
      return {
        label: i.name,
        value: i.uuid,
        iata_code: i.iata_code
      }
    }) ?? []
    const airlinesToAdd: { label: any; value: any }[] = []
    entityAirlines?.forEach((j: any) => {
      if (!remoteAirlines.find((k: any) => k.value === j.uuid)) {
        airlinesToAdd.push({
          label: j.name,
          value: j.uuid,
          iata_code: j.iata_code
        })
      }
    })
    if (entityAirline && !remoteAirlines.find((k: any) => k.value === entityAirline.uuid)) {
      airlinesToAdd.push({
        label: entityAirline.name,
        value: entityAirline.uuid,
        iata_code: entityAirline.iata_code
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    airlines.value = [...remoteAirlines, ...airlinesToAdd]

    firstTime.value = false

    if (entityAirline) {
      getSearchAirlines(entityAirline, entityAirlines)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchAirlines')
  }
}

export function getAirlineKey () {
  return key.value
}

export function initialAirlines () {
  airlines.value = []
  key.value = 0
  firstTime.value = true
}