<template>
  <div class="filter-airlines-container">
    <div class="search">
      <ElInput
        :placeholder="$t('search_airlines')"
        v-model="querySearch"
        v-loading="loading"
        @input="debounce(() => getAirlines(true))"
        @keydown.enter.prevent="getAirlines(true)"
      >
        <template #suffix>
          <Icons name="16px/search" @click="getAirlines(true)" />
        </template>
      </ElInput>
    </div>
    <div class="any">
      <ElSwitch
        v-model="anyAirline"
        :disabled="anyAirline"
        @change="selectAnyAirline()"
      >
        <template #inactive-action>
          <Icons name="16px/close_gray" />
        </template>
        <template #active-action>
          <Icons name="16px/check" />
        </template>
      </ElSwitch>
      <div class="any-text">
        <span class="body2">
          {{ $t('any') }}
        </span>
      </div>
    </div>
    <div v-for="airline in airlines ?? []" :key="airline.uuid" class="airlines">
      <ElCheckbox
        class="background-blue rounded xl"
        v-model="airline.selected"
        @change="selectedAirline(airline)"
      />
      <span class="body2"> {{ airline.name }} ({{ airline.iata_code }}) </span>
    </div>
  </div>
</template>

<script>
import { ElLoading, ElMessageBox } from 'element-plus'
import { useI18n } from 'vue-i18n'
import { cloneDeep } from 'lodash'

export default defineComponent({
  setup() {
    const { width, height } = useBreakpoints()
    const { t } = useI18n()
    const { $sentry } = useNuxtApp()
    const router = useRouter()
    const route = useRoute()
    const config = useRuntimeConfig()
    const { debounce } = getTableInitialVariables()

    const initialVariables = []

    let objectToReturn = {
      width,
      height,
      t,
      router,
      route,
      config,
      $sentry,
      debounce
    }
    for (const variable of initialVariables) {
      objectToReturn = Object.assign(objectToReturn, variable)
    }

    return objectToReturn
  },
  data() {
    return {
      airlines: null,
      loading: false,
      loadingDialog: null,
      querySearch: null,
      filterAirlines: {},
      anyAirline: true
    }
  },
  mounted() {
    this.getAirlines()
  },
  methods: {
    async getAirlines(refetch = false) {
      try {
        this.loadingDialog = ElLoading.service({
          fullscreen: true,
          background: '#F7F7F7',
          svg: useLoaderSvg(),
          svgViewBox: useLoaderSvgViewBox()
        })
        this.loading = true
        const airlines = await apolloAirlines(
          {
            sortBy: 'NAME',
            sortDesc: false,
            search: this.querySearch,
            page: 1,
            first: 10
          },
          true,
          this.filterAirlines,
          refetch ? 'network-only' : null
        )

        this.airlines = airlines?.getAllAirlines?.data?.map((i) => {
          return {
            ...i,
            selected: false
          }
        })
      } catch (err) {
        this.$showError(err, this.t)
        this.$sentry(err, 'getAirlines')
      } finally {
        this.loading = false
        this.loadingDialog.close()
      }
    },
    selectedAirline(airline) {
      this.airlines = this.airlines.map((i) => {
        if (airline.uuid === i.uuid) {
          return {
            ...i,
            selected: i.selected ? true : false
          }
        }
        return i
      })
      this.anyAirline = this.airlines.filter((i) => i.selected).length
        ? false
        : true
      this.$emit(
        'selectedAirline',
        this.airlines.filter((i) => i.selected).map((i) => i.iata_code) ?? []
      )
    },
    selectAnyAirline() {
      this.airlines = this.airlines.map((i) => {
        return {
          ...i,
          selected: false
        }
      })
      this.$emit('selectedAirline', [])
    }
  }
})
</script>

<style scoped lang="scss">
.filter-airlines-container {
  max-height: 300px;
  padding-bottom: 10px;
  overflow-y: auto;
  .search {
    margin-bottom: 16px;
  }
  .any {
    margin-bottom: 8px;
    .el-switch {
      display: inline-block;
      width: 44px;
    }
    .any-text {
      display: inline-block;
      position: relative;
      top: 2px;
      margin-left: 8px;
    }
  }
  .airlines {
    display: flex;
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
    span.body2 {
      margin-left: 8px;
    }

    padding-top: 16px;
  }
}
</style>