<template>
  <div v-if="reservationCopy" class="baggage-form">
    <div class="form-close">
      <Icons name="24px/close" @click="$emit('close')" />
    </div>
    <div class="form-title">
      <span class="dashboard-heading">
        {{ $t('baggage') }}
      </span>
    </div>
    <div class="form-subtitle">
      <span class="dashboard-title">
        {{ $t('flight_from_to', { from: step - 1, to: servicesSteps }) }}
      </span>
    </div>
    <div
      class="baggage-included"
      v-if="
        reservationCopy.flight[getFlightType()]?.baggageAllowance?.hand[0]
          ?.quantity ||
        reservationCopy.flight[getFlightType()]?.baggageAllowance?.checked[0]
          ?.quantity
      "
    >
      <div class="included-title">
        <span class="dashboard-title">
          {{ $t('carry_on_bundle') }}
        </span>
      </div>
      <div class="included-icon">
        <Icons name="24px/baggage_0" />
      </div>
      <div class="included-subtitle">
        <span class="body2">
          {{ $t('included') }}
        </span>
      </div>
      <div
        class="included-items"
        v-for="type in ['hand', 'checked']"
        :key="type"
      >
        <div
          class="included-item"
          v-if="
            reservationCopy.flight[getFlightType()]?.baggageAllowance?.[type][0]
              ?.quantity
          "
        >
          <span class="small">
            {{
              reservationCopy.flight[getFlightType()]?.baggageAllowance?.[
                type
              ][0]?.description
            }}
            <span
              v-if="
                getBagWeight(
                  reservationCopy.flight[getFlightType()]?.baggageAllowance?.[
                    type
                  ][0]
                )
              "
            >
              ({{
                getBagWeight(
                  reservationCopy.flight[getFlightType()]?.baggageAllowance?.[
                    type
                  ][0]
                )
              }})
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="baggage-extra">
      <div class="extra-title">
        <span class="body1-strong">
          {{ $t('improvement_options') }}
        </span>
      </div>
      <div class="extra-list">
        <div
          class="extra-item"
          v-for="item in flightServices"
          :key="item.service_id"
        >
          <div class="item-title">
            <span class="body2-strong">
              {{ getBagTitle(item.descriptions[0]) }}
            </span>
          </div>
          <div class="item-icon">
            <Icons name="24px/baggage_1" />
          </div>
          <div class="item-subtitle">
            <span class="small">
              {{ getBagSubtitle(item.descriptions[0]) }}
            </span>
          </div>
          <div
            class="item-passenger"
            v-for="(passenger, index) in item.passenger_types"
            :key="index"
          >
            <div class="name">
              <label class="body2-strong">
                {{ passenger.first_name }} {{ passenger.last_name }}
              </label>
            </div>
            <div class="price">
              <div class="first-block">
                <span class="body2-strong">
                  {{ passenger.price
                  }}{{ getCurrencySymbol(passenger.currency) }}
                </span>
              </div>
              <div class="second-block">
                <ElInputNumber
                  class="no-background"
                  type="number"
                  step="1"
                  min="0"
                  max="1"
                  v-model="passenger.quantity"
                  @change="changePassengerBagQuantity(item, passenger)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'

export default defineComponent({
  props: {
    reservation: {
      type: Object,
      required: false,
      default: () => {}
    },
    step: {
      type: Number,
      required: false,
      default: 0
    },
    servicesSteps: {
      type: Number,
      required: false,
      default: 0
    },
    services: {
      type: Array,
      required: false,
      default: []
    }
  },
  watch: {
    reservation(newVal) {
      this.reservationCopy = cloneDeep(newVal)
    },
    step() {
      this.setServices()
    }
  },
  data() {
    return {
      reservationCopy: null,
      flightServices: []
    }
  },
  mounted() {
    if (this.reservation) {
      this.reservationCopy = cloneDeep(this.reservation)
      this.setServices()
    }
  },
  methods: {
    getSegment() {
      const segments = [
        ...(this.reservationCopy.flight.outbound?.segments ?? []),
        ...(this.reservationCopy.flight.return?.segments ?? [])
      ]
      return segments[this.step - 2]
    },
    getFlightType() {
      const segment = this.getSegment()
      if (
        this.reservationCopy.flight.outbound.segments.find(
          (i) => i.segment_id === segment.segment_id
        )
      ) {
        return 'outbound'
      }
      if (
        this.reservationCopy.flight.return.segments.find(
          (i) => i.segment_id === segment.segment_id
        )
      ) {
        return 'return'
      }
      return 'outbound'
    },
    setServices() {
      const segment = this.getSegment()
      const passengers =
        this.services?.find((i) => i.segment_code === segment.segment_id)
          ?.passengers ?? []
      const services = []
      if (passengers?.length) {
        for (let [index, passenger] of passengers.entries()) {
          for (let service of passenger.services) {
            const serviceIndex = services.findIndex(
              (i) => i.service_id === service.service_id
            )
            const reservationPassenger =
              this.reservationCopy?.passengers?.find(
                (i) =>
                  i.passengerType === passenger.passenger_type &&
                  parseInt(i.index) === index
              ) ?? []
            const quantity =
              reservationPassenger.services?.find(
                (j) =>
                  j.serviceId === service.service_id &&
                  j.segmentReference === segment.segment_id
              )?.quantity ?? 0
            const passengerType = {
              index: reservationPassenger.index,
              first_name: reservationPassenger.first_name,
              last_name: reservationPassenger.last_name,
              price: service.price.consumer_price
                ? Math.round(parseFloat(service.price.consumer_price / 100))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                : 0,
              currency: service.price.consumer_currency?.toLowerCase() ?? 'usd',
              quantity
            }
            if (serviceIndex !== -1) {
              services[serviceIndex].passenger_types.push(passengerType)
            } else {
              services.push({
                ...service,
                segment_id: segment.segment_id,
                passenger_types: [passengerType]
              })
            }
          }
        }
      }
      this.flightServices = services
    },
    getBagTitle(description) {
      return description.split(':')[0]
    },
    getBagSubtitle(description) {
      return description.split(':')[1]
    },
    changePassengerBagQuantity(item, passenger) {
      const passengerIndex = this.reservationCopy.passengers.findIndex(
        (i) => i.index === passenger.index
      )
      if (passengerIndex !== -1) {
        const segmentServiceIndex = this.reservationCopy.passengers[
          passengerIndex
        ].services.findIndex(
          (i) =>
            i.serviceId === item.service_id &&
            i.segmentReference === item.segment_id
        )
        if (segmentServiceIndex !== -1) {
          if (passenger.quantity) {
            this.reservationCopy.passengers[passengerIndex].services[
              segmentServiceIndex
            ].quantity = passenger.quantity
          } else {
            this.reservationCopy.passengers[passengerIndex].services.splice(
              segmentServiceIndex,
              1
            )
          }
        } else {
          if (passenger.quantity) {
            this.reservationCopy.passengers[passengerIndex].services.push({
              segmentReference: item.segment_id,
              serviceId: item.service_id,
              quantity: passenger.quantity
            })
          }
        }
      }
    },
    getPassengers() {
      return this.reservationCopy.passengers
    },
    getBagWeight(bag) {
      const kgObject = bag.properties?.find(
        (i) => i.unit_of_measurement.toLowerCase() === 'kg'
      )
      return kgObject
        ? `${kgObject?.value}${kgObject?.unit_of_measurement.toLowerCase()}`
        : ''
    }
  }
})
</script>

<style scoped lang="scss">
.baggage-form {
  max-height: 80vh;
  overflow-y: auto;
  @media (max-width: 1100px) {
    padding: 0 20px 20px 20px;
  }
  .form-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .form-title {
    margin-top: 20px;
    color: var(--brand-off-black);
  }
  .form-subtitle {
    margin-top: 20px;
    color: var(--brand-off-black);
  }
  .baggage-included {
    margin-top: 24px;
    padding: 16px;
    background-color: var(--brand-off-white);
    position: relative;
    .included-subtitle {
      margin-top: 4px;
      margin-bottom: 4px;
    }
    .included-icon {
      position: absolute;
      top: 16px;
      right: 16px;
    }
    .included-items {
      .included-item {
        .small {
          color: var(--text-secondary);
        }
      }
    }
  }
  .baggage-extra {
    .extra-title {
      margin-top: 24px;
      margin-bottom: 24px;
    }
    .extra-list {
      .extra-item {
        margin-top: 16px;
        padding: 16px;
        background-color: var(--brand-off-white);
        position: relative;
        .item-subtitle {
          margin-top: 4px;
          margin-bottom: 8px;
        }
        .item-icon {
          position: absolute;
          top: 16px;
          right: 16px;
        }
        .item-passenger {
          margin-top: 24px;
          .name {
            margin-bottom: 8px;
          }
          .price {
            .first-block {
              display: inline-block;
              @media (min-width: 1100px) {
                width: 10%;
              }
              @media (max-width: 1100px) {
                width: 20%;
              }
            }
            .second-block {
              display: inline-block;
              width: 50%;
            }
          }
        }
      }
    }
  }
}
</style>