<template>
  <div>
    <el-dialog
      :model-value="value"
      :close-on-click-modal="false"
      :width="width <= 600 ? '100%' : '80vw'"
      @open="$emit('open')"
      @close="$emit('close')"
      modal-class="itinerary-transport-dialog"
      :header-class="
        form.transport_type_code === 'FLIGHT' && flightForm ? 'flight-list' : ''
      "
      :body-class="
        form.transport_type_code === 'FLIGHT' && flightForm ? 'flight-list' : ''
      "
      :footer-class="
        form.transport_type_code === 'FLIGHT' && flightForm ? 'flight-list' : ''
      "
    >
      <template #header v-if="!flightForm && !flightReservation">
        <span class="dashboard-title">
          {{ dialogTitle }}
        </span>
      </template>
      <ElFormItem :label="$t('select_type')" v-if="!flightForm">
        <ElButtonGroup>
          <el-tooltip
            v-for="item of transportTypes?.getAllTransportTypes?.data"
            :key="item"
            effect="dark"
            popper-class="custom-tooltip"
            :content="item.name"
            placement="top"
          >
            <ElButton
              type="group"
              @click.prevent.stop="selectTransportType(item)"
              :class="form.transport_type_uuid === item.uuid ? 'active' : ''"
            >
              <Icons
                :name="
                  item.uuid === form.transport_type_uuid
                    ? `20px/${item.code.toLowerCase()}_filled-selected`
                    : `20px/${item.code.toLowerCase()}_filled`
                "
              />
            </ElButton>
          </el-tooltip>
        </ElButtonGroup>
      </ElFormItem>
      <ElFormItem
        v-if="
          form.transport_type_code === 'FLIGHT' &&
          !flightForm &&
          !flightReservation
        "
      >
        <ElCheckbox
          class="already-flights-booked-checkbox"
          v-model="alreadyFlightsBooked"
        />
        <div class="already-flights-booked-label">
          <label class="caption">{{
            $t('i_have_already_flight_booked')
          }}</label>
        </div>
      </ElFormItem>
      <ItinerariesRoutesFlightsSearchFlightForm
        ref="searchFlightForm"
        :itinerary-day="itineraryDay"
        @flightForm="flightForm = $event"
        v-if="
          !alreadyFlightsBooked &&
          form.transport_type_code.toUpperCase() === 'FLIGHT' &&
          !flightForm &&
          !flightReservation
        "
      />
      <ItinerariesRoutesFlightsManualFlightForm
        ref="manualFlightForm"
        :transport-type-uuid="form.transport_type_uuid"
        :model="model"
        :user="user"
        :route="route"
        :itinerary-day="itineraryDay"
        :itinerary-day-location="itineraryDayLocation"
        @create="$emit('create')"
        model-type="ItineraryDayLocation"
        v-if="
          alreadyFlightsBooked &&
          form.transport_type_code.toUpperCase() === 'FLIGHT' &&
          !flightForm &&
          !flightReservation
        "
      />
      <ItinerariesRoutesFlightsFlightList
        ref="flightList"
        :flight-form="flightForm"
        @back="back()"
        @flightForm="flightForm = $event"
        @confirmBooking="flightReservation = $event"
        v-if="
          form.transport_type_code.toUpperCase() === 'FLIGHT' &&
          flightForm &&
          !flightReservation
        "
      />
      <ItinerariesRoutesFlightsFlightReservationForm
        ref="flightReservationForm"
        :flight-form="flightForm"
        :reservation="flightReservation"
        :itinerary-day-location="itineraryDayLocation"
        :user="user"
        @back="back()"
        @create="$emit('create')"
        @close="$emit('close')"
        @isLastStep="flightIsLastStep = $event"
        v-if="
          form.transport_type_code.toUpperCase() === 'FLIGHT' &&
          flightForm &&
          flightReservation
        "
      />
      <ElRow
        :gutter="20"
        justify="center"
        align="middle"
        v-if="form.transport_type_code !== 'FLIGHT'"
      >
        <ElCol :span="24">
          <ElForm
            require-asterisk-position="right"
            ref="ruleFormRef"
            :rules="inputRules"
            :model="form"
            label-position="top"
          >
            <ElRow :gutter="20">
              <ElCol
                :span="24"
                :style="
                  width <= 1100
                    ? 'max-height: 62vh; overflow-y: auto'
                    : 'max-height: 70vh; overflow-y: auto'
                "
              >
                <el-carousel
                  indicator-position="outside"
                  arrow="never"
                  :autoplay="false"
                  trigger="click"
                  height="auto"
                  @change="
                    ;(titleMode = $event),
                      (passengerSelected = form.transport_passengers.length - 1)
                  "
                >
                  <el-carousel-item
                    style="max-height: 65vh; height: 65vh; overflow-y: auto"
                  >
                    <ElRow :gutter="20">
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem
                          v-if="selectedFieldLanguage"
                          :prop="`name_${selectedFieldLanguage.toLowerCase()}`"
                          :label="`${$t('name')}`"
                          class="translation-dialog"
                        >
                          <ElInput
                            maxlength="40"
                            show-word-limit
                            v-model="
                              form[
                                `name_${selectedFieldLanguage.toLowerCase()}`
                              ]
                            "
                            @keydown.enter.prevent="$emit('validateForm')"
                          >
                            <template #prepend>
                              <ElFormItem>
                                <ElSelect
                                  v-model="selectedFieldLanguage"
                                  :autocomplete="true"
                                  :placeholder="$t('select_an_option')"
                                  :fallback-placements="['top', 'bottom']"
                                  :popper-options="getSelectPopperOptions()"
                                  popper-class="custom-selector"
                                >
                                  <template #prefix>
                                    <img
                                      v-if="selectedFieldLanguageFlag"
                                      :src="selectedFieldLanguageFlag.flag"
                                    />
                                  </template>
                                  <el-option
                                    v-for="item in languages"
                                    :key="item.iso_code"
                                    :label="item.label"
                                    :value="item.iso_code"
                                  />
                                </ElSelect>
                              </ElFormItem>
                            </template>
                          </ElInput>
                          <Icons
                            name="20px/translation"
                            @click="
                              translateField(
                                `name_${selectedFieldLanguage.toLowerCase()}`
                              )
                            "
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem
                          prop="company_uuid"
                          :label="$t('company')"
                          class="autocomplete-new-entity"
                          :class="
                            form.company_uuid ? 'autocomplete-redirection' : ''
                          "
                        >
                          <el-select-v2
                            :key="getCompanyKey()"
                            v-loading="getCompanyKey() === 0"
                            v-model="form.company_uuid"
                            :remote-method="searchCompanies"
                            :placeholder="$t('select_a_company')"
                            remote
                            filterable
                            autocomplete
                            clearable
                            clear-icon="Close"
                            :fallback-placements="['top', 'bottom']"
                            :popper-options="getSelectPopperOptions()"
                            popper-class="custom-selector"
                            :options="getSearchCompanies()"
                            style="width: 100%"
                          />
                          <Icons
                            name="24px/group_purple"
                            class="new-entity-button"
                            @click="showNewCompanyDialog = true"
                          />
                          <Icons
                            v-if="form.company_uuid"
                            name="24px/arrow_top_right_purple"
                            class="new-entity-button"
                            @click="
                              newWindowItem('/finances/companies', {
                                uuid: form.company_uuid
                              })
                            "
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem
                          prop="origin_point"
                          :label="$t('origin_point')"
                        >
                          <ElSelect
                            v-model="form.origin_point"
                            :placeholder="$t('select_an_option')"
                            filterable
                            :fallback-placements="['top', 'bottom']"
                            :popper-options="getSelectPopperOptions()"
                            popper-class="custom-selector"
                            class="!w-full no-multiple"
                          >
                            <ElOption
                              v-for="item in addressLocationTypes"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </ElSelect>
                        </ElFormItem>
                      </ElCol>
                      <ElCol
                        :span="containerWidth <= 700 ? 24 : 12"
                        :lg="12"
                        v-if="form.origin_point === 'ADDRESS'"
                      >
                        <ElFormItem
                          prop="origin_formatted_address"
                          :label="`${$t('address')} *`"
                        >
                          <GMapAutocomplete
                            id="originGoogleAutoComplete"
                            class="googleAutoComplete pac-target-input"
                            :class="
                              originFormattedAddressInvalid ? 'invalid' : ''
                            "
                            :placeholder="`${$t('enter_an_origin_address')}`"
                            v-model="form.origin_formatted_address"
                            @place_changed="
                              ;(address = setGeolocation($event)),
                                updateAddress(address, 'origin')
                            "
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol
                        :span="containerWidth <= 700 ? 24 : 12"
                        :lg="12"
                        v-if="form.origin_point === 'LOCATION'"
                      >
                        <ElFormItem
                          prop="origin_uuid"
                          :label="`${$t('location')} *`"
                          class="autocomplete-new-entity"
                          :class="
                            form.origin_uuid ? 'autocomplete-redirection' : ''
                          "
                        >
                          <el-select-v2
                            :key="getLocationKey()"
                            v-loading="getLocationKey() === 0"
                            v-model="form.origin_uuid"
                            :remote-method="searchLocations"
                            :placeholder="$t('select_a_location')"
                            remote
                            filterable
                            autocomplete
                            clearable
                            clear-icon="Close"
                            :fallback-placements="['top', 'bottom']"
                            :popper-options="getSelectPopperOptions()"
                            popper-class="custom-selector"
                            :options="getSearchLocations()"
                            style="width: 100%"
                          />
                          <Icons
                            name="24px/group_purple"
                            class="new-entity-button"
                            @click="showNewOriginDialog = true"
                          />
                          <Icons
                            v-if="form.origin_uuid"
                            name="24px/arrow_top_right_purple"
                            class="new-entity-button"
                            @click="
                              newWindowItem('/locations/locations', {
                                uuid: form.origin_uuid
                              })
                            "
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem
                          prop="destination_point"
                          :label="$t('destination_point')"
                        >
                          <ElSelect
                            v-model="form.destination_point"
                            :placeholder="$t('select_an_option')"
                            filterable
                            :fallback-placements="['top', 'bottom']"
                            :popper-options="getSelectPopperOptions()"
                            popper-class="custom-selector"
                            class="!w-full no-multiple"
                          >
                            <ElOption
                              v-for="item in addressLocationTypes"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </ElSelect>
                        </ElFormItem>
                      </ElCol>
                      <ElCol
                        :span="containerWidth <= 700 ? 24 : 12"
                        :lg="12"
                        v-if="form.destination_point === 'ADDRESS'"
                      >
                        <ElFormItem
                          prop="destination_formatted_address"
                          :label="`${$t('address')} *`"
                        >
                          <GMapAutocomplete
                            id="destinationGoogleAutoComplete"
                            class="googleAutoComplete pac-target-input"
                            :class="
                              destinationFormattedAddressInvalid
                                ? 'invalid'
                                : ''
                            "
                            :placeholder="`${$t(
                              'enter_a_destination_address'
                            )}`"
                            v-model="form.destination_formatted_address"
                            @place_changed="
                              ;(address = setGeolocation($event)),
                                updateAddress(address, 'destination')
                            "
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol
                        :span="containerWidth <= 700 ? 24 : 12"
                        :lg="12"
                        v-if="form.destination_point === 'LOCATION'"
                      >
                        <ElFormItem
                          prop="destination_uuid"
                          :label="`${$t('location')} *`"
                          class="autocomplete-new-entity"
                          :class="
                            form.destination_uuid
                              ? 'autocomplete-redirection'
                              : ''
                          "
                        >
                          <el-select-v2
                            :key="getDestinationsKey()"
                            v-loading="getDestinationsKey() === 0"
                            v-model="form.destination_uuid"
                            :remote-method="searchDestinations"
                            :placeholder="$t('select_a_location')"
                            remote
                            filterable
                            autocomplete
                            clearable
                            clear-icon="Close"
                            :fallback-placements="['top', 'bottom']"
                            :popper-options="getSelectPopperOptions()"
                            popper-class="custom-selector"
                            :options="getSearchDestinations()"
                            style="width: 100%"
                          />
                          <Icons
                            name="24px/group_purple"
                            class="new-entity-button"
                            @click="showNewDestinationDialog = true"
                          />
                          <Icons
                            v-if="form.destination_uuid"
                            name="24px/arrow_top_right_purple"
                            class="new-entity-button"
                            @click="
                              newWindowItem('/locations/locations', {
                                uuid: form.destination_uuid
                              })
                            "
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol
                        :span="containerWidth <= 700 ? 24 : 12"
                        :lg="12"
                        v-if="props.itineraryDay?.original_date"
                      >
                        <ElFormItem
                          prop="departure_date"
                          :label="$t('departure_date')"
                        >
                          <ElDatePicker
                            v-model="form.departure_date"
                            class="!w-full"
                            type="date"
                            format="DD/MM/YYYY"
                            popper-class="custom-datepicker"
                            :editable="false"
                            :clearable="false"
                            @change="setTravelDays('date')"
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem
                          prop="departure_time"
                          :label="$t('departure_time')"
                        >
                          <ElTimePicker
                            v-model="form.departure_time"
                            class="!w-full"
                            popper-class="custom-timepicker"
                            format="HH:mm"
                            :editable="false"
                            :clearable="false"
                            @change="setEstimatedTravelTime()"
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol
                        :span="containerWidth <= 700 ? 24 : 12"
                        :lg="12"
                        v-if="props.itineraryDay?.original_date"
                      >
                        <ElFormItem
                          prop="arrival_date"
                          :label="$t('arrival_date')"
                        >
                          <ElDatePicker
                            v-model="form.arrival_date"
                            class="!w-full"
                            type="date"
                            format="DD/MM/YYYY"
                            popper-class="custom-datepicker"
                            :editable="false"
                            :clearable="false"
                            @change="setTravelDays('date')"
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem
                          prop="arrival_time"
                          :label="$t('arrival_time')"
                        >
                          <ElTimePicker
                            v-model="form.arrival_time"
                            class="!w-full"
                            popper-class="custom-timepicker"
                            format="HH:mm"
                            :editable="false"
                            :clearable="false"
                            @change="setEstimatedTravelTime()"
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem :label="$t('travel_days')">
                          <ElInputNumber
                            v-model="form.travel_days"
                            type="number"
                            min="0"
                            @change="setTravelDays('number')"
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem prop="wheelchair" :label="$t('wheelchair')">
                          <ElSwitch v-model="form.wheelchair" />
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem
                          prop="estimated_travel_time"
                          :label="$t('estimated_travel_time')"
                        >
                          <ElTimePicker
                            v-model="form.estimated_travel_time"
                            class="!w-full"
                            popper-class="custom-timepicker"
                            format="HH:mm"
                            :editable="false"
                            :clearable="false"
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem
                          prop="transport_number"
                          :label="$t('transport_number')"
                        >
                          <ElInput
                            v-model="form.transport_number"
                            @change="hasBeenUpdated = true"
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem
                          prop="reservation_number"
                          :label="$t('reservation_number')"
                        >
                          <ElInput
                            v-model="form.reservation_number"
                            @change="hasBeenUpdated = true"
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol
                        v-if="width > 700"
                        :span="containerWidth <= 700 ? 24 : 12"
                        :lg="12"
                      />
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem
                          prop="customer_price"
                          :label="$t('customer_price')"
                        >
                          <ElInputNumber
                            v-model="form.customer_price"
                            type="number"
                            min="0"
                            step="0.01"
                            @keydown.enter.prevent="submit(ruleFormRef)"
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem
                          prop="paradise_price"
                          :label="$t('paradise_price')"
                        >
                          <ElInputNumber
                            v-model="form.paradise_price"
                            type="number"
                            min="0"
                            step="0.01"
                            @keydown.enter.prevent="submit(ruleFormRef)"
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem prop="commission" :label="$t('commission')">
                          <ElInputNumber
                            v-model="form.commission"
                            type="number"
                            min="0"
                            step="0.01"
                            @keydown.enter.prevent="submit(ruleFormRef)"
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem prop="currency" :label="$t('currency')">
                          <ElSelect
                            v-model="form.currency"
                            :autocomplete="true"
                            :filterable="true"
                            style="width: 100%"
                            :placeholder="$t('select_currency')"
                            :fallback-placements="['top', 'bottom']"
                            :popper-options="getSelectPopperOptions()"
                            popper-class="custom-selector"
                            clearable
                            clear-icon="Close"
                          >
                            <el-option
                              v-for="item in currencies"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            />
                          </ElSelect>
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem prop="prepayment" :label="$t('prepayment')">
                          <ElSwitch v-model="form.prepayment" />
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem
                          prop="payment_by_customer"
                          :label="$t('payment_by_customer')"
                        >
                          <ElSwitch v-model="form.payment_by_customer" />
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                        <ElFormItem
                          prop="agent_uuid"
                          :label="$t('agent')"
                          class="autocomplete-new-entity"
                        >
                          <el-select-v2
                            :key="getAgentKey()"
                            v-loading="getAgentKey() === 0"
                            v-model="form.agent_uuid"
                            :remote-method="searchAgents"
                            :placeholder="$t('select_an_agent')"
                            remote
                            filterable
                            autocomplete
                            clearable
                            clear-icon="Close"
                            :fallback-placements="['top', 'bottom']"
                            :popper-options="getSelectPopperOptions()"
                            popper-class="custom-selector"
                            :options="getSearchAgents(form.agent)"
                            style="width: 100%"
                          />
                          <Icons
                            name="24px/group_purple"
                            class="new-entity-button"
                            @click="showNewAgentDialog = true"
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol
                        :span="containerWidth <= 700 ? 24 : 12"
                        :lg="12"
                        v-if="width > 1100"
                      />
                      <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="24">
                        <ElFormItem
                          v-if="selectedFieldLanguage"
                          prop="notes"
                          :label="$t('notes')"
                          class="translation-dialog"
                        >
                          <ElSelect
                            v-model="selectedFieldLanguage"
                            :autocomplete="true"
                            :placeholder="$t('select_an_option')"
                            :fallback-placements="['top', 'bottom']"
                            :popper-options="getSelectPopperOptions()"
                            popper-class="custom-selector"
                          >
                            <template #prefix>
                              <img
                                v-if="selectedFieldLanguageFlag"
                                :src="selectedFieldLanguageFlag.flag"
                              />
                            </template>
                            <el-option
                              v-for="item in languages"
                              :key="item.iso_code"
                              :label="item.label"
                              :value="item.iso_code"
                            />
                          </ElSelect>
                          <div class="textarea-container">
                            <ElInput
                              v-model="
                                form[
                                  `notes_${selectedFieldLanguage.toLowerCase()}`
                                ]
                              "
                              :autosize="autosize"
                              type="textarea"
                              maxlength="3000"
                              show-word-limit
                              @keydown.enter.prevent="submit(ruleFormRef)"
                            />
                            <Icons
                              name="20px/translation"
                              @click="
                                translateField(
                                  `notes_${selectedFieldLanguage.toLowerCase()}`
                                )
                              "
                            />
                          </div>
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="24">
                        <ElFormItem
                          prop="description"
                          :label="$t('description')"
                        >
                          <ElInput
                            v-model="form.description"
                            :autosize="autosize"
                            type="textarea"
                          />
                        </ElFormItem>
                      </ElCol>
                      <ElCol :span="24">
                        <ElFormItem :label="$t('documents')">
                          <FilesDrawer
                            :files="form.computedDocuments"
                            @detachFiles="detachFiles"
                            @uploadImages="uploadImages"
                          />
                        </ElFormItem>
                      </ElCol>
                    </ElRow>
                  </el-carousel-item>
                  <el-carousel-item style="height: 480px; padding-right: 10px">
                    <ElRow :gutter="20">
                      <ElCol :span="24" style="height: 400px; overflow-y: auto">
                        <ElRow :gutter="20">
                          <ElCol
                            :span="24"
                            v-for="(
                              passenger, index2
                            ) in form.transport_passengers"
                            :key="index2"
                            :xs="24"
                            :sm="24"
                            :md="24"
                            :lg="24"
                            :xl="24"
                          >
                            <ElRow
                              :gutter="20"
                              v-if="index2 !== passengerSelected"
                              class="passenger-list"
                            >
                              <div class="passenger-number not-shown">
                                <span class="caption-strong">{{
                                  index2 + 1
                                }}</span>
                              </div>
                              <div class="passenger-info">
                                <div class="passenger-title">
                                  <span class="body2-strong"
                                    >{{ passenger.passenger_name }}
                                  </span>
                                </div>
                                <div class="passenger-subtitle">
                                  <span class="small">
                                    {{ passenger.voucher_number }}
                                  </span>
                                </div>
                              </div>
                              <div class="passenger-actions">
                                <Icons
                                  name="16px/pen"
                                  @click="passengerSelected = index2"
                                />
                                <Icons
                                  name="16px/trash_red"
                                  @click="removePassenger(index2)"
                                />
                              </div>
                            </ElRow>
                            <ElRow
                              :gutter="20"
                              v-if="index2 === passengerSelected"
                            >
                              <ElCol :span="24" v-if="index2 !== 0">
                                <div class="passenger-number">
                                  <span class="caption-strong">{{
                                    index2 + 1
                                  }}</span>
                                </div>
                              </ElCol>
                              <ElCol
                                :span="24"
                                :xs="24"
                                :sm="24"
                                :md="24"
                                :lg="24"
                                :xl="24"
                              >
                                <ElFormItem
                                  prop="passenger_uuid"
                                  :label="`${$t('passenger')} *`"
                                  class="autocomplete-new-entity"
                                >
                                  <el-select-v2
                                    :key="getPasengersByUserKey()"
                                    v-loading="getPasengersByUserKey() === 0"
                                    v-model="passenger.passenger_uuid"
                                    :remote-method="searchPassengersByUser"
                                    :disabled="
                                      getSearchPassengersByUser().length === 0
                                    "
                                    :placeholder="$t('select_a_passenger')"
                                    remote
                                    filterable
                                    autocomplete
                                    :fallback-placements="['top', 'bottom']"
                                    :popper-options="getSelectPopperOptions()"
                                    popper-class="custom-selector"
                                    :options="getSearchPassengersByUser()"
                                    style="width: 100%"
                                    @change="
                                      getPassengerName(
                                        passenger.passenger_uuid
                                      ),
                                        (hasBeenUpdated = true)
                                    "
                                  />
                                  <Icons
                                    name="24px/group_purple"
                                    class="new-entity-button"
                                    @click="showNewPassengerDialog = true"
                                  />
                                </ElFormItem>
                              </ElCol>
                              <ElCol
                                :span="24"
                                :xs="24"
                                :sm="24"
                                :md="24"
                                :lg="24"
                                :xl="24"
                              >
                                <ElFormItem
                                  prop="voucher_number"
                                  :label="$t('voucher_number')"
                                >
                                  <ElInput
                                    v-model="passenger.voucher_number"
                                    @change="hasBeenUpdated = true"
                                  />
                                </ElFormItem>
                              </ElCol>
                            </ElRow>
                          </ElCol>
                        </ElRow>
                      </ElCol>
                      <ElCol :span="24" class="cancel-button">
                        <ElButton type="link" @click="submitPassenger()">
                          {{ $t('add_another_passenger') }}
                        </ElButton>
                      </ElCol>
                    </ElRow>
                  </el-carousel-item>
                </el-carousel>
              </ElCol>
            </ElRow>
          </ElForm>
        </ElCol>
      </ElRow>
      <template #footer>
        <ElRow :gutter="20">
          <ElCol v-if="width > 700" :span="16" :lg="16" />
          <ElCol
            :span="width <= 700 ? 24 : 4"
            :lg="4"
            class="cancel-button"
            style="margin-top: 0px !important"
            v-if="
              form.transport_type_code !== 'FLIGHT' ||
              (form.transport_type_code === 'FLIGHT' &&
                !flightForm &&
                !flightReservation)
            "
          >
            <ElButton
              style="width: 100%"
              type="secondary"
              @click="$emit('cancel')"
            >
              {{ $t('cancel') }}
            </ElButton>
          </ElCol>
          <ElCol
            :span="width <= 700 ? 24 : 4"
            :lg="4"
            class="cancel-button"
            style="margin-top: 0px !important"
            v-if="
              form.transport_type_code === 'FLIGHT' &&
              flightForm &&
              !flightReservation
            "
          >
            <ElButton style="width: 100%" type="secondary" @click="back()">
              {{ $t('back') }}
            </ElButton>
          </ElCol>
          <ElCol
            :span="width <= 700 ? 24 : 4"
            :lg="4"
            class="cancel-button"
            :class="width <= 700 ? 'mb-3' : ''"
            v-if="form.transport_type_code === 'FLIGHT' && flightReservation"
          >
            <ElButton
              style="width: 100%"
              type="secondary"
              @click="$refs.flightReservationForm.back()"
            >
              {{ $t('back') }}
            </ElButton>
          </ElCol>
          <ElCol
            :span="width <= 700 ? 24 : 4"
            :lg="4"
            class="confirm-button"
            :style="
              width <= 1100
                ? 'margin-top: 16px !important'
                : 'margin-top: 0px !important'
            "
            v-if="form.transport_type_code !== 'FLIGHT'"
          >
            <ElButton
              :loading="loading"
              :disabled="loading"
              type="primary"
              style="width: 100%"
              @click="submit(ruleFormRef)"
            >
              {{ $t('save') }}
            </ElButton>
          </ElCol>
          <ElCol
            :span="width <= 700 ? 24 : 4"
            :lg="4"
            class="confirm-button"
            :style="
              width <= 1100
                ? 'margin-top: 16px !important'
                : 'margin-top: 0px !important'
            "
            v-if="
              !alreadyFlightsBooked &&
              form.transport_type_code === 'FLIGHT' &&
              !flightReservation
            "
          >
            <ElButton
              :loading="loading"
              :disabled="loading"
              type="primary"
              style="width: 100%"
              @click="
                flightForm
                  ? $refs.flightList.searchFlights(true)
                  : $refs.searchFlightForm.validateForm()
              "
            >
              {{ $t('search_flights') }}
            </ElButton>
          </ElCol>
          <ElCol
            :span="width <= 700 ? 24 : 4"
            :lg="4"
            class="confirm-button"
            :style="
              width <= 1100
                ? 'margin-top: 16px !important'
                : 'margin-top: 0px !important'
            "
            v-if="alreadyFlightsBooked && form.transport_type_code === 'FLIGHT'"
          >
            <ElButton
              :loading="loading"
              :disabled="loading"
              type="primary"
              style="width: 100%"
              @click="$refs.manualFlightForm.validateForm()"
            >
              {{ $t('save') }}
            </ElButton>
          </ElCol>
          <ElCol
            :span="width <= 700 ? 24 : 4"
            :lg="4"
            class="confirm-button"
            v-if="
              !alreadyFlightsBooked &&
              form.transport_type_code === 'FLIGHT' &&
              flightForm &&
              flightReservation
            "
          >
            <ElButton
              :loading="loading"
              :disabled="loading"
              type="primary"
              style="width: 100%"
              @click="$refs.flightReservationForm.next()"
            >
              {{ flightIsLastStep ? $t('save') : $t('next') }}
            </ElButton>
          </ElCol>
        </ElRow>
      </template>
    </el-dialog>
    <el-dialog
      v-model="showNewPassengerDialog"
      :width="containerWidth <= 600 ? '100%' : '70vw'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_passenger') }}
        </span>
      </template>
      <QuickCreatePassengerDialog
        @create=";(showNewPassengerDialog = false), createPassenger($event)"
        @cancel="showNewPassengerDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewCompanyDialog"
      :width="containerWidth <= 600 ? '100%' : '70vw'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_company') }}
        </span>
      </template>
      <QuickCreateCompanyDialog
        @create=";(showNewCompanyDialog = false), createCompany($event)"
        @cancel="showNewCompanyDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewOriginDialog"
      :close-on-click-modal="false"
      :width="containerWidth <= 600 ? '100%' : '70vw'"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_location') }}
        </span>
      </template>
      <QuickCreateLocationDialog
        @create=";(showNewOriginDialog = false), createOrigin($event)"
        @cancel="showNewOriginDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewDestinationDialog"
      :width="containerWidth <= 600 ? '100%' : '70vw'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_location') }}
        </span>
      </template>
      <QuickCreateLocationDialog
        @create=";(showNewDestinationDialog = false), createDestination($event)"
        @cancel="showNewDestinationDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewAgentDialog"
      :width="containerWidth <= 600 ? '100%' : '70vw'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_agent') }}
        </span>
      </template>
      <QuickCreateAgentDialog
        @create=";(showNewAgentDialog = false), createAgent($event)"
        @cancel="showNewAgentDialog = false"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import moment from 'moment'
import useVuelidate from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_ROUTE_NO_ADDRESS from '~/graphql/routes/mutation/createRouteNoAddress.gql'
import CREATE_ROUTE_ORIGIN_ADDRESS from '~/graphql/routes/mutation/createRouteOriginAddress.gql'
import CREATE_ROUTE_DESTINATION_ADDRESS from '~/graphql/routes/mutation/createRouteDestinationAddress.gql'
import CREATE_ROUTE_ADDRESS from '~/graphql/routes/mutation/createRouteAddress.gql'
import CREATE_TRANSPORT_NO_ADDRESS from '~/graphql/transports/mutation/createTransportNoAddress.gql'
import CREATE_TRANSPORT_ORIGIN_ADDRESS from '~/graphql/transports/mutation/createTransportOriginAddress.gql'
import CREATE_TRANSPORT_DESTINATION_ADDRESS from '~/graphql/transports/mutation/createTransportDestinationAddress.gql'
import CREATE_TRANSPORT_ADDRESS from '~/graphql/transports/mutation/createTransportAddress.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const props = defineProps({
  value: {
    type: Boolean,
    default: false
  },
  itineraryDay: {
    type: Object,
    default: () => ({})
  },
  itineraryDayLocation: {
    type: Object,
    default: () => ({})
  },
  route: {
    type: String,
    default: ''
  },
  model: {
    type: Object,
    default: () => ({})
  },
  modelType: {
    type: String,
    default: ''
  },
  user: {
    type: Object,
    default: () => ({})
  }
})

initialPassengersByUser(props.user.uuid)
const form = reactive({
  name_es: '',
  name_en: '',
  name_fr: '',
  name_de: '',
  name_sv: '',
  name_ar: '',
  transport_type_uuid: '',
  transport_type_code: '',
  company_uuid: '',
  origin_uuid: '',
  origin_point: 'LOCATION',
  origin_formatted_address: '',
  origin_street: '',
  origin_city: '',
  origin_postal_code: '',
  origin_province_uuid: '',
  origin_province_name: '',
  origin_country_uuid: '',
  origin_country_name: '',
  origin_country_code: '',
  origin_latitude: '',
  origin_longitude: '',
  destination_uuid: '',
  destination_point: 'LOCATION',
  destination_formatted_address: '',
  destination_street: '',
  destination_city: '',
  destination_postal_code: '',
  destination_province_uuid: '',
  destination_province_name: '',
  destination_country_uuid: '',
  destination_country_code: '',
  destination_country_name: '',
  destination_latitude: '',
  destination_longitude: '',
  departure_time: props.itineraryDay?.original_date
    ? moment(props.itineraryDay.original_date).add(12, 'hours')
    : moment().startOf('day').add(12, 'hours'),
  departure_date: props.itineraryDay?.original_date
    ? moment(props.itineraryDay.original_date).add(12, 'hours')
    : null,
  arrival_time: props.itineraryDay?.original_date
    ? moment(props.itineraryDay.original_date).add(14, 'hours')
    : moment().startOf('day').add(14, 'hours'),
  arrival_date: props.itineraryDay?.original_date
    ? moment(props.itineraryDay.original_date).add(14, 'hours')
    : null,
  travel_days: 0,
  customer_price: 0,
  paradise_price: 0,
  commission: 0,
  prepayment: false,
  payment_by_customer: false,
  currency: 'eur',
  documents: [],
  computedDocuments: [],
  agent_uuid: '',
  description: '',
  notes_es: '',
  notes_en: '',
  notes_fr: '',
  notes_de: '',
  notes_sv: '',
  notes_ar: '',
  wheelchair: false,
  estimated_travel_time: moment().startOf('day').add(2, 'hours').toDate(),
  transport_number: '',
  reservation_number: '',
  transport_passengers: [
    {
      voucher_number: '',
      passenger_uuid: '',
      passenger_name: ''
    }
  ]
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()

const ruleFormRef = ref()
const loading = ref(false)
const showNewCompanyDialog = ref(false)
const showNewOriginDialog = ref(false)
const showNewDestinationDialog = ref(false)
const showNewPassengerDialog = ref(false)
const showNewAgentDialog = ref(false)
const currencies = getCurrencies()
const passengerSelected = ref(0)
const titleMode = ref(0)
const flightForm = ref(null)
const flightReservation = ref(null)
const flightIsLastStep = ref(false)
const alreadyFlightsBooked = ref(false)
const hasBeenUpdated = ref(false)
const transportTypes = ref(null)
const originFormattedAddressInvalid = ref(false)
const destinationFormattedAddressInvalid = ref(false)
const languages = getLanguages()
const selectedFieldLanguage = ref('EN')
let {
  containerWidth,
  autoresize,
  getTargetLang,
  getSourceLanguage,
  fieldsByLang,
  address,
  countriesArray,
  provinces
} = getFormInitialVariables()
let { newWindowItem } = getTableInitialVariables()

const rules = {
  transport_type_uuid: { required },
  origin_uuid: (value) => {
    if (value.origin_point === 'LOCATION' && !value.origin_uuid) {
      return false
    }
    return true
  },
  destination_uuid: (value) => {
    if (value.destination_point === 'LOCATION' && !value.destination_uuid) {
      return false
    }
    return true
  },
  origin_formatted_address: (value) => {
    if (value.origin_point === 'ADDRESS' && !value.origin_formatted_address) {
      return false
    }
    return true
  },
  destination_formatted_address: (value) => {
    if (
      value.destination_point === 'ADDRESS' &&
      !value.destination_formatted_address
    ) {
      return false
    }
    return true
  },
  departure_date: { required },
  departure_time: { required },
  arrival_date: { required },
  arrival_time: { required },
  notes_en: {
    maxLength: 3000
  },
  notes_es: {
    maxLength: 3000
  },
  notes_fr: {
    maxLength: 3000
  },
  notes_de: {
    maxLength: 3000
  },
  notes_sv: {
    maxLength: 3000
  },
  notes_ar: {
    maxLength: 3000
  }
}

onMounted(() => {
  initialCompanies()
  initialLocations()
  initialPassengersByUser(props.user.uuid)
  initialAgents()
  setFieldLanguage()
  getAllTransportTypes(true)
})

const v$ = useVuelidate(rules, form)

const selectedFieldLanguageFlag = computed(() => {
  return languages.find((item) => item.iso_code === selectedFieldLanguage.value)
})

const dialogTitle = computed(() => {
  if (
    titleMode.value === 0 ||
    (form.transport_type_code === 'FLIGHT' &&
      !flightForm.value &&
      !flightReservation.value)
  ) {
    return t('add_transport')
  }
  if (titleMode.value === 1 && form.transport_type_code !== 'FLIGHT') {
    return t('add_passengers_to_transport')
  }
  return ''
})

const addressLocationTypes = computed(() => {
  return [
    { value: 'ADDRESS', label: t('address') },
    { value: 'LOCATION', label: t('location') }
  ]
})

const inputRules = reactive({
  transport_type_uuid: [
    {
      required: true,
      message: t('type_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  origin_uuid: [
    {
      validator: (rule, value, callback) => {
        if (form.origin_point === 'LOCATION' && !form.origin_uuid) {
          return callback(t('origin_is_required'))
        }
        return callback()
      }
    }
  ],
  destination_uuid: [
    {
      validator: (rule, value, callback) => {
        if (form.destination_point === 'LOCATION' && !form.destination_uuid) {
          return callback(t('destination_is_required'))
        }
        return callback()
      }
    }
  ],
  origin_formatted_address: [
    {
      validator: (rule, value, callback) => {
        if (form.origin_point === 'ADDRESS' && !form.origin_formatted_address) {
          return callback(t('origin_is_required'))
        }
        return callback()
      }
    }
  ],
  destination_formatted_address: [
    {
      validator: (rule, value, callback) => {
        if (
          form.destination_point === 'ADDRESS' &&
          !form.destination_formatted_address
        ) {
          return callback(t('destination_is_required'))
        }
        return callback()
      }
    }
  ],
  departure_date: [
    {
      required: true,
      message: t('departure_date_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  departure_time: [
    {
      required: true,
      message: t('departure_time_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  arrival_date: [
    {
      required: true,
      message: t('arrival_date_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  arrival_time: [
    {
      required: true,
      message: t('arrival_time_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  notes_en: [
    {
      max: 3000,
      message: t('max_length_is', { max: 3000, field: t('notes_en') }),
      trigger: ['blur', 'change']
    }
  ],
  notes_es: [
    {
      max: 3000,
      message: t('max_length_is', { max: 3000, field: t('notes_es') }),
      trigger: ['blur', 'change']
    }
  ],
  notes_fr: [
    {
      max: 3000,
      message: t('max_length_is', { max: 3000, field: t('notes_fr') }),
      trigger: ['blur', 'change']
    }
  ],
  notes_de: [
    {
      max: 3000,
      message: t('max_length_is', { max: 3000, field: t('notes_de') }),
      trigger: ['blur', 'change']
    }
  ],
  notes_sv: [
    {
      max: 3000,
      message: t('max_length_is', { max: 3000, field: t('notes_sv') }),
      trigger: ['blur', 'change']
    }
  ],
  notes_ar: [
    {
      max: 3000,
      message: t('max_length_is', { max: 3000, field: t('notes_ar') }),
      trigger: ['blur', 'change']
    }
  ]
})

const getAllTransportTypes = async (refetch = false) => {
  try {
    loading.value = true
    transportTypes.value = await apolloTransportTypes(
      {
        sortBy: 'CODE',
        sortDesc: false,
        search: null,
        page: 1,
        first: 20
      },
      true,
      {},
      refetch ? 'network-only' : null
    )
    if (transportTypes.value?.getAllTransportTypes?.data?.length) {
      form.transport_type_uuid =
        transportTypes.value.getAllTransportTypes.data[0].uuid
      form.transport_type_code =
        transportTypes.value.getAllTransportTypes.data[0].code
    }
  } catch (err) {
    $showError(err, t)
    $sentry(err, 'getTransportTypes')
  } finally {
    loading.value = false
  }
}

const submitPassenger = () => {
  const passenger = form.transport_passengers[passengerSelected.value]
  if (passenger) {
    if (!passenger.passenger_uuid) {
      $showError(t('passenger_is_invalid'))
    } else {
      form.transport_passengers.push({
        voucher_number: '',
        passenger_uuid: '',
        passenger_name: ''
      })
      passengerSelected.value = form.transport_passengers.length - 1
    }
  } else {
    $showError(t('passenger_is_invalid'))
  }
}

const validatePassengers = () => {
  let result = true
  if (hasBeenUpdated.value) {
    for (let j = 0; j < form.transport_passengers.length; j++) {
      const passenger = form.transport_passengers[j]
      if (!passenger.passenger_uuid) {
        $showError(
          t('fill_required_fields_from_passenger_number', {
            i: i + 1
          })
        )
        result = false
        passengerSelected.value = j
        return result
      }
    }
  }
  return result
}

const submit = async (formEl) => {
  v$.value.$touch()
  await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (!validatePassengers()) {
    //
  } else if (
    !validateLanguages() ||
    v$.value.$invalid ||
    (!props.modelType && !props.route)
  ) {
    //
  } else {
    create()
  }
}

const validateLanguages = () => {
  const languagesArray = languages.filter(
    (i) =>
      i.iso_code.toLowerCase() !== selectedFieldLanguage.value.toLowerCase()
  )
  for (const language of languagesArray) {
    if (
      v$.value[`notes_${language.iso_code.toLowerCase()}`]?.maxLength?.$invalid
    ) {
      $showError(
        t('max_length_is', {
          max: 3000,
          field: t(`notes_${language.iso_code.toLowerCase()}`)
        })
      )
      return false
    }
  }
  return true
}

const create = async () => {
  loading.value = true
  let variables =
    props.modelType && props.model?.uuid
      ? getRouteVariables(form)
      : getTransportVariables(form)
  const transportMutation = getCreateTransportMutation(
    form,
    props.modelType && props.model?.uuid ? false : true
  )
  const response = await mutation(transportMutation, variables)
  if (response.error) {
    loading.value = false
    $showError(response.error, t)
    $sentry(response.error, 'create')
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits(
      'create',
      props.modelType && props.model?.uuid
        ? response.result?.data?.createRoute ?? null
        : response.result?.data?.createTransport ?? null
    )
    loading.value = false
  }
}

const getCreateTransportMutation = (form, hasRoute) => {
  if (form.origin_formatted_address && form.destination_formatted_address) {
    return hasRoute ? CREATE_TRANSPORT_ADDRESS : CREATE_ROUTE_ADDRESS
  } else if (
    !form.origin_formatted_address &&
    form.destination_formatted_address
  ) {
    return hasRoute
      ? CREATE_TRANSPORT_DESTINATION_ADDRESS
      : CREATE_ROUTE_DESTINATION_ADDRESS
  } else if (
    form.origin_formatted_address &&
    !form.destination_formatted_address
  ) {
    return hasRoute
      ? CREATE_TRANSPORT_ORIGIN_ADDRESS
      : CREATE_ROUTE_ORIGIN_ADDRESS
  } else {
    return hasRoute ? CREATE_TRANSPORT_NO_ADDRESS : CREATE_ROUTE_NO_ADDRESS
  }
}

const getTransportVariables = (form) => {
  let variables = {
    name_es: form.name_es,
    name_en: form.name_en,
    name_fr: form.name_fr,
    name_de: form.name_de,
    name_sv: form.name_sv,
    name_ar: form.name_ar,
    transport_type_uuid: form.transport_type_uuid,
    route_uuid: props.route,
    company_uuid: form.company_uuid,
    origin_uuid: form.origin_uuid ? form.origin_uuid : null,
    origin_formatted_address: form.origin_formatted_address
      ? form.origin_formatted_address
      : null,
    origin_street: form.origin_street ? form.origin_street : null,
    origin_city: form.origin_city ? form.origin_city : null,
    origin_postal_code: form.origin_postal_code
      ? form.origin_postal_code
      : null,
    origin_province_uuid: form.origin_province_uuid
      ? form.origin_province_uuid
      : null,
    origin_country_uuid: form.origin_country_uuid
      ? form.origin_country_uuid
      : null,
    origin_latitude: form.origin_latitude ? form.origin_latitude : null,
    origin_longitude: form.origin_longitude ? form.origin_longitude : null,
    destination_uuid: form.destination_uuid ? form.destination_uuid : null,
    destination_formatted_address: form.destination_formatted_address
      ? form.destination_formatted_address
      : null,
    destination_street: form.destination_street
      ? form.destination_street
      : null,
    destination_city: form.destination_city ? form.destination_city : null,
    destination_postal_code: form.destination_postal_code
      ? form.destination_postal_code
      : null,
    destination_province_uuid: form.destination_province_uuid
      ? form.destination_province_uuid
      : null,
    destination_country_uuid: form.destination_country_uuid
      ? form.destination_country_uuid
      : null,
    destination_latitude: form.destination_latitude
      ? form.destination_latitude
      : null,
    destination_longitude: form.destination_longitude
      ? form.destination_longitude
      : null,
    departure_date: form.departure_date
      ? moment(form.departure_date).format('YYYY-MM-DD')
      : null,
    departure_time: form.departure_time
      ? moment(form.departure_time).format('HH:mm:00')
      : null,
    arrival_date: form.arrival_date
      ? moment(form.arrival_date).format('YYYY-MM-DD')
      : null,
    arrival_time: form.arrival_time
      ? moment(form.arrival_time).format('HH:mm:00')
      : null,
    travel_days: form.travel_days,
    customer_price: form.customer_price
      ? parseInt(form.customer_price * 100)
      : 0,
    paradise_price: form.paradise_price
      ? parseInt(form.paradise_price * 100)
      : 0,
    commission: form.commission ? parseInt(form.commission * 100) : 0,
    prepayment: form.prepayment,
    payment_by_customer: form.payment_by_customer,
    currency: form.currency,
    documents: form.documents,
    agent_uuid: form.agent_uuid,
    description: form.description,
    notes_es: form.notes_es,
    notes_en: form.notes_en,
    notes_fr: form.notes_fr,
    notes_de: form.notes_de,
    notes_sv: form.notes_sv,
    notes_ar: form.notes_ar,
    wheelchair: form.wheelchair,
    estimated_travel_time: form.estimated_travel_time
      ? parseInt(
          form.estimated_travel_time.getHours() * 60 +
            form.estimated_travel_time.getMinutes()
        )
      : 0,
    transport_number: form.transport_number,
    reservation_number: form.reservation_number
  }
  if (hasBeenUpdated.value) {
    variables.transport_passengers = form.transport_passengers.map((j) => {
      return {
        create: {
          voucher_number: j.voucher_number,
          passenger: {
            connect: j.passenger_uuid
          }
        }
      }
    })
  }

  return variables
}

const getRouteVariables = (form) => {
  let variables = {
    model_uuid: props.model.uuid,
    model_type: props.modelType,
    origin_uuid: form.origin_uuid ? form.origin_uuid : null,
    origin_formatted_address: form.origin_formatted_address
      ? form.origin_formatted_address
      : null,
    origin_street: form.origin_street ? form.origin_street : null,
    origin_city: form.origin_city ? form.origin_city : null,
    origin_postal_code: form.origin_postal_code
      ? form.origin_postal_code
      : null,
    origin_province_uuid: form.origin_province_uuid
      ? form.origin_province_uuid
      : null,
    origin_country_uuid: form.origin_country_uuid
      ? form.origin_country_uuid
      : null,
    origin_latitude: form.origin_latitude ? form.origin_latitude : null,
    origin_longitude: form.origin_longitude ? form.origin_longitude : null,
    destination_uuid: form.destination_uuid ? form.destination_uuid : null,
    destination_formatted_address: form.destination_formatted_address
      ? form.destination_formatted_address
      : null,
    destination_street: form.destination_street
      ? form.destination_street
      : null,
    destination_city: form.destination_city ? form.destination_city : null,
    destination_postal_code: form.destination_postal_code
      ? form.destination_postal_code
      : null,
    destination_province_uuid: form.destination_province_uuid
      ? form.destination_province_uuid
      : null,
    destination_country_uuid: form.destination_country_uuid
      ? form.destination_country_uuid
      : null,
    destination_latitude: form.destination_latitude
      ? form.destination_latitude
      : null,
    destination_longitude: form.destination_longitude
      ? form.destination_longitude
      : null,
    voucher_number: form.voucher_number ?? null,
    departure_date: form.departure_date
      ? moment(form.departure_date).format('YYYY-MM-DD')
      : null,
    departure_time: form.departure_time
      ? moment(form.departure_time).format('HH:mm')
      : null,
    arrival_date: form.arrival_date
      ? moment(form.arrival_time).format('YYYY-MM-DD')
      : null,
    arrival_time: form.arrival_time
      ? moment(form.arrival_time).format('HH:mm')
      : null,
    travel_days: form.travel_days,
    customer_price: form.customer_price
      ? parseInt(form.customer_price * 100)
      : 0,
    paradise_price: form.paradise_price
      ? parseInt(form.paradise_price * 100)
      : 0,
    commission: form.commission ? parseInt(form.commission * 100) : 0,
    prepayment: form.prepayment,
    payment_by_customer: form.payment_by_customer,
    currency: form.currency,
    transports: [getTransportFromRouteVariables(form)]
  }
  return variables
}

const getTransportFromRouteVariables = (form) => {
  let transport = {
    name_es: form.name_es,
    name_en: form.name_en,
    name_fr: form.name_fr,
    name_de: form.name_de,
    name_sv: form.name_sv,
    name_ar: form.name_ar,
    departure_date: form.departure_date
      ? moment(form.departure_date).format('YYYY-MM-DD')
      : null,
    departure_time: form.departure_time
      ? moment(form.departure_time).format('HH:mm:00')
      : null,
    arrival_date: form.arrival_date
      ? moment(form.arrival_date).format('YYYY-MM-DD')
      : null,
    arrival_time: form.arrival_time
      ? moment(form.arrival_time).format('HH:mm:00')
      : null,
    travel_days: form.travel_days,
    price: {
      create: {
        customer_price: form.customer_price
          ? parseInt(form.customer_price * 100)
          : 0,
        paradise_price: form.paradise_price
          ? parseInt(form.paradise_price * 100)
          : 0,
        commission: form.commission ? parseInt(form.commission * 100) : 0,
        prepayment: form.prepayment,
        payment_by_customer: form.payment_by_customer,
        currency: form.currency
      }
    },
    documents: form.documents,
    description: form.description,
    notes_es: form.notes_es,
    notes_en: form.notes_en,
    notes_fr: form.notes_fr,
    notes_de: form.notes_de,
    notes_sv: form.notes_sv,
    notes_ar: form.notes_ar,
    wheelchair: form.wheelchair,
    estimated_travel_time: form.estimated_travel_time
      ? parseInt(
          moment(form.estimated_travel_time, 'HH:mm').hours() * 60 +
            moment(form.estimated_travel_time, 'HH:mm').minutes()
        )
      : 0,
    transport_number: form.transport_number,
    reservation_number: form.reservation_number,
    transport_passengers: []
  }

  if (form.company_uuid) {
    transport.company = {
      connect: form.company_uuid
    }
  }

  if (form.transport_type_uuid) {
    transport.transport_type = {
      connect: form.transport_type_uuid
    }
  }
  if (form.origin_uuid) {
    transport.origin = {
      connect: form.origin_uuid
    }
  }
  if (form.origin_formatted_address) {
    transport.origin_address = {
      create: {
        formatted_address: form.origin_formatted_address,
        street: form.origin_street,
        city: form.origin_city,
        postal_code: form.origin_postal_code,
        province: {
          connect: form.origin_province_uuid
        },
        country: {
          connect: form.origin_country_uuid
        },
        latitude: form.origin_latitude,
        longitude: form.origin_longitude
      }
    }
  }
  if (form.destination_uuid) {
    transport.destination = {
      connect: form.destination_uuid
    }
  }
  if (form.destination_formatted_address) {
    transport.destination_address = {
      create: {
        formatted_address: form.destination_formatted_address,
        street: form.destination_street,
        city: form.destination_city,
        postal_code: form.destination_postal_code,
        province: {
          connect: form.destination_province_uuid
        },
        country: {
          connect: form.destination_country_uuid
        },
        latitude: form.destination_latitude,
        longitude: form.destination_longitude
      }
    }
  }

  if (hasBeenUpdated.value) {
    transport.transport_passengers = form.transport_passengers.map((j) => {
      return {
        create: {
          voucher_number: j.voucher_number,
          passenger: {
            connect: j.passenger_uuid
          }
        }
      }
    })
  }

  return transport
}

const setFieldLanguage = () => {
  if (getAuthUser()?.value?.language?.toLowerCase() === 'en') {
    selectedFieldLanguage.value = 'EN'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'es') {
    selectedFieldLanguage.value = 'ES'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'fr') {
    selectedFieldLanguage.value = 'FR'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'de') {
    selectedFieldLanguage.value = 'DE'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'sv') {
    selectedFieldLanguage.value = 'SV'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'ar') {
    selectedFieldLanguage.value = 'AR'
  }
}

const translateField = async (field) => {
  try {
    loading.value = true
    const response = await mutationTranslation(
      {
        text: form[field],
        source_lang: getSourceLanguage(field).name,
        target_lang: getTargetLang(field)
      },
      t,
      getSourceLanguage(field).name
    )
    const fieldByLang = fieldsByLang(field)
    if (response?.length) {
      response.forEach((item) => {
        const fieldName = fieldByLang[item.target_lang]
        if (fieldName) {
          form[fieldName] = item.translated_text
        }
      })
    }
  } catch (err) {
    $showError(err, t)
    $sentry(err, 'translateField')
  } finally {
    loading.value = false
  }
}

const setTravelDays = (type) => {
  if (type === 'date' && form.departure_date && form.arrival_date) {
    const start = moment(form.departure_date)
    const end = moment(form.arrival_date)
    const diff = end.diff(start, 'days')
    form.travel_days = diff
  } else if (type === 'number' && form.departure_date) {
    const start = moment(form.departure_date)
    const end = start.add(form.travel_days, 'days')
    form.arrival_date = end
  }
}

const setEstimatedTravelTime = () => {
  if (form.arrival_time && form.departure_time) {
    const start = moment(form.departure_time)
    const end = moment(form.arrival_time)
    const diffMinutes = end.diff(start, 'minutes')

    form.estimated_travel_time = moment()
      .startOf('day')
      .add(Math.abs(diffMinutes), 'minutes')
      .toDate()
  }
}

const updateAddress = async (address, type) => {
  if (address?.find((i) => i.field === 'formatted_address')?.value) {
    form[`${type}_formatted_address`] = address.find(
      (i) => i.field === 'formatted_address'
    ).value
    setGoogleAutocompleteInput(
      {
        formatted_address: form[`${type}_formatted_address`]
      },
      `${type}GoogleAutoComplete`,
      `${type}_formatted_address`
    )
  }
  if (address?.find((i) => i.field === 'street')?.value) {
    form[`${type}_street`] = address.find((i) => i.field === 'street').value
  }
  if (address?.find((i) => i.field === 'city')?.value) {
    form[`${type}_city`] = address.find((i) => i.field === 'city').value
  }
  if (address?.find((i) => i.field === 'postal_code')?.value) {
    form[`${type}_postal_code`] = address.find(
      (i) => i.field === 'postal_code'
    ).value
  }
  if (address?.find((i) => i.field === 'province')?.value) {
    form[`${type}_province_name`] = address.find(
      (i) => i.field === 'province'
    ).value
  }
  if (address?.find((i) => i.field === 'country')?.value) {
    form[`${type}_country_name`] = address.find(
      (i) => i.field === 'country'
    ).value
    form[`${type}_country_code`] = address.find(
      (i) => i.field === 'country_code'
    ).value
    searchCountriesByCode(form[`${type}_country_code`])
    countriesArray = await getSearchCountriesByCode()
    if (countriesArray?.length) {
      form[`${type}_country_uuid`] =
        countriesArray.find((i) => i.code === form[`${type}_country_code`])
          ?.value ?? null
      if (form[`${type}_country_uuid`]) {
        provinces = await searchProvincesByCountry(form[`${type}_country_uuid`])
        if (address?.find((i) => i.field === 'province')?.value) {
          form[`${type}_province_name`] = address.find(
            (i) => i.field === 'province'
          ).value
          form[`${type}_province_uuid`] =
            provinces?.find((i) => i.name === form[`${type}_province_name`])
              ?.uuid ?? null
          if (!form[`${type}_province_uuid`]) {
            createAsyncProvince(type)
          }
        }
      } else {
        getCountryAndProvinceByName(type)
      }
    } else if (address?.find((i) => i.field === 'province')?.value) {
      getCountryAndProvinceByName(type)
    }
  }
  if (address?.find((i) => i.field === 'latitude')?.value) {
    form[`${type}_latitude`] = address.find((i) => i.field === 'latitude').value
  }
  if (address?.find((i) => i.field === 'longitude')?.value) {
    form[`${type}_longitude`] = address.find(
      (i) => i.field === 'longitude'
    ).value
  }
}
const getCountryAndProvinceByName = async (type) => {
  const provinceName = address.find((i) => i.field === 'province').value
  const province = await searchProvincesByName(provinceName)
  form[`${type}_country_name`] = province?.country?.name
  form[`${type}_country_code`] = province?.country?.code
  form[`${type}_country_uuid`] = province?.country?.uuid
  if (form[`${type}_country_code`]) {
    searchCountriesByCode(form[`${type}_country_code`])
    countriesArray = await getSearchCountriesByCode()
    provinces = await searchProvincesByCountry(form[`${type}_country_uuid`])
    form[`${type}_province_name`] = address.find(
      (i) => i.field === 'province'
    ).value
    form[`${type}_province_uuid`] =
      provinces?.find((i) => i.name === form[`${type}_province_name`])?.uuid ??
      null
  } else {
    createAsyncCountry(type)
  }
}
const updateProvinceArray = async (event) => {
  provinces = await searchProvincesByCountry(event)
}
const updateCountryAndProvince = async (event) => {
  searchCountries(event.name)
  provinces = await searchProvincesByCountry(event.uuid)
}
const createAsyncProvince = async (type) => {
  const provinceName =
    address.find((i) => i.field === 'province')?.value ?? null
  if (provinceName && form[`${type}_country_uuid`]) {
    const province = await mutationProvince({
      name: provinceName,
      country_uuid: form[`${type}_country_uuid`]
    })
    form[`${type}_province_uuid`] = province.uuid
    form[`${type}_province_name`] = province.name
    form[`${type}_country_uuid`] = province.country?.uuid
    form[`${type}_country_code`] = province.country?.code
    form[`${type}_country_name`] = province.country?.name
    updateCountryAndProvince(province.country)
  }
}
const createAsyncCountry = async (type) => {
  const countryName = address.find((i) => i.field === 'country')?.value ?? null
  const countryCode =
    address.find((i) => i.field === 'country_code')?.value ?? null
  let country = null
  if (countryName && countryCode) {
    country = await mutationCountry({ name: countryName, code: countryCode }, t)
    if (country) {
      form[`${type}_country_uuid`] = country?.uuid
      form[`${type}_country_code`] = country?.code
      form[`${type}_country_name`] = country?.name
      createAsyncProvince(type)
    } else {
      country = getSearchCountriesByCode()?.find((i) => i.code === countryCode)
      if (country) {
        form[`${type}_country_uuid`] = country?.value
        form[`${type}_country_code`] = country?.code
        form[`${type}_country_name`] = country?.label
        createAsyncProvince(type)
      }
    }
  }
}

const createCompany = (event) => {
  if (event?.uuid) {
    form.company_uuid = event.uuid
    form.company = event
    searchCompanies(event.business_name, form.company, null, true)
  }
}

const createOrigin = (event) => {
  if (event?.uuid) {
    form.origin_uuid = event.uuid
    form.origin = event
    searchLocations(event.name, form.origin, null, true)
  }
}

const createDestination = (event) => {
  if (event?.uuid) {
    form.destination_uuid = event.uuid
    form.destination = event
    searchDestinations(event.name, form.destination, null, true)
  }
}

const createAgent = (event) => {
  if (event?.uuid) {
    form.agent_uuid = event.uuid
    form.agent = event
    searchAgents(event.full_name, form.agent, null, true)
  }
}

const createPassenger = (event) => {
  if (event?.uuid) {
    form.transport_passengers[passengerSelected.value].passenger_uuid =
      event.uuid
    form.transport_passengers[passengerSelected.value].passenger = event
    searchPassengers(
      event.first_name,
      form.transport_passengers[passengerSelected.value].passenger,
      null,
      true
    )
  }
}

const uploadImages = (event) => {
  event?.forEach((i) => {
    form.documents.push({
      file: i
    })
  })
  event?.forEach((i) => {
    blobToBase64(i).then((fileBase64) => {
      form.computedDocuments.push({
        uid: i.uid,
        file_original_name: i.name,
        file_mime_type: i.type,
        encode_file: fileBase64
      })
    })
  })
}

const detachFiles = (index) => {
  form.documents.splice(index, 1)
  form.computedDocuments.splice(index, 1)
}

const getPassengerName = (uuid) => {
  if (uuid && getSearchPassengers()?.length) {
    const passenger = getSearchPassengers().find((p) => p.value === uuid)
    if (passenger) {
      form.transport_passengers[passengerSelected.value].passenger_name =
        passenger.label
    }
  }
}

const removePassenger = (index) => {
  form.transport_passengers.splice(index, 1)
  passengerSelected.value = form.transport_passengers.length - 1
}

const selectTransportType = (item) => {
  form.transport_type_uuid = item.uuid
  form.transport_type_code = item.code
}

const back = () => {
  if (flightReservation.value && flightForm.value) {
    flightReservation.value = null
    return
  }
  if (!flightReservation.value && flightForm.value) {
    flightForm.value = null
    return
  }
  return
}
</script>

<style scoped lang="scss">
:deep(.el-overlay.itinerary-transport-dialog) {
  .el-dialog {
    padding: 0 !important;
    .el-dialog__header {
      padding: 20px 20px 0 20px !important;
      &.flight-list {
        display: none;
      }
    }
    .el-dialog__body {
      @media (min-width: 1100px) {
        padding: 0 20px 20px 20px !important;
      }
      @media (max-width: 1100px) {
        padding: 0 20px 0 20px !important;
      }
      &.flight-list {
        padding: 0px !important;
      }
      .el-form-item {
        .el-form-item__content {
          .already-flights-booked-checkbox {
            margin-right: 8px;
          }
        }
      }
    }
    .el-dialog__footer {
      padding: 20px;
      &.flight-list {
        .el-row {
          .el-col {
            margin-top: 0 !important;
          }
        }
      }
    }
  }
}
.passenger-number {
  margin-top: 16px;
  border-radius: 24px;
  background: var(--brand-blue);
  width: 24px;
  height: 24px;
  padding: 12px;
  color: var(--brand-white);
  display: flex;
  align-items: center;
  justify-content: center;
  &.not-shown {
    margin-left: 10px;
    margin-right: 32px;
  }
}
.passenger-list {
  margin-top: 16px;
  .passenger-info {
    display: inline-block;
    border-radius: 8px;
    background: var(--brand-off-white);
    padding: 16px;
    .passenger-title {
      color: var(--text-primary);
      display: block;
      @media (min-width: 1100px) {
        max-width: 400px;
      }
      @media (max-width: 1100px) {
        max-width: 300px;
      }
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .transport-subtitle,
    .passenger-subtitle {
      color: var(--text-primary);
      display: block;
    }
  }

  .passenger-actions {
    margin-left: 16px;
    i {
      cursor: pointer;
    }
    i:nth-of-type(2) {
      position: relative;
      top: 16px;
    }
  }
}
</style>
