import ALL_AIRPORTS from '@/graphql/airports/query/getAllAirportsAutocomplete.gql'

const airports = ref<{ label: string; value: string, name: string, city: string }[]>([])
const destinationAirports = ref<{ label: string; value: string, name: string, city: string }[]>([])
const key1 = ref(0)
const key2 = ref(0)
const firstTime = ref(true)
const firstTime2 = ref(true)

export function airportsAutocomplete(params: any) {
    const apolloQuery = ALL_AIRPORTS
    const variables = {
        search: '%' + params.search + '%',
        orderBy: [
            {
                column: getAuthUser()?.value?.language?.toLowerCase() === 'en'
                    ? 'NAME_EN'
                    : getAuthUser()?.value?.language?.toLowerCase() === 'es'
                        ? 'NAME_ES'
                        : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
                            ? 'NAME_FR'
                            : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                                ? 'NAME_DE'
                                : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                                    ? 'NAME_SV'
                                    : 'NAME_AR',
                order: params.sortDesc ? 'DESC' : 'ASC'
            }
        ],
        first: 10,
        page: 1
    }

    if (params.search?.length === 0) {
        delete variables.search
    }

    const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

    return result
}

export async function searchAirportsCb(queryString: any, cb: (arg0: any) => void) {
    const { $showError, $sentry } = useNuxtApp()
    let { t } = useI18n()
    try {
        const results = await airportsAutocomplete({ search: queryString })
        cb(results?.getAllAirports?.data ?? [])
    } catch (err) {
        $showError(err)
        $sentry(err, 'searchAirportsCb')
    }
}

export function getSearchAirports(entityAirport: any, entityAirports: any) {
    if (firstTime.value === true) {
        firstTime.value = false
        searchAirports('', entityAirport, entityAirports)
    }

    if (entityAirport && !airports?.value?.find((k: any) => k.value === entityAirport.uuid)) {
        airports?.value?.push({
            label: `${entityAirport.name} - ${entityAirport.city}`,
            value: entityAirport.uuid,
            name: entityAirport.name,
            city: entityAirport.city
        })
    }

    return airports?.value ?? []
}

export function getSearchDestinationAirports(entityAirport: any, entityAirports: any) {
    if (firstTime2.value === true) {
        firstTime2.value = false
        searchDestinationAirports('', entityAirport, entityAirports)
    }

    if (entityAirport && !destinationAirports?.value?.find((k: any) => k.value === entityAirport.uuid)) {
        destinationAirports?.value?.push({
            label: `${entityAirport.name} - ${entityAirport.city}`,
            value: entityAirport.uuid,
            name: entityAirport.name,
            city: entityAirport.city
        })
    }

    return destinationAirports?.value ?? []
}

function addRemoteEntityToArray(remoteArray: any, entityAirport: any, entityAirports: any, refresh = false) {
    const arrayToAdd: { label: any; value: any, name: any, city: any }[] = []
    entityAirports?.forEach((j: any) => {
        if (!remoteArray.find((k: any) => k.value === j.uuid)) {
            arrayToAdd.push({
                label: `${getAuthUser()?.value?.language?.toLowerCase() === 'en'
                    ? j.name_en
                    : getAuthUser()?.value?.language?.toLowerCase() === 'es'
                        ? j.name_es
                        : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
                            ? j.name_fr
                            : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                                ? j.name_de
                                : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                                    ? j.name_sv
                                    : getAuthUser()?.value?.language?.toLowerCase() === 'ar'
                                        ? j.name_ar
                                        : j.name_en} - ${getAuthUser()?.value?.language?.toLowerCase() === 'en'
                                            ? j.city_en
                                            : getAuthUser()?.value?.language?.toLowerCase() === 'es'
                                                ? j.city_es
                                                : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
                                                    ? j.city_fr
                                                    : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                                                        ? j.city_de
                                                        : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                                                            ? j.city_sv
                                                            : getAuthUser()?.value?.language?.toLowerCase() === 'ar'
                                                                ? j.city_ar
                                                                : j.city_en
                    }`,
                value: j.uuid,
                name: getAuthUser()?.value?.language?.toLowerCase() === 'en'
                    ? j.name_en
                    : getAuthUser()?.value?.language?.toLowerCase() === 'es'
                        ? j.name_es
                        : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
                            ? j.name_fr
                            : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                                ? j.name_de
                                : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                                    ? j.name_sv
                                    : getAuthUser()?.value?.language?.toLowerCase() === 'ar'
                                        ? j.name_ar
                                        : j.name_en,
                city: getAuthUser()?.value?.language?.toLowerCase() === 'en'
                    ? j.city_en
                    : getAuthUser()?.value?.language?.toLowerCase() === 'es'
                        ? j.city_es
                        : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
                            ? j.city_fr
                            : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                                ? j.city_de
                                : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                                    ? j.city_sv
                                    : getAuthUser()?.value?.language?.toLowerCase() === 'ar'
                                        ? j.city_ar
                                        : j.city_en
            })
        }
    })
    if (entityAirport && !remoteArray.find((k: any) => k.value === entityAirport.uuid)) {
        arrayToAdd.push({
            label: `${entityAirport.name} - ${entityAirport.city}`,
            value: entityAirport.uuid,
            name: entityAirport.name,
            city: entityAirport.city
        })
    }
    if (refresh) {
        key1.value = 0
        key2.value = 0
    }
    if (key1.value === 0) {
        key1.value = setCustomKeyVariable()
    }
    if (key2.value === 0) {
        key2.value = setCustomKeyVariable()
    }
    return [...remoteArray, ...arrayToAdd]
}

export async function searchAirports(queryString: any, entityAirport: any, entityAirports: any[], refresh = false) {
    const { $showError, $sentry } = useNuxtApp()
    try {
        const results = await airportsAutocomplete({ search: queryString })
        const remoteAirports = results?.getAllAirports?.data?.map((i: any) => {
            return {
                label: `${getAuthUser()?.value?.language?.toLowerCase() === 'en'
                    ? i.name_en
                    : getAuthUser()?.value?.language?.toLowerCase() === 'es'
                        ? i.name_es
                        : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
                            ? i.name_fr
                            : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                                ? i.name_de
                                : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                                    ? i.name_sv
                                    : getAuthUser()?.value?.language?.toLowerCase() === 'ar'
                                        ? i.name_ar
                                        : i.name_en} - ${getAuthUser()?.value?.language?.toLowerCase() === 'en'
                                            ? i.city_en
                                            : getAuthUser()?.value?.language?.toLowerCase() === 'es'
                                                ? i.city_es
                                                : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
                                                    ? i.city_fr
                                                    : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                                                        ? i.city_de
                                                        : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                                                            ? i.city_sv
                                                            : getAuthUser()?.value?.language?.toLowerCase() === 'ar'
                                                                ? i.city_ar
                                                                : i.city_en}`,
                value: i.uuid,
                name: getAuthUser()?.value?.language?.toLowerCase() === 'en'
                    ? i.name_en
                    : getAuthUser()?.value?.language?.toLowerCase() === 'es'
                        ? i.name_es
                        : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
                            ? i.name_fr
                            : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                                ? i.name_de
                                : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                                    ? i.name_sv
                                    : getAuthUser()?.value?.language?.toLowerCase() === 'ar'
                                        ? i.name_ar
                                        : i.name_en,
                city: getAuthUser()?.value?.language?.toLowerCase() === 'en'
                    ? i.city_en
                    : getAuthUser()?.value?.language?.toLowerCase() === 'es'
                        ? i.city_es
                        : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
                            ? i.city_fr
                            : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                                ? i.city_de
                                : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                                    ? i.city_sv
                                    : getAuthUser()?.value?.language?.toLowerCase() === 'ar'
                                        ? i.city_ar
                                        : i.city_en
            }
        }) ?? []

        airports.value = addRemoteEntityToArray(remoteAirports, entityAirport, entityAirports, refresh)
        firstTime.value = false

        if (entityAirport) {
            getSearchAirports(entityAirport, entityAirports)
        }
    } catch (err) {
        $showError(err)
        $sentry(err, 'searchAirports')
    }
}

export async function searchDestinationAirports(queryString: any, entityAirport: any, entityAirports: any[], refresh = false) {
    const { $showError, $sentry } = useNuxtApp()
    try {
        const results = await airportsAutocomplete({ search: queryString })
        const remoteDestinationAirports = results?.getAllAirports?.data?.map((i: any) => {
            return {
                label: `${getAuthUser()?.value?.language?.toLowerCase() === 'en'
                    ? i.name_en
                    : getAuthUser()?.value?.language?.toLowerCase() === 'es'
                        ? i.name_es
                        : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
                            ? i.name_fr
                            : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                                ? i.name_de
                                : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                                    ? i.name_sv
                                    : getAuthUser()?.value?.language?.toLowerCase() === 'ar'
                                        ? i.name_ar
                                        : i.name_en} - ${getAuthUser()?.value?.language?.toLowerCase() === 'en'
                                            ? i.city_en
                                            : getAuthUser()?.value?.language?.toLowerCase() === 'es'
                                                ? i.city_es
                                                : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
                                                    ? i.city_fr
                                                    : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                                                        ? i.city_de
                                                        : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                                                            ? i.city_sv
                                                            : getAuthUser()?.value?.language?.toLowerCase() === 'ar'
                                                                ? i.city_ar
                                                                : i.city_en}`,
                value: i.uuid,
                name: getAuthUser()?.value?.language?.toLowerCase() === 'en'
                    ? i.name_en
                    : getAuthUser()?.value?.language?.toLowerCase() === 'es'
                        ? i.name_es
                        : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
                            ? i.name_fr
                            : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                                ? i.name_de
                                : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                                    ? i.name_sv
                                    : getAuthUser()?.value?.language?.toLowerCase() === 'ar'
                                        ? i.name_ar
                                        : i.name_en,
                city: getAuthUser()?.value?.language?.toLowerCase() === 'en'
                    ? i.city_en
                    : getAuthUser()?.value?.language?.toLowerCase() === 'es'
                        ? i.city_es
                        : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
                            ? i.city_fr
                            : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                                ? i.city_de
                                : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                                    ? i.city_sv
                                    : getAuthUser()?.value?.language?.toLowerCase() === 'ar'
                                        ? i.city_ar
                                        : i.city_en
            }
        }) ?? []

        destinationAirports.value = addRemoteEntityToArray(remoteDestinationAirports, entityAirport, entityAirports, refresh)
        firstTime2.value = false

        if (entityAirport) {
            getSearchDestinationAirports(entityAirport, entityAirports)
        }
    } catch (err) {
        $showError(err)
        $sentry(err, 'searchDestinationAirports')
    }
}

export function getAirportKey() {
    return key1.value
}

export function getDestinationAirportKey() {
    return key2.value
}

export function initialAirports() {
    key1.value = 0
    key2.value = 0
    firstTime.value = true
    firstTime2.value = true
    airports.value = []
    destinationAirports.value = []
}