import { useI18n } from 'vue-i18n';
import { passengerTypes } from '~/models/passenger-types';

export const getPassengerTypes = () => {
    const { t } = useI18n();
    return passengerTypes.map((item) => {
        return {
            label: t(item.toLowerCase()),
            value: item.toUpperCase()
        };
    });
}
