import SEARCH_FLIGHTS from '@/graphql/flights/query/searchFlights.gql'

export function apolloFlights(
  params: any,
  filterExists = false,
  filterSaved = null,
  fetchPolicy = null
) {
  return new Promise(async (resolve, reject) => {
    let apolloQuery
    let variables = params
    try {
      apolloQuery = SEARCH_FLIGHTS

      resolve(await query(apolloQuery, variables, { fetchPolicy }))
    } catch (err) {
      reject(err)
    }
  })
}