import { useI18n } from 'vue-i18n';
import { seatClasses } from '~/models/seat-classes';

export const getSeatClasses = () => {
    const { t } = useI18n();
    return seatClasses.map((item) => {
        return {
            label: t('seat_classes.' + item.toLowerCase()),
            value: item.toUpperCase()
        };
    });
}
