<template>
  <div v-if="reservationCopy" class="seats-form">
    <div class="form-close">
      <Icons name="24px/close" @click="$emit('close')" />
    </div>
    <div class="form-title">
      <span class="dashboard-heading">
        {{ $t('seats') }}
      </span>
    </div>
    <div class="form-subtitle">
      <span class="dashboard-title">
        {{
          $t('flight_from_to', {
            from: step - 1 - servicesSteps,
            to: getLastPlaneStep()
          })
        }}
      </span>
    </div>
    <div class="seats-info">
      <div class="info__subtitle">
        <span class="body2">
          {{ $t('select_seats_on_the_map') }}
        </span>
      </div>
      <div class="info__prices">
        <div class="prices-group" v-for="color in colorsUsed" :key="color">
          <div
            class="price-color"
            :style="`background-color: ${
              isColorHexadecimalOrRgb(color.backgroundColor)
                ? color.backgroundColor
                : `var(--${color.backgroundColor})`
            }`"
          />
          <div class="price-label">
            <span class="body2">
              {{
                `${
                  color.price
                    ? Math.round(color.price)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                    : 0
                }${color.currency}`
              }}
            </span>
          </div>
        </div>
        <div class="prices-group">
          <div
            class="price-color"
            style="background-color: var(--divider-default)"
          />
          <div class="price-label">
            <span class="body2"> {{ $t('not_available') }} </span>
          </div>
        </div>
      </div>
    </div>
    <div class="seats-selector">
      <ElSelect
        v-if="reservationCopy"
        v-model="passengerSelected"
        :placeholder="$t('select_a_traveller')"
        filterable
        :fallback-placements="['top', 'bottom']"
        :popper-options="getSelectPopperOptions()"
        popper-class="custom-selector"
      >
        <ElOption
          v-for="item in getPassengersFiltered(reservationCopy.passengers)"
          :label="`${item.first_name} ${item.last_name}`"
          :value="item.index"
          :key="item.index"
        />
      </ElSelect>
    </div>
    <div class="seats-draw" v-if="getPlane(step)">
      <div
        class="draw__letters"
        :style="`width: ${getPlaneWidth(getPlane(step))}`"
      >
        <div
          class="letter"
          v-for="letter in getPlaneLetters(getPlane(step))"
          :key="letter"
          :class="letter === '-' ? 'aisle' : ''"
        >
          <span class="body1-strong">
            {{ letter === '-' ? '' : letter }}
          </span>
        </div>
      </div>
      <div
        class="draw__seat-row"
        :style="`width: ${getPlaneWidth(getPlane(step))}`"
        v-for="row in getPlaneRows(getPlane(step))"
        :key="row"
      >
        <div
          class="draw__seats"
          :id="getSeatId(seat)"
          v-for="(seat, index) in getPlaneSeatsRow(row)"
          :class="`${showPlaneSummaryPrice(row) ? 'has-summary-price' : ''}`"
          :key="getSeatId(seat)"
        >
          <div
            class="price"
            v-if="showPlaneSummaryPrice(row) && index === 0"
            :style="`width: ${getPlaneWidth(getPlane(step))}`"
          >
            <div
              class="price-value"
              :style="`background-color: ${
                isColorHexadecimalOrRgb(getSeatColor(seat)?.borderBottomColor)
                  ? getSeatColor(seat).borderBottomColor
                  : `var(--${getSeatColor(seat).borderBottomColor})`
              };`"
            >
              <span class="caption-strong"
                >{{
                  `${
                    showPlaneSummaryPrice(row)?.consumer_price
                      ? Math.round(
                          parseFloat(
                            showPlaneSummaryPrice(row).consumer_price / 100
                          )
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                      : ''
                  }${getCurrencySymbol(
                    showPlaneSummaryPrice(row)?.consumer_currency?.toLowerCase()
                  )}`
                }}
              </span>
            </div>
            <div
              class="line"
              :style="
                width > 1100
                  ? `width: calc(${getPlaneWidth(
                      getPlane(step)
                    )} + 60px);background-color: ${
                      isColorHexadecimalOrRgb(
                        getSeatColor(seat)?.borderBottomColor
                      )
                        ? getSeatColor(seat).borderBottomColor
                        : `var(--${getSeatColor(seat).borderBottomColor})`
                    };`
                  : `width: calc(${getPlaneWidth(
                      getPlane(step)
                    )} + 37px);background-color: ${
                      isColorHexadecimalOrRgb(
                        getSeatColor(seat)?.borderBottomColor
                      )
                        ? getSeatColor(seat).borderBottomColor
                        : `var(--${getSeatColor(seat).borderBottomColor})`
                    };`
              "
            />
          </div>
          <div
            v-if="!seat.is_aisle"
            class="seat"
            :class="`${
              !seat.available || !seat.airline_ref || !hasPrice(seat)
                ? 'not-available'
                : getSeatSelected(seat)
                ? 'selected'
                : 'available'
            }`"
            :style="
              seat.available && seat.airline_ref && !isNaN(getSeatPrice(seat))
                ? `background-color: ${
                    isColorHexadecimalOrRgb(getSeatColor(seat)?.backgroundColor)
                      ? getSeatColor(seat).backgroundColor
                      : `var(--${getSeatColor(seat).backgroundColor})`
                  };
                  border-bottom: 2px solid ${
                    isColorHexadecimalOrRgb(
                      getSeatColor(seat)?.borderBottomColor
                    )
                      ? getSeatColor(seat).borderBottomColor
                      : `var(--${getSeatColor(seat).borderBottomColor})`
                  };`
                : ''
            "
            @click.prevent.stop="selectSeat(seat)"
          >
            <Icons
              name="24px/emergency_exit"
              v-if="seat.has_emergency_exit"
              :class="index === 0 ? 'first' : 'last'"
            />
            <Icons
              name="16px/close_warning"
              class="close"
              v-if="getSeatSelected(seat)"
              @click.prevent.stop="removeSeat(seat)"
            />
            <span class="caption-strong" v-if="getSeatSelected(seat)">
              {{ getSeatSelected(seat) }}
            </span>
          </div>
          <div class="aisle" v-if="seat.is_aisle">
            <span class="body1-strong">
              {{ seat.location.row }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, orderBy } from 'lodash'

export default defineComponent({
  props: {
    planes: {
      type: Array,
      required: false,
      default: []
    },
    step: {
      type: Number,
      required: false,
      default: 0
    },
    servicesSteps: {
      type: Number,
      required: false,
      default: 0
    },
    reservation: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  setup() {
    const { width } = useBreakpoints()

    return {
      width
    }
  },
  data() {
    return {
      reservationCopy: null,
      passengerSelected: null,
      colorsUsed: [],
      randomColors: []
    }
  },
  watch: {
    reservation(newVal) {
      this.reservationCopy = cloneDeep(newVal)
      this.passengerSelected = newVal.passengers[0]?.index ?? null
    },
    step() {
      this.passengerSelected = this.reservation.passengers[0]?.index ?? null
      this.colorsUsed = []
      this.resetRandomColors()
    },
    passengerSelected() {
      this.colorsUsed = []
      this.resetRandomColors()
    }
  },
  mounted() {
    if (this.reservation) {
      this.reservationCopy = cloneDeep(this.reservation)
      this.passengerSelected = this.reservationCopy.passengers[0]?.index ?? null
    }
  },
  methods: {
    validateForm() {
      const plane = this.getPlane(this.step)
      for (let i = 0; this.reservationCopy.passengers.length > i; i++) {
        const passenger = this.reservationCopy.passengers[i]
        if (passenger.passengerType.toUpperCase() !== 'INFANT') {
          if (!passenger.seats?.length) {
            this.passengerSelected = i.toString()
            this.$showError(
              this.$t('passenger_has_no_seat', {
                passenger: `${passenger.first_name} ${passenger.last_name}`
              })
            )
            return false
          }
          if (!passenger.seats.find((i) => i.segment === plane.segment_code)) {
            this.passengerSelected = i.toString()
            this.$showError(
              this.$t('passenger_has_no_seat', {
                passenger: `${passenger.first_name} ${passenger.last_name}`
              })
            )
            return false
          }
        }
      }
      return true
    },
    getLastPlaneStep() {
      return this.planes?.filter((i) => i.plane_displays?.length)?.length ?? 0
    },
    getSeats() {
      return this.reservationCopy.passengers
    },
    getPlane(step) {
      return this.planes[step - this.servicesSteps - 2]
    },
    getPlaneLetters(plane) {
      return plane?.plane_displays[0]?.rows_distribution.split('') ?? []
    },
    getPlaneAislePosition(plane) {
      return this.getPlaneLetters(plane)
        .map((c, i) => (c === '-' ? i : -1))
        .filter((i) => i !== -1)
    },
    getPlaneRows(plane) {
      if (
        plane?.plane_displays[0] &&
        plane?.plane_displays[plane.plane_displays.length - 1]
      ) {
        return Array.from(
          {
            length:
              plane.plane_displays[plane.plane_displays.length - 1].last_row -
              plane.plane_displays[0].first_row +
              1
          },
          (_, i) => plane.plane_displays[0].first_row + i
        )
      }
      return []
    },
    getPlaneSeatsRow(row) {
      const plane = this.getPlane(this.step)
      const letters = plane.plane_displays[0].rows_distribution
        .replaceAll('-', '')
        .split('')
      let seatsWithAisles = []
      if (row && plane?.plane_displays[0]) {
        let seats =
          plane.plane_displays[0].seats?.filter(
            (i) => i.location.row === row
          ) ?? []
        if (seats?.length === 0) {
          for (var i = 0; i < letters.length; i++) {
            seats.push({
              location: {
                column: letters[i],
                row
              },
              available: false,
              has_emergency_exit: false
            })
          }
        }
        if (seats?.length !== letters?.length) {
          for (var i = 0; i < letters.length; i++) {
            if (!seats.find((j) => j.location.column === letters[i])) {
              seats.push({
                location: {
                  column: letters[i],
                  row
                },
                available: false,
                has_emergency_exit: false
              })
            }
          }
        }
        const aislePosition = this.getPlaneAislePosition(plane)
        seats = orderBy(seats, (i) => i.location.column, 'asc')
        if (aislePosition?.length) {
          for (let i = 0; i < aislePosition.length; i++) {
            if (i === 0) {
              seatsWithAisles = [
                ...seats.slice(0, aislePosition[i]),
                {
                  is_aisle: true,
                  location: {
                    column: null,
                    row
                  }
                },
                ...seats.slice(aislePosition[i])
              ]
            } else {
              seatsWithAisles = [
                ...seatsWithAisles.slice(0, aislePosition[i]),
                {
                  is_aisle: true,
                  location: {
                    column: null,
                    row
                  }
                },
                ...seatsWithAisles.slice(aislePosition[i])
              ]
            }
          }
          return seatsWithAisles
        }
      }
      return []
    },
    getSeatId(seat) {
      const plane = this.getPlane(this.step)
      return `${seat.airline_ref ?? ''} - ${seat?.location?.column ?? ''}${
        seat?.location?.row ?? ''
      } - ${plane.segment_code}`
    },
    hasPrice(seat) {
      if (!this.passengerSelected) {
        return false
      }
      let passenger = this.reservationCopy?.passengers?.find(
        (i) => i.index === this.passengerSelected
      )
      if (passenger && seat.passenger_type_prices?.length) {
        const prices =
          seat.passenger_type_prices.filter(
            (i) => i.passenger_type === passenger.passengerType
          ) ?? []
        if (prices?.length && !isNaN(prices[0].price?.consumer_price)) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    getSeatPrice(seat) {
      if (!this.passengerSelected) {
        return 0
      }
      let passenger = this.reservationCopy?.passengers?.find(
        (i) => i.index === this.passengerSelected
      )
      if (passenger && seat.passenger_type_prices?.length) {
        const prices =
          seat.passenger_type_prices.filter(
            (i) => i.passenger_type === passenger.passengerType
          ) ?? []
        if (prices?.length) {
          return prices[0].price?.consumer_price
            ? parseFloat(prices[0].price.consumer_price / 100)
            : 0
        } else {
          return 0
        }
      }
      return 0
    },
    getSeatColor(seat) {
      const price = this.getSeatPrice(seat)
      let color = this.colorsUsed?.find((i) => i.price === price) ?? null
      if (color) {
        return color
      } else {
        color = this.randomColors[0]
        if (color) {
          this.colorsUsed.push({
            price,
            currency: getAuthUser()?.value?.currency
              ? getCurrencySymbol(getAuthUser()?.value?.currency)
              : getCurrencySymbol('usd'),
            backgroundColor: color.backgroundColor,
            borderBottomColor: color.borderBottomColor
          })
          this.randomColors = this.randomColors.filter(
            (i) => i.backgroundColor !== color.backgroundColor
          )
          return color
        }
        return {
          backgroundColor: '',
          borderBottomColor: ''
        }
      }
    },
    resetRandomColors() {
      this.randomColors = [
        {
          backgroundColor: 'rgba(41, 0, 187, 0.12)',
          borderBottomColor: 'rgba(41, 0, 187)'
        },
        {
          backgroundColor: 'info-light',
          borderBottomColor: 'info-main'
        },
        {
          backgroundColor: 'success-light',
          borderBottomColor: 'success-main'
        },
        {
          backgroundColor: 'rgba(196, 190, 2, 0.12)',
          borderBottomColor: 'rgba(196, 190, 2)'
        },
        {
          backgroundColor: 'rgba(255, 152, 0, 0.12)',
          borderBottomColor: 'rgba(255, 152, 0)'
        },
        {
          backgroundColor: 'rgba(239, 83, 80, 0.12)',
          borderBottomColor: 'rgba(239, 83, 80)'
        },
        {
          backgroundColor: 'rgba(94, 50, 250, 0.12)',
          borderBottomColor: 'rgba(94, 50, 250)'
        },
        {
          backgroundColor: 'validation-positive-light',
          borderBottomColor: 'validation-positive'
        },
        {
          backgroundColor: 'validation-error-light',
          borderBottomColor: 'validation-error'
        },
        {
          backgroundColor: 'warning-light',
          borderBottomColor: 'warning-main'
        }
      ]
    },
    getPlaneWidth(plane) {
      const letters = this.getPlaneLetters(plane)
      let width = 42 * letters.length + 12
      return `${width}px`
    },
    showPlaneSummaryPrice(row) {
      const plane = this.getPlane(this.step)
      const passenger = this.reservationCopy?.passengers?.find(
        (i) => i.index === this.passengerSelected
      )
      if (row && plane?.grouped_prices?.length && passenger) {
        const passengerTypePrices = plane?.grouped_prices?.find(
          (i) => i.passenger_type === passenger.passengerType
        )
        if (passengerTypePrices?.prices?.length) {
          const price = passengerTypePrices.prices.find(
            (i) => i.from_row === row
          )
          return price
        }
        return false
      }
      return false
    },
    selectSeat(seat) {
      if (seat.available) {
        const passengerIndex = this.reservationCopy?.passengers?.findIndex(
          (i) => i.index === this.passengerSelected
        )
        const plane = this.getPlane(this.step)
        if (passengerIndex !== -1) {
          const passengerHasThisSeat = this.reservationCopy.passengers[
            passengerIndex
          ]?.seats?.find(
            (i) =>
              i.refs === seat.airline_ref &&
              i.location?.row === seat.location.row &&
              i.location?.column === seat.location.column &&
              i.segment === plane.segment_code
          )
          if (passengerHasThisSeat) {
            this.reservationCopy.passengers[passengerIndex].seats =
              this.reservationCopy.passengers[passengerIndex].seats.filter(
                (i) =>
                  i.refs !== seat.airline_ref &&
                  i.location?.row !== seat.location.row &&
                  i.location?.column !== seat.location.column &&
                  i.segment !== plane.segment_code
              )
          } else if (seat.airline_ref && !this.getSeatSelected(seat)) {
            this.reservationCopy.passengers[passengerIndex].seats =
              this.reservationCopy.passengers[passengerIndex].seats.filter(
                (i) => i.segment !== plane.segment_code
              )
            this.reservationCopy.passengers[passengerIndex]?.seats?.push({
              refs: seat.airline_ref,
              segment: plane.segment_code,
              location: {
                row: seat.location.row,
                column: seat.location.column
              }
            })
            if (
              this.getPassengersFiltered(this.reservationCopy.passengers)[
                passengerIndex + 1
              ]
            ) {
              this.passengerSelected = this.getPassengersFiltered(
                this.reservationCopy.passengers
              )[passengerIndex + 1].index
            }
          }
        }
      }
    },
    removeSeat(seat) {
      if (seat.available) {
        const plane = this.getPlane(this.step)
        for (let [
          index,
          passenger
        ] of this.reservationCopy.passengers.entries()) {
          if (
            passenger.seats.find(
              (i) =>
                i.segment === plane.segment_code &&
                i.refs === seat.airline_ref &&
                i.location?.row === seat.location.row &&
                i.location?.column === seat.location.column
            )
          ) {
            this.reservationCopy.passengers[index].seats =
              this.reservationCopy.passengers[index].seats.filter(
                (i) =>
                  i.segment !== plane.segment_code &&
                  i.refs !== seat.airline_ref &&
                  i.location?.row !== seat.location.row &&
                  i.location?.column !== seat.location.column
              )
          }
        }
      }
    },
    getSeatSelected(seat) {
      const plane = this.getPlane(this.step)
      if (seat.available) {
        for (var i = 0; i < this.reservationCopy?.passengers?.length; i++) {
          const passenger = this.reservationCopy.passengers[i]
          if (
            passenger.seats.find(
              (i) =>
                i.refs === seat.airline_ref &&
                i.location?.row === seat.location.row &&
                i.location?.column === seat.location.column &&
                i.segment === plane.segment_code
            )
          ) {
            return `${passenger.first_name.charAt(
              0
            )}${passenger.last_name.charAt(0)}`
          }
        }
        return false
      }
      return false
    },
    isColorHexadecimalOrRgb(color) {
      return color.includes('#') || color.includes('rgb')
    },
    getPassengersFiltered(passengers) {
      return passengers.filter(
        (i) => i.passengerType.toUpperCase() !== 'INFANT'
      )
    }
  }
})
</script>

<style scoped lang="scss">
.seats-form {
  max-height: 80vh;
  overflow-y: auto;
  @media (max-width: 1100px) {
    padding: 0 20px 20px 20px;
  }
  .form-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .form-title {
    margin-top: 20px;
    color: var(--brand-off-black);
  }
  .form-subtitle {
    margin-top: 20px;
    color: var(--brand-off-black);
  }
  .seats-info {
    margin-top: 20px;
    @media (min-width: 1100px) {
      display: inline-block;
      width: 80%;
      vertical-align: top;
    }
    @media (max-width: 1100px) {
      margin-bottom: 24px;
    }
    .info__subtitle {
      margin-bottom: 8px;
    }
    .info__prices {
      .prices-group {
        &:not(:last-of-type) {
          margin-bottom: 8px;
        }
        .price-color {
          width: 20px;
          height: 20px;
          border-radius: 3px;
          display: inline-block;
          margin-right: 8px;
        }
        .price-label {
          display: inline-block;
          vertical-align: top;
        }
      }
    }
  }
  .seats-selector {
    @media (min-width: 1100px) {
      display: inline-block;
      width: 20%;
      vertical-align: top;
    }
  }
  .seats-draw {
    margin-top: 24px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: 1100px) {
      overflow-x: auto;
      align-items: flex-start;
    }
    .draw__letters {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      .letter {
        text-align: center;
        width: 30px;
        &.aisle {
          margin-left: -10px;
          margin-right: -10px;
        }
      }
    }
    .draw__seat-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      .draw__seats {
        position: relative;
        &.has-summary-price {
          margin-top: calc(16px + 24px + 16px);
        }
        .seat {
          border-radius: 6px 6px 2px 2px;
          width: 30px;
          height: 40px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          position: relative;
          &.not-available {
            border-bottom: 2px solid var(--divider-default);
            background: var(--brand-off-white);
            cursor: not-allowed;
          }
          &.available {
            cursor: pointer;
          }
          i {
            position: absolute;
            cursor: default;
            &.first {
              top: calc(40px / 2 - 24px / 2);
              @media (min-width: 1100px) {
                left: -30px;
              }
              @media (max-width: 1100px) {
                left: -11px;
              }
            }
            &.last {
              top: calc(40px / 2 - 24px / 2);
              @media (min-width: 1100px) {
                right: -30px;
              }
              @media (max-width: 1100px) {
                right: -11px;
              }
            }
            &.close {
              top: -5px;
              right: -5px;
              outline: 2px solid var(--brand-white);
              border-radius: 50%;
              background-color: var(--brand-white);
              cursor: pointer;
            }
          }

          &.selected {
            background-color: var(--info-main) !important;
            border-bottom: 2px solid var(--info-main) !important;
            color: var(--brand-white);
            cursor: pointer;
          }
        }
        .aisle {
          height: 40px;
          width: 30px;
          margin-left: -10px;
          margin-right: -10px;
          vertical-align: top;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          .body1 {
            color: var(--brand-off-white);
          }
        }
        .price {
          position: absolute;
          top: -48px;
          .price-value {
            padding: 4px 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            color: var(--brand-white);
            max-width: 42px;
            margin: 0 auto;
            z-index: 1;
            position: relative;
            outline: 16px solid var(--brand-white);
          }
          .line {
            height: 1px;
            position: absolute;
            top: 12px;
            @media (min-width: 1100px) {
              left: -30px;
            }
            @media (max-width: 1100px) {
              left: -18px;
            }
            z-index: 0;
          }
        }
      }
    }
  }
}
</style>