import ALL_USER_PASSENGERS from '@/graphql/passengers/query/getAllUserPassengers.gql'

export function apolloUserPassengers(
  params: any,
  filterExists = false,
  filterSaved: any = null,
  fetchPolicy = null
) {
  return new Promise(async (resolve, reject) => {
    let apolloQuery
    let variables = {}
    try {
        variables = {
          orderBy: [
            {
              column: 'FIRST_NAME',
              order: 'ASC'
            }
          ],
          page: 1,
          first: 50,
          user_uuid: params.user_uuid
        }
        apolloQuery = ALL_USER_PASSENGERS

      resolve(await query(apolloQuery, variables, { fetchPolicy }))
    } catch (err) {
      reject(err)
    }
  })
}
