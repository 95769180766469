<template>
  <ElForm
    v-if="baggageCopy"
    ref="ruleFormRef"
    :model="baggageCopy"
    require-asterisk-position="right"
    :rules="createBaggageRules()"
    label-position="top"
  >
    <ElRow :gutter="20">
      <ElCol :span="width <= 700 ? 24 : 5" :lg="5">
        <ElFormItem prop="baggage_type" :label="$t('type')">
          <ElSelect
            v-model="baggageCopy.baggage_type"
            :placeholder="$t('select_an_option')"
            filterable
            :fallback-placements="['top', 'bottom']"
            :popper-options="getSelectPopperOptions()"
            popper-class="custom-selector"
            class="no-background"
            @input="$emit('newBaggage', baggageCopy)"
          >
            <ElOption
              v-for="item in baggageTypes"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            />
          </ElSelect>
        </ElFormItem>
      </ElCol>
      <ElCol :span="width <= 700 ? 24 : 3" :lg="3">
        <ElFormItem prop="quantity" :label="$t('quantity')">
          <ElInputNumber
            class="no-background"
            placeholder=""
            type="number"
            min="0"
            v-model="baggageCopy.quantity"
            @input="$emit('newBaggage', baggageCopy)"
          />
        </ElFormItem>
      </ElCol>
      <ElCol :span="width <= 700 ? 24 : 3" :lg="3">
        <ElFormItem prop="weight" :label="`${$t('weight')} (kg)`">
          <ElInput
            class="no-background"
            placeholder=""
            type="number"
            min="0"
            step="0.01"
            v-model="baggageCopy.weight"
            @input="$emit('newBaggage', baggageCopy)"
          />
        </ElFormItem>
      </ElCol>
      <ElCol :span="width <= 700 ? 24 : 3" :lg="3">
        <ElFormItem prop="height" :label="`${$t('height')} (cm)`">
          <ElInput
            class="no-background"
            placeholder=""
            type="number"
            min="0"
            step="0.01"
            v-model="baggageCopy.height"
            @input="$emit('newBaggage', baggageCopy)"
          />
        </ElFormItem>
      </ElCol>
      <ElCol :span="width <= 700 ? 24 : 3" :lg="3">
        <ElFormItem prop="width" :label="`${$t('width')} (cm)`">
          <ElInput
            class="no-background"
            placeholder=""
            type="number"
            min="0"
            step="0.01"
            v-model="baggageCopy.width"
            @input="$emit('newBaggage', baggageCopy)"
          />
        </ElFormItem>
      </ElCol>
      <ElCol :span="width <= 700 ? 24 : 3" :lg="3">
        <ElFormItem prop="length" :label="`${$t('length')} (cm)`">
          <ElInput
            class="no-background"
            placeholder=""
            type="number"
            min="0"
            step="0.01"
            v-model="baggageCopy.length"
            @input="$emit('newBaggage', baggageCopy)"
          />
        </ElFormItem>
      </ElCol>
      <ElCol :span="width <= 700 ? 24 : 3" :lg="3">
        <Icons
          name="24px/plus"
          @click="$emit('addBaggage', segmentIndex, passengerIndex)"
        />
        <Icons
          name="24px/trash"
          v-if="baggageIndex > 0"
          @click="
            $emit('removeBaggage', segmentIndex, passengerIndex, baggageCopy)
          "
        />
      </ElCol>
    </ElRow>
  </ElForm>
</template>

<script>
import { cloneDeep } from 'lodash'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import 'moment/dist/locale/es'
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/de'
import 'moment/dist/locale/sv'
import 'moment/dist/locale/ar'

export default defineComponent({
  props: {
    segmentIndex: {
      type: Number,
      required: false,
      default: 0
    },
    passengerIndex: {
      type: Number,
      required: false,
      default: 0
    },
    baggageIndex: {
      type: Number,
      required: false,
      default: 0
    },
    baggage: {
      type: Object,
      required: false,
      default: {}
    }
  },
  setup(props) {
    const { width } = useBreakpoints()
    const ruleFormRef = ref(null)
    const { t } = useI18n()

    return {
      width,
      ruleFormRef,
      t
    }
  },
  data() {
    return {
      baggageCopy: null,
      baggageTypes: getBaggageTypes()
    }
  },
  watch: {
    baggage: {
      handler() {
        if (this.baggage) {
          this.baggageCopy = cloneDeep(this.baggage)
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.baggage) {
      this.baggageCopy = cloneDeep(this.baggage)
    }
  },
  methods: {
    createBaggageRules() {
      return reactive({
        baggage_type: [
          {
            validator: (rule, value, callback) => {
              if (
                (this.baggageCopy.quantity ||
                  this.baggageCopy.weigth ||
                  this.baggageCopy.height ||
                  this.baggageCopy.width ||
                  this.baggageCopy.length) &&
                !this.baggageCopy.baggage_type
              ) {
                return callback(
                  this.$t('type_is_required_segment_passenger_baggage', {
                    segment: this.segmentIndex + 1,
                    passenger: this.passengerIndex + 1,
                    baggage: this.baggageIndex + 1
                  })
                )
              }
              return callback()
            },
            trigger: ['blur', 'change']
          }
        ],
        quantity: [
          {
            validator: (rule, value, callback) => {
              if (
                (this.baggageCopy.baggage_type ||
                  this.baggageCopy.weigth ||
                  this.baggageCopy.height ||
                  this.baggageCopy.width ||
                  this.baggageCopy.length) &&
                !this.baggageCopy.quantity
              ) {
                return callback(
                  this.$t('quantity_is_required_segment_passenger_baggage', {
                    segment: this.segmentIndex + 1,
                    passenger: this.passengerIndex + 1,
                    baggage: this.baggageIndex + 1
                  })
                )
              }
              return callback()
            },
            trigger: ['blur', 'change']
          }
        ],
        weigth: [
          {
            validator: (rule, value, callback) => {
              if (
                (this.baggageCopy.baggage_type ||
                  this.baggageCopy.quantity ||
                  this.baggageCopy.height ||
                  this.baggageCopy.width ||
                  this.baggageCopy.length) &&
                !this.baggageCopy.weigth
              ) {
                return callback(
                  this.$t('weight_is_required_segment_passenger_baggage', {
                    segment: this.segmentIndex + 1,
                    passenger: this.passengerIndex + 1,
                    baggage: this.baggageIndex + 1
                  })
                )
              }
              return callback()
            },
            trigger: ['blur', 'change']
          }
        ],
        height: [
          {
            validator: (rule, value, callback) => {
              if (
                (this.baggageCopy.baggage_type ||
                  this.baggageCopy.quantity ||
                  this.baggageCopy.weigth ||
                  this.baggageCopy.width ||
                  this.baggageCopy.length) &&
                !this.baggageCopy.height
              ) {
                return callback(
                  this.$t('height_is_required_segment_passenger_baggage', {
                    segment: this.segmentIndex + 1,
                    passenger: this.passengerIndex + 1,
                    baggage: this.baggageIndex + 1
                  })
                )
              }
              return callback()
            },
            trigger: ['blur', 'change']
          }
        ],
        width: [
          {
            validator: (rule, value, callback) => {
              if (
                (this.baggageCopy.baggage_type ||
                  this.baggageCopy.quantity ||
                  this.baggageCopy.weigth ||
                  this.baggageCopy.height ||
                  this.baggageCopy.length) &&
                !this.baggageCopy.width
              ) {
                return callback(
                  this.$t('width_is_required_segment_passenger_baggage', {
                    segment: this.segmentIndex + 1,
                    passenger: this.passengerIndex + 1,
                    baggage: this.baggageIndex + 1
                  })
                )
              }
              return callback()
            },
            trigger: ['blur', 'change']
          }
        ],
        length: [
          {
            validator: (rule, value, callback) => {
              if (
                (this.baggageCopy.baggage_type ||
                  this.baggageCopy.quantity ||
                  this.baggageCopy.weigth ||
                  this.baggageCopy.height ||
                  this.baggageCopy.width) &&
                !this.baggageCopy.length
              ) {
                return callback(
                  this.$t('length_is_required_segment_passenger_baggage', {
                    segment: this.segmentIndex + 1,
                    passenger: this.passengerIndex + 1,
                    baggage: this.baggageIndex + 1
                  })
                )
              }
              return callback()
            },
            trigger: ['blur', 'change']
          }
        ]
      })
    },
    async validateForm() {
      let response = true
      response = await this.ruleFormRef.validate((valid, fields) => {
        if (fields && Object.values(fields)?.length) {
          for (const field of Object.values(fields)) {
            if (field[0]?.message) {
              this.$showError(field[0].message)
              return false
            }
          }
          return true
        }
        return true
      })
      return response
    }
  }
})
</script>

<style scoped lang="scss">
.el-col {
  i {
    display: inline-block;
    position: relative;
    top: 60px;
    cursor: pointer;
    &:nth-of-type(1) {
      margin-right: 5px;
    }
  }
}
</style>