<template>
  <div v-if="flight" class="summary-form">
    <div class="form-close">
      <Icons name="24px/close" @click="$emit('close')" />
    </div>
    <div class="form-title">
      <span class="dashboard-heading">
        {{ $t('summary') }}
      </span>
    </div>
    <div class="summary__info-block">
      <div class="summary__info-block__icon">
        <Icons name="24px/plane_blue" />
      </div>
      <div class="summary__info-block__text">
        <div class="text-title">
          <span class="body1-strong">
            {{ $t('flight') }}
          </span>
        </div>
        <div class="text-main">
          <span class="body2-strong">
            {{ flight.outbound.departure.city }} {{ $t('to').toLowerCase() }}
            {{ flight.outbound.arrival.city }}
          </span>
          <span class="body2">
            {{ getFlightDates() }}
          </span>
          <span class="body2">
            {{ getNumberOfPassengersFormat() }}
          </span>
        </div>
        <div
          class="flight-type"
          v-for="type in ['outbound', 'return']"
          :key="type"
        >
          <div class="type" v-if="flight[type]" :class="type">
            <span class="body2-strong">
              {{ $t(type) }}
            </span>
            <span class="body2">
              {{ getFlightTypeDate(flight[type]) }}
            </span>
            <span class="body2">
              {{ getFlightTypeTime(flight[type]) }}
            </span>
            <span class="body2">
              {{ getFlightTypeDuration(flight[type].duration) }},
              {{ getFlightNumStops(flight[type].number_of_stops) }}
            </span>
            <span class="body2">
              {{ getMarketingCarrier(flight[type]) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="summary__info-block">
      <div class="summary__info-block__icon">
        <Icons name="24px/info_blue" />
      </div>
      <div class="summary__info-block__text">
        <div class="text-title">
          <span class="body1-strong">
            {{ $t('passengers_info') }}
          </span>
        </div>
        <div
          class="passenger"
          v-for="passenger in reservationCopy.passengers"
          :key="passenger.index"
        >
          <span class="body2-strong">
            {{ $t('passenger') }} {{ parseInt(passenger.index) + 1 }}
          </span>
          <span class="body2">
            {{ passenger.first_name }} {{ passenger.last_name }}
          </span>
          <span class="body2">
            {{ getPassengerBirthDate(passenger.date_of_birth) }}
          </span>
        </div>
      </div>
    </div>
    <div
      class="summary__info-block"
      v-if="
        reservationCopy.flight.outbound?.baggageAllowance?.hand[0]
          ?.quantity ||
        reservationCopy.flight.outbound?.baggageAllowance?.checked[0]
          ?.quantity ||
        hasAnyBaggage()
      "
    >
      <div class="summary__info-block__icon">
        <Icons name="24px/baggage_blue" />
      </div>
      <div class="summary__info-block__text">
        <div class="text-title">
          <span class="body1-strong">
            {{ $t('baggage') }}
          </span>
        </div>
        <div
          class="passenger"
          v-for="passenger in reservationCopy.passengers"
          :key="passenger.index"
        >
          <span class="body2-strong">
            {{ $t('passenger') }} {{ parseInt(passenger.index) + 1 }}
          </span>
          <span
            class="body2"
            v-for="type in ['hand', 'checked']"
            :key="type"
          >
            <span
              class="body2"
              v-if="
                reservationCopy.flight.outbound?.baggageAllowance?.[type][0]
                  ?.quantity
              "
            >
              {{
                reservationCopy.flight.outbound.baggageAllowance?.[type][0]
                  ?.description
              }}
            </span>
          </span>
          <span
            class="body2"
            v-for="(baggage, index) in getPassengerBaggage(passenger)"
            :key="index"
          >
            {{ baggage.title }} ({{ baggage.segment }})
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import 'moment-timezone'
import 'moment/dist/locale/es'
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/de'
import 'moment/dist/locale/sv'
import 'moment/dist/locale/ar'

export default defineComponent({
  props: {
    reservation: {
      type: Object,
      required: false,
      default: () => {}
    },
    step: {
      type: Number,
      required: false,
      default: 0
    },
    servicesSteps: {
      type: Number,
      required: false,
      default: 0
    },
    planes: {
      type: Array,
      required: false,
      default: []
    },
    services: {
      type: Array,
      required: false,
      default: []
    },
    flightForm: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  setup() {
    const { t } = useI18n()

    return {
      t
    }
  },
  data() {
    return {
      reservationCopy: null,
      flight: null
    }
  },
  watch: {
    reservation(newVal) {
      this.reservationCopy = cloneDeep(newVal)
    }
  },
  mounted() {
    if (this.reservation) {
      this.reservationCopy = cloneDeep(this.reservation)
      setTimeout(() => {
        this.flight = this.reservation.flight
      }, 0)
    }
  },
  methods: {
    validateForm() {
      return true
    },
    getFlightDates() {
      moment.locale('es')
      if (this.flight.outbound && this.flight.return) {
        return `${moment(this.flight.outbound.departure.date).format(
          "ddd, DD MMM 'YY"
        )} - ${moment(this.flight.return.arrival.date).format(
          "ddd, DD MMM 'YY"
        )}`
      }
      return `${moment(this.flight.outbound.departure.date).format(
        "ddd, DD MMM 'YY"
      )}`
    },
    getNumberOfPassengersFormat() {
      let text = ''
      if (this.flightForm.passengers.adults) {
        text += `${this.flightForm.passengers.adults} ${
          this.flightForm.passengers.adults > 1
            ? this.$t('adults').toLowerCase()
            : this.$t('adult').toLowerCase()
        }`
        if (
          this.flightForm.passengers.children ||
          this.flightForm.passengers.infants
        ) {
          text += ', '
        }
      }
      if (this.flightForm.passengers.children) {
        text += `${this.flightForm.passengers.children} ${
          this.flightForm.passengers.children > 1
            ? this.$t('children').toLowerCase()
            : this.$t('children_single').toLowerCase()
        }`
        if (this.flightForm.passengers.infants) {
          text += ', '
        }
      }
      if (this.flightForm.passengers.infants) {
        text += `${this.flightForm.passengers.infants} ${
          this.flightForm.passengers.infants > 1
            ? this.$t('infants').toLowerCase()
            : this.$t('infant').toLowerCase()
        }`
      }
      return text
    },
    getFlightTypeDate(type) {
      moment.locale('es')
      return `${moment(type.departure.date).format("ddd, DD MMM 'YY")}`
    },
    getFlightTypeTime(type) {
      return `${type.departure.airport_code} ${type.departure.time} - ${type.arrival.airport_code} ${type.arrival.time}`
    },
    getFlightNumStops(stops) {
      if (stops === 0) {
        return this.$t('direct')
      }
      if (stops === 1) {
        return this.$t('num_stops_single', { stop: 1 })
      }
      if (stops > 1) {
        return this.$t('num_stops_plural', { stop: stops })
      }
    },
    getFlightTypeDuration(duration) {
      const hours = duration.split(':')[0]
      const minutes = duration.split(':')[1]

      if (hours === '00' && minutes !== '00') {
        return `${minutes}m`
      }
      if (hours === '00' && minutes === '00') {
        return `0m`
      }
      if (hours !== '00' && minutes !== '00') {
        return `${parseInt(hours) < 10 ? `${parseInt(hours)}` : hours}h ${
          parseInt(minutes) < 10 ? `${parseInt(minutes)}` : minutes
        }m`
      }
      if (hours !== '00' && minutes === '00') {
        return `${parseInt(hours) < 10 ? `${parseInt(hours)}` : hours}h`
      }
    },
    getMarketingCarrier(type) {
      let segment = type.segments[0]
      return `${this.$t('flight_operated_by', {
        airline: segment.marketingCarrier.name,
        flightNumber: segment.flight_number
      })}`
    },
    getPassengerBirthDate(date) {
      moment.locale('es')
      return `${moment(date).format('DD/MM/YYYY')}`
    },
    getPassengerBaggage(passenger) {
      const baggage = []
      for (let service of this.services) {
        if (
          passenger.services?.filter(
            (i) => i.segmentReference === service.segment_code
          )?.length
        ) {
          const passengerSegmentServices =
            service.passengers?.filter(
              (i) =>
                i.passenger_type.toUpperCase() ===
                passenger.passengerType.toUpperCase()
            ) ?? []
          const passengerSegmentService = passengerSegmentServices?.length
            ? passengerSegmentServices[0]
            : null
          for (let bag of passengerSegmentService.services) {
            const passengerBag = passenger.services.find(
              (i) =>
                i.segmentReference === service.segment_code &&
                i.serviceId === bag.service_id
            )
            if (passengerBag) {
              baggage.push({
                title: bag.descriptions[0].split(':')[0],
                quantity: passengerBag.quantity,
                segment: this.getSegmentBag(passengerBag.segmentReference)
              })
            }
          }
        }
      }
      return baggage
    },
    getSegmentBag(segmentReference) {
      for (let type of ['outbound', 'return']) {
        if (this.flight[type]) {
          let segment = this.flight[type].segments.find(
            (i) => i.segment_id === segmentReference
          )
          if (segment) {
            return `${segment.departureAirport.city} - ${segment.arrivalAirport.city}`
          }
        }
      }
    },
    hasAnyBaggage() {
      let result = false
      for(let passenger of this.reservationCopy.passengers) {
        if (this.getPassengerBaggage(passenger).length) {
          result = true
          break
        }
      }
      return result
    }
  }
})
</script>

<style scoped lang="scss">
.summary-form {
  max-height: 80vh;
  overflow-y: auto;
  @media (max-width: 1100px) {
    padding: 0 20px 20px 20px;
  }
  .form-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .form-title {
    margin-top: 20px;
    color: var(--brand-off-black);
  }
  .summary__info-block {
    &:nth-of-type(1) {
      margin-top: 20px;
    }
    border-bottom: 1px dashed var(--divisor-line);
    padding: 16px 0 16px 0;
    .summary__info-block__icon {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: var(--brand-off-white);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      vertical-align: top;
    }
    .summary__info-block__text {
      display: inline-block;
      width: calc(100% - 48px - 16px);
      max-width: calc(100% - 48px - 16px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      .text-title {
        color: var(--brand-blue);
      }
      .text-main,
      .passenger {
        .body2-strong {
          display: block;
          color: var(--brand-off-black);
          margin-top: 16px;
        }
        .body2 {
          display: block;
          margin-top: 4px;
        }
      }
      .flight-type {
        .type {
          .body2-strong {
            display: block;
            color: var(--brand-off-black);
            margin-top: 16px;
          }
          .body2 {
            display: block;
            margin-top: 4px;
          }
        }
      }
    }
  }
}
</style>