<template>
  <ElForm
    require-asterisk-position="right"
    ref="ruleFormRef"
    :rules="inputRules"
    :model="form"
    label-position="top"
  >
    <ElRow
      :gutter="20"
      :style="
        width <= 1100
          ? 'max-height: 58vh; overflow-y: auto'
          : 'max-height: 66vh; overflow-y: auto'
      "
    >
      <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <ElFormItem prop="type" :label="$t('trip_type')">
          <ElSelect
            v-model="form.type"
            :placeholder="$t('select_the_trip_type')"
            filterable
            :fallback-placements="['top', 'bottom']"
            :popper-options="getSelectPopperOptions()"
            popper-class="custom-selector"
          >
            <ElOption
              v-for="item in flightTypes"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            />
          </ElSelect>
        </ElFormItem>
      </ElCol>
      <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <ElFormItem prop="origin_uuid" :label="$t('departing_from')">
          <el-select-v2
            :key="getAirportKey()"
            v-loading="getAirportKey() === 0"
            v-model="form.origin_uuid"
            :remote-method="searchAirports"
            :placeholder="$t('search_for_airport')"
            remote
            filterable
            autocomplete
            clearable
            clear-icon="Close"
            :fallback-placements="['top', 'bottom']"
            :popper-options="getSelectPopperOptions()"
            popper-class="custom-selector"
            :options="getSearchAirports()"
            style="width: 100%"
            @change="setAirportValue($event, 'origin')"
          />
        </ElFormItem>
      </ElCol>
      <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <ElFormItem prop="destination_uuid" :label="$t('destination_airport')">
          <el-select-v2
            :key="getDestinationAirportKey()"
            v-loading="getDestinationAirportKey() === 0"
            v-model="form.destination_uuid"
            :remote-method="searchDestinationAirports"
            :placeholder="$t('search_for_airport')"
            remote
            filterable
            autocomplete
            clearable
            clear-icon="Close"
            :fallback-placements="['top', 'bottom']"
            :popper-options="getSelectPopperOptions()"
            popper-class="custom-selector"
            :options="getSearchDestinationAirports()"
            style="width: 100%"
            @change="setAirportValue($event, 'destination')"
          />
        </ElFormItem>
      </ElCol>
      <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <ElFormItem
          prop="start_date"
          :label="$t('date')"
          v-if="form.type === 'ONE_WAY'"
        >
          <ElDatePicker
            v-model="form.start_date"
            type="date"
            class="!w-full"
            format="DD/MM/YYYY"
            :placeholder="$t('select_date')"
            :disabled-date="isDisableDates"
            popper-class="custom-datepicker"
            :disabled="true"
            :editable="false"
            :clearable="false"
          />
        </ElFormItem>
        <ElFormItem
          :label="`${$t('dates')}`"
          prop="start_date"
          v-if="form.type === 'ROUND_TRIP'"
        >
          <ElDatePicker
            v-model="dates"
            ref="selectDrawerDatePicker"
            class="!w-full"
            :start-placeholder="$t('select_dates')"
            :disabled-date="isDisableDates"
            type="daterange"
            format="DD/MM/YYYY"
            :teleported="false"
            placement="bottom"
            :editable="false"
            :clearable="false"
            @change="setDates($event)"
          />
        </ElFormItem>
      </ElCol>
      <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <ElFormItem prop="passengers.adults">
          <div class="first-block">
            <span class="body2-strong">{{ $t('adults') }}</span>
            <span class="body2">{{ $t('adults_subtitle') }}</span>
          </div>
          <div class="second-block">
            <ElInputNumber
              class="landing-input is-number body2-strong"
              type="number"
              step="1"
              min="1"
              v-model="form.passengers.adults"
            />
          </div>
        </ElFormItem>
      </ElCol>
      <ElCol
        :span="24"
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
        v-if="width > 1100"
      />
      <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <ElFormItem prop="passengers.children">
          <div class="first-block">
            <span class="body2-strong">{{ $t('children') }}</span>
            <span class="body2">{{ $t('children_subtitle') }}</span>
          </div>
          <div class="second-block">
            <ElInputNumber
              class="landing-input is-number body2-strong"
              type="number"
              step="1"
              min="0"
              v-model="form.passengers.children"
            />
          </div>
        </ElFormItem>
      </ElCol>
      <ElCol
        :span="24"
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
        v-if="width > 1100"
      />
      <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <ElFormItem prop="passengers.infants">
          <div class="first-block">
            <span class="body2-strong">{{ $t('infants') }}</span>
            <span class="body2">{{ $t('infants_subtitle') }}</span>
          </div>
          <div class="second-block">
            <ElInputNumber
              class="landing-input is-number body2-strong"
              type="number"
              step="1"
              min="0"
              v-model="form.passengers.infants"
            />
          </div>
        </ElFormItem>
      </ElCol>
      <ElCol
        :span="24"
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
        v-if="width > 1100"
      />
      <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <ElFormItem prop="baggage.hand">
          <div class="first-block">
            <span class="body2-strong">{{ $t('cabin_baggage') }}</span>
          </div>
          <div class="second-block">
            <ElInputNumber
              class="landing-input is-number body2-strong"
              type="number"
              step="1"
              min="0"
              max="1"
              v-model="form.baggage.hand"
            />
          </div>
        </ElFormItem>
      </ElCol>
      <ElCol
        :span="24"
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
        v-if="width > 1100"
      />
      <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <ElFormItem prop="baggage.checked">
          <div class="first-block">
            <span class="body2-strong">{{ $t('checked_baggage') }}</span>
          </div>
          <div class="second-block">
            <ElInputNumber
              class="landing-input is-number body2-strong"
              type="number"
              step="1"
              min="0"
              max="2"
              v-model="form.baggage.checked"
            />
          </div>
        </ElFormItem>
      </ElCol>
    </ElRow>
  </ElForm>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { ElMessageBox } from 'element-plus'
import moment from 'moment'
import 'moment/dist/locale/es'
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/de'
import 'moment/dist/locale/sv'
import 'moment/dist/locale/ar'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default defineComponent({
  props: {
    itineraryDay: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const { width } = useBreakpoints()
    const { t } = useI18n()
    const ruleFormRef = ref()

    const form = reactive({
      type: 'ONE_WAY',
      origin_uuid: '',
      origin_name: '',
      origin_city: '',
      destination_uuid: '',
      destination_name: '',
      destination_city: '',
      start_date: props.itineraryDay?.original_date
        ? moment(props.itineraryDay.original_date)
        : moment(),
      end_date: '',
      passengers: {
        adults: 1,
        children: 0,
        infants: 0
      },
      baggage: {
        hand: 0,
        checked: 0
      }
    })

    const inputRules = reactive({
      type: [
        {
          required: true,
          message: t('type_is_required'),
          trigger: ['blur', 'change']
        }
      ],
      origin_uuid: [
        {
          required: true,
          message: t('origin_is_required'),
          trigger: ['blur', 'change']
        }
      ],
      destination_uuid: [
        {
          required: true,
          message: t('destination_is_required'),
          trigger: ['blur', 'change']
        }
      ],
      start_date: [
        {
          validator: (rule, value, callback) => {
            if (form.type === 'ROUND_TRIP' && !form.start_date) {
              return callback(t('dates_are_required'))
            }
            if (form.type === 'ONE_WAY' && !form.start_date) {
              return callback(t('depart_date_is_required'))
            }
            callback()
          },
          trigger: ['blur', 'change']
        }
      ],
      end_date: [
        {
          validator: (rule, value, callback) => {
            if (form.type === 'ROUND_TRIP' && !form.end_date) {
              return callback(t('return_date_is_required'))
            }
            return callback()
          }
        }
      ]
    })

    const rules = {
      type: { required },
      origin_uuid: { required },
      destination_uuid: { required },
      start_date: { required },
      end_date: (value) => {
        if (value.type === 'ROUND_TRIP' && !value.end_date) {
          return false
        }
        return true
      }
    }

    const v$ = useVuelidate(rules, form)

    return {
      width,
      form,
      ruleFormRef,
      inputRules,
      v$,
      v$,
      route: useRoute(),
      ...getFormInitialVariables()
    }
  },
  data() {
    return {
      flightTypes: getFlightTypes(),
      dates: [
        this.itineraryDay?.original_date
          ? moment(this.itineraryDay.original_date)
          : moment().startOf('day'),
        this.itineraryDay?.original_date
          ? moment(this.itineraryDay.original_date)
          : moment().startOf('day')
      ]
    }
  },
  methods: {
    async validateForm() {
      await this.ruleFormRef.validate((valid, fields) => {
        if (fields && Object.values(fields)?.length) {
          for (const field of Object.values(fields)) {
            if (field[0]?.message) {
              this.$showError(field[0].message)
              return false
            }
          }
        }
      })
      if (this.v$.$invalid) {
        //
      } else {
        this.$emit('flightForm', this.form)
      }
    },
    isDisableDates(date) {
      if (date) {
        moment.locale('en')
        let dayRaw = moment(date)
        let today = moment()
        return dayRaw.isBefore(today, 'day')
      }
    },
    setDates(event) {
      if (event[0] && event[1]) {
        moment.locale('es')
        this.form.start_date = this.itineraryDay?.original_date
          ? moment(this.itineraryDay.original_date).format('YYYY-MM-DD')
          : null
        this.form.end_date = event[1]
          ? moment(event[1]).format('YYYY-MM-DD')
          : null
        this.dates = [
          moment(this.itineraryDay?.original_date),
          moment(event[1])
        ]
        if (
          !moment(this.itineraryDay.original_date)
            .startOf('day')
            .isSame(moment(event[0], 'day').startOf('day'))
        ) {
          this.$showError(
            this.$t(
              'depart_date_must_be_date_if_you_want_a_different_depart_date_select_a_different_day',
              { date: moment(this.itineraryDay.original_date).format('DD/MM/YYYY') }
            )
          )
        }
      }
    },
    setAirportValue(event, field) {
      if (field === 'origin' && getSearchAirports()?.length && event) {
        const airport = getSearchAirports().find((item) => item.value === event)
        this.form[`${field}_name`] = airport.name
        this.form[`${field}_city`] = airport.city
      } else if (
        field === 'destination' &&
        getSearchDestinationAirports()?.length &&
        event
      ) {
        const airport = getSearchDestinationAirports().find(
          (item) => item.value === event
        )
        this.form[`${field}_name`] = airport.name
        this.form[`${field}_city`] = airport.city
      }
    }
  }
})
</script>

<style scoped lang="scss">
.el-row {
  .el-col {
    .el-form-item {
      :deep(.el-form-item__content) {
        .first-block {
          width: calc(50% - 2px);
          margin-right: 2px;
          span {
            display: block;
            &:nth-of-type(1) {
              color: var(--brand-off-black);
            }
            &:nth-of-type(2) {
              color: var(--text-secondary);
            }
          }
        }
        .second-block {
          text-align: right;
          width: calc(50% - 2px);
        }
      }
    }
  }
}
</style>