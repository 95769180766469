import { useI18n } from 'vue-i18n';
import { baggageTypes } from '~/models/baggage-types';

export const getBaggageTypes = () => {
    const { t } = useI18n();
    return baggageTypes.map((item) => {
        return {
            label: t('baggage_types.' + item.toLowerCase()),
            value: item.toUpperCase()
        };
    });
}
