<template>
  <div>
    <el-drawer
      :model-value="value"
      direction="rtl"
      class="left-drawer"
      :size="width > 1100 ? '475px' : '100%'"
      @close="$emit('close')"
    >
      <template #header>
        <span class="dashboard-heading">{{ $t('flight_details') }}</span>
      </template>
      <template #default>
        <div class="detail-container">
          <div class="detail-price">
            <span class="body2">
              {{ $t('total_for_number_travellers', { number: numPassengers }) }}
            </span>
            <span class="body1-strong">
              {{
                `${
                  flightSelected.price.consumer_price
                    ? Math.round(
                        parseFloat(flightSelected.price.consumer_price / 100)
                      )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                    : 0
                }${getCurrencySymbol(
                  flightSelected.price.consumer_currency.toLowerCase() ?? 'usd'
                )}`
              }}
            </span>
          </div>
          <div
            class="detail-info-container"
            v-for="type in ['outbound', 'return']"
            :key="type"
          >
            <div class="detail-info" v-if="flightSelected[type]">
              <span class="heading5">
                {{
                  $t('flight_to', {
                    to: flightSelected[type].arrival.city
                  })
                }}
              </span>
              <span class="body2">
                {{ getFlightNumStops(flightSelected[type].number_of_stops) }}:
                {{ getFlightDuration(flightSelected[type].duration) }}
              </span>
            </div>
            <div class="detail-timeline" v-if="flightSelected[type]">
              <el-timeline>
                <el-timeline-item
                  v-for="item in getTimelineItems(
                    flightSelected[type].segments
                  )"
                  :class="item.class"
                  :key="item.index"
                >
                  <div class="timeline__item-info">
                    <span class="body2">
                      {{ item.date }}, {{ item.time }}
                    </span>
                    <span class="body2">
                      <span class="body2-strong"> {{ item.airport_name }} </span
                      >&nbsp;{{ item.airport_code }}
                    </span>
                  </div>
                  <div class="timeline__item-extra">
                    <div
                      class="extra-icon flight"
                      v-if="item.class === 'flight'"
                    >
                      <img
                        v-if="item.marketingCarrierLogo"
                        :src="item.marketingCarrierLogo"
                        alt="carrier-logo"
                      />
                    </div>
                    <div class="extra-icon scale" v-if="item.class === 'scale'">
                      <Icons name="24px/clock" />
                    </div>
                    <div
                      class="extra-info flight"
                      v-if="item.class === 'flight'"
                    >
                      <span class="small">
                        {{ item.marketingCarrier }}
                      </span>
                      <span class="small">
                        {{ item.flight_number }} -
                        {{ getNameFormatted(item.cabinClass) }}
                      </span>
                      <span class="small">
                        {{
                          $t('flight_time', {
                            time: getFlightDuration(item.duration)
                          })
                        }}
                      </span>
                    </div>
                    <div class="extra-info scale" v-if="item.class === 'scale'">
                      <span class="small">
                        {{
                          $t('layover_time', {
                            time: getFlightLayoverDuration(item.scale_duration)
                          })
                        }}
                      </span>
                    </div>
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
          </div>
        </div>
      </template>
      <template #footer v-if="showActions">
        <ElButton
          type="primary"
          class="full-width"
          @click="$emit('confirmBooking')"
        >
          {{ $t('book_and_customise') }}
        </ElButton>
      </template>
    </el-drawer>
  </div>
</template>

<script>
import moment from 'moment'
import 'moment/dist/locale/es'
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/de'
import 'moment/dist/locale/sv'
import 'moment/dist/locale/ar'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    flightSelected: {
      type: Object,
      required: false,
      default: () => {}
    },
    flightForm: {
      type: Object,
      required: false,
      default: () => {}
    },
    numPassengers: {
      type: Number,
      required: false,
      default: 1
    },
    showActions: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props) {
    const { width } = useBreakpoints()

    return {
      width
    }
  },
  methods: {
    getTimelineItems(segments) {
      let timeline = []
      moment.locale('es')
      if (segments?.length) {
        let timelineIndex = 0
        segments.forEach((elem, index) => {
          timeline.push({
            index: timelineIndex,
            airport_code: elem.departureAirport?.airport_code,
            airport_name: elem.departureAirport?.airport_name,
            time: elem.departureAirport?.time,
            date: elem.departureAirport?.date
              ? moment(elem.departureAirport.date).format('ddd DD MMM YYYY')
              : null,
            cabinClass: elem.cabinClass,
            flight_number: elem.flight_number,
            duration: elem.duration,
            marketingCarrier: this.getNameFormatted(
              elem.marketingCarrier?.name ?? ''
            ),
            marketingCarrierLogo: elem.marketingCarrier?.logo?.url ?? null,
            scale_duration: elem.scale_duration,
            cabinClass: elem.cabinClass,
            class: 'flight'
          })

          timelineIndex++

          if (index !== segments.length - 1) {
            timeline.push({
              index: timelineIndex,
              airport_code: elem.arrivalAirport?.airport_code,
              airport_name: elem.arrivalAirport?.airport_name,
              time: elem.arrivalAirport?.time,
              date: elem.arrivalAirport?.date
                ? moment(elem.arrivalAirport.date).format('ddd DD MMM YYYY')
                : null,
              cabinClass: elem.cabinClass,
              flight_number: elem.flight_number,
              duration: elem.duration,
              marketingCarrier: this.getNameFormatted(
                elem.marketingCarrier?.name ?? ''
              ),
              marketingCarrierLogo: elem.marketingCarrier?.logo?.url ?? null,
              scale_duration: elem.scale_duration,
              cabinClass: elem.cabinClass,
              class: 'scale'
            })
            timelineIndex++
          }

          if (index === segments.length - 1) {
            timeline.push({
              index: timelineIndex,
              airport_code: elem.arrivalAirport?.airport_code,
              airport_name: elem.arrivalAirport?.airport_name,
              time: elem.arrivalAirport?.time,
              date: elem.arrivalAirport?.date
                ? moment(elem.arrivalAirport.date).format('ddd DD MMM YYYY')
                : null,
              cabinClass: elem.cabinClass,
              flight_number: elem.flight_number,
              duration: elem.duration,
              marketingCarrier: this.getNameFormatted(
                elem.marketingCarrier?.name ?? ''
              ),
              marketingCarrierLogo: elem.marketingCarrier?.logo?.url ?? null,
              scale_duration: elem.scale_duration,
              cabinClass: elem.cabinClass,
              class: 'end'
            })
            timelineIndex++
          }
        })
      }
      return timeline
    },
    getNameFormatted(name) {
      return `${name.charAt(0).toUpperCase()}${name.slice(1).toLowerCase()}`
    },
    getFlightNumStops(stops) {
      if (stops === 0) {
        return this.$t('direct')
      }
      if (stops === 1) {
        return this.$t('num_stops_single', { stop: 1 })
      }
      if (stops > 1) {
        return this.$t('num_stops_plural', { stop: stops })
      }
    },
    getFlightDuration(duration) {
      const hours = duration.split(':')[0]
      const minutes = duration.split(':')[1]

      if (hours === '00' && minutes !== '00') {
        return `${minutes}m`
      }
      if (hours === '00' && minutes === '00') {
        return `0m`
      }
      if (hours !== '00' && minutes !== '00') {
        return `${parseInt(hours) < 10 ? `${parseInt(hours)}` : hours}h ${
          parseInt(minutes) < 10 ? `${parseInt(minutes)}` : minutes
        }m`
      }
      if (hours !== '00' && minutes === '00') {
        return `${parseInt(hours) < 10 ? `${parseInt(hours)}` : hours}h`
      }
    },
    getFlightLayoverDuration(duration) {
      const hours = duration.split(':')[0].replace('h', '')
      const minutes = duration.split(':')[1].replace('m', '')
      if (hours !== '00' && minutes !== '00') {
        return `${parseInt(hours) < 10 ? `${parseInt(hours)}` : hours}h ${
          parseInt(minutes) < 10 ? `${parseInt(minutes)}` : minutes
        }m`
      }
      if (hours !== '00' && minutes === '00') {
        return `${parseInt(hours) < 10 ? `${parseInt(hours)}` : hours}h`
      }
      if (hours === '00' && minutes !== '00') {
        return `${parseInt(minutes) < 10 ? `${parseInt(minutes)}` : minutes}m`
      }
      if (hours === '00' && minutes === '00') {
        return '0m'
      }
    }
  }
})
</script>

<style scoped lang="scss">
:deep(.el-overlay) {
  .left-drawer {
    .el-drawer__body {
      .detail-container {
        .detail-price {
          margin-bottom: 32px;
          .body2,
          .body1-strong {
            display: block;
            color: var(--brand-off-black);
          }
        }
        .detail-info {
          margin-bottom: 24px;
          .heading5,
          .body2 {
            display: block;
          }

          .body2 {
            margin-top: 4px;
          }
        }
        .detail-timeline {
          .el-timeline {
            .el-timeline-item {
              padding-bottom: 16px !important;
              .el-timeline-item__wrapper {
                .el-timeline-item__content {
                  position: relative;
                  top: -3px;
                  .timeline__item-info {
                    max-width: 90%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    .body2 {
                      display: block;
                      &:nth-of-type(2) {
                        color: var(--brand-mid-grey);
                      }
                    }
                    .body2-strong {
                      color: var(--brand-off-black);
                    }
                  }
                  .timeline__item-extra {
                    margin-top: 16px;
                    margin-bottom: 16px;
                    padding-top: 16px;
                    .extra-icon {
                      display: inline-block;
                      &.scale {
                        width: 24px;
                      }
                      &.flight {
                        width: 40px;
                        height: 40px;
                        img {
                          width: 100%;
                          height: 100%;
                          object-fit: contain;
                          border-radius: 50%;
                          position: relative;
                          border: 1px solid var(--brand-off-black);
                          top: -4px;
                        }
                      }
                      margin-right: 8px;
                      i {
                        position: relative;
                        top: 2px;
                      }
                    }
                    .extra-info {
                      &.scale {
                        width: calc(100% - 24px - 8px);
                      }
                      &.flight {
                        width: calc(100% - 40px - 8px);
                      }
                      display: inline-block;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                      .small {
                        display: block;
                        color: var(--brand-off-black);
                      }
                    }
                  }
                }
                .el-timeline-item__timestamp {
                  display: none;
                }
              }
              .el-timeline-item__node {
                height: 24px;
                width: 24px;
                background-image: url('~/assets/icons/24px/check_circle_empty.svg') !important;
                background-size: 24px 24px !important;
                background-color: var(--brand-white);
                background-repeat: no-repeat;
                background-position: center;
                outline: 4px solid var(--brand-white);
              }
              .el-timeline-item__tail {
                left: 10px !important;
              }
              &.flight {
                .el-timeline-item__tail {
                  border-left: 1px solid var(--brand-off-black);
                }
              }
              &.scale {
                .el-timeline-item__tail {
                  border-left: 1px dotted var(--brand-off-black);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>