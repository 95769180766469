<template>
  <div class="div-container" v-if="flight">
    <div class="div-wrapper">
      <div class="booking-container">
        <div class="booking-passengers" v-if="step === 1">
          <ItinerariesRoutesFlightsPassengersPassengerForm
            ref="passengerForm"
            :passengers="passengers"
            :reservation="reservationCopy"
            @close="$emit('close')"
          />
        </div>
        <div
          class="booking-bags"
          v-if="step > 1 && step <= servicesSteps + 1 && step !== lastStep"
        >
          <ItinerariesRoutesFlightsBaggageForm
            :reservation="reservationCopy"
            ref="baggageForm"
            :services-steps="servicesSteps"
            :services="services"
            :step="step"
            @close="$emit('close')"
          />
        </div>
        <div
          class="booking-seats"
          v-if="step > servicesSteps + 1 && step !== lastStep"
        >
          <ItinerariesRoutesFlightsSeatsForm
            :planes="planes"
            :step="step"
            :services-steps="servicesSteps"
            :reservation="reservationCopy"
            ref="seatsForm"
            @close="$emit('close')"
          />
        </div>
        <div class="booking-summary" v-if="step === lastStep">
          <ItinerariesRoutesFlightsSummaryForm
            :reservation="reservationCopy"
            ref="summaryForm"
            :step="step"
            :services-steps="servicesSteps"
            :services="services"
            :planes="planes"
            :flight-form="flightForm"
            @step="step = $event"
            @close="$emit('close')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import 'moment-timezone'
import 'moment/dist/locale/es'
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/de'
import 'moment/dist/locale/sv'
import 'moment/dist/locale/ar'
import { ElLoading, ElMessageBox } from 'element-plus'
import { cloneDeep } from 'lodash'
import { useI18n } from 'vue-i18n'
import BOOK_FLIGHT from '~/graphql/flight-reservations/mutation/bookFlight.gql'

export default defineComponent({
  props: {
    flightForm: {
      type: Object,
      required: false,
      default: () => {}
    },
    reservation: {
      type: Object,
      required: false,
      default: () => {}
    },
    user: {
      type: Object,
      required: false,
      default: () => {}
    },
    itineraryDayLocation: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      reservationCopy: null,
      flight: null,
      numPassengers: 1,
      passengers: [],
      planes: null,
      services: null,
      step: 1,
      lastStep: 2, // steps: pasajeros, summary final
      servicesSteps: 0,
      expirationInterval: null,
      offerExpiration: null
    }
  },
  watch: {
    reservation(newVal) {
      this.reservationCopy = cloneDeep(newVal)
    },
    step(newVal) {
      if (newVal === this.lastStep) {
        this.$emit('flightIsLastStep', true)
      } else {
        this.$emit('flightIsLastStep', false)
      }
    }
  },
  mounted() {
    this.$emit('flightIsLastStep', false)
    if (this.reservation) {
      this.reservationCopy = cloneDeep(this.reservation)
      this.numPassengers =
        (this.flightForm?.passengers?.adults ?? 0) +
        (this.flightForm?.passengers?.children ?? 0) +
        (this.flightForm?.passengers?.infants ?? 0)

      this.getItineraryPassengers()

      setTimeout(() => {
        this.flight = this.reservation.flight

        this.clearExpirationCountdown()
        this.offerExpiration = moment.utc(this.flight.offer_expiration)

        this.startExpirationCountdown()

        this.getFlightPlaneSeats()
        this.getFlightServices()
      }, 0)
    } else {
      this.$emit('back')
    }
  },
  methods: {
    getNameFormatted(name) {
      return `${name.charAt(0).toUpperCase()}${name.slice(1).toLowerCase()}`
    },
    getFlightDate(date) {
      if (date) {
        moment.locale('es')
        return moment(date).format('DD MMM')
      }
      return ''
    },
    getFlightNumStops(stops) {
      if (stops === 0) {
        return this.$t('direct')
      }
      if (stops === 1) {
        return this.$t('num_stops_single', { stop: 1 })
      }
      if (stops > 1) {
        return this.$t('num_stops_plural', { stop: stops })
      }
    },
    getFlightDuration(duration) {
      const hours = duration.split(':')[0]
      const minutes = duration.split(':')[1]

      if (hours === '00') {
        return `${minutes}m`
      }
      if (hours !== '00' && minutes !== '00') {
        return `${parseInt(hours) < 10 ? `${parseInt(hours)}` : hours}h ${
          parseInt(minutes) < 10 ? `${parseInt(minutes)}` : minutes
        }m`
      }
      if (hours !== '00' && minutes === '00') {
        return `${parseInt(hours) < 10 ? `${parseInt(hours)}` : hours}h`
      }
    },
    next() {
      if (this.step === 1) {
        // validar datos de pasajeros
        const isValid = this.$refs.passengerForm.validateForm()
        if (!isValid) {
          return
        }
        this.reservationCopy.passengers =
          this.$refs.passengerForm.getPassengers()
        this.step++
        return
      }
      if (this.step > 1 && this.step <= this.servicesSteps + 1) {
        // validar datos de maletas
        this.reservationCopy.passengers = this.$refs.baggageForm.getPassengers()
        this.step++
        return
      }
      if (this.step > this.servicesSteps + 1 && this.step !== this.lastStep) {
        // validar datos de asientos
        const isValid = this.$refs.seatsForm.validateForm()
        if (!isValid) {
          return
        }
        this.reservationCopy.passengers = this.$refs.seatsForm.getSeats()
        this.step++
        return
      }
      if (this.step === this.lastStep) {
        // confirmar reserva
        this.confirmBooking()
        return
      }
    },
    back() {
      if (this.step === 1) {
        this.$emit('back')
        return
      } else {
        this.step--
        return
      }
    },
    async confirmBooking() {
      this.loading = true
      this.loadingDialog = ElLoading.service({
        fullscreen: true,
        background: '#F7F7F7',
        svg: useLoaderSvg(),
        svgViewBox: useLoaderSvgViewBox()
      })
      moment.locale('es')
      let variables = {
        user_uuid: getAuthUser().value.uuid,
        provider_id: this.flight.provider_id,
        passengers: this.reservationCopy.passengers.map((passenger) => {
          let item = {
            passenger: passenger.uuid
              ? {
                  update: {
                    uuid: passenger.uuid,
                    first_name: passenger.first_name,
                    last_name: passenger.last_name,
                    date_of_birth: passenger.date_of_birth
                      ? moment(passenger.date_of_birth).format('YYYY-MM-DD')
                      : null,
                    phone: passenger.phone,
                    phone_country: passenger.phone_country,
                    email: passenger.email,
                    gender: passenger.gender
                  }
                }
              : {
                  create: {
                    first_name: passenger.first_name,
                    last_name: passenger.last_name,
                    date_of_birth: passenger.date_of_birth
                      ? moment(passenger.date_of_birth).format('YYYY-MM-DD')
                      : null,
                    phone: passenger.phone,
                    phone_country: passenger.phone_country,
                    email: passenger.email,
                    gender: passenger.gender
                  }
                },
            document: {
              country: passenger.document_type_country,
              document_number: passenger.document_number,
              document_type: passenger.document_type.toUpperCase(),
              expiration_date: passenger.expiration_date
                ? moment(passenger.expiration_date).format('YYYY-MM-DD')
                : null
            },
            seats: passenger.seats ?? [],
            services:
              passenger.services.map((service) => {
                return {
                  ...service,
                  type: 'SERVICE',
                  action: 'CREATE'
                }
              }) ?? []
          }
          if (item.seats?.length === 0) {
            delete item.seats
          }
          if (item.services?.length === 0) {
            delete item.services
          }
          return item
        }),
        model_uuid: this.itineraryDayLocation.uuid,
        model_type: 'ItineraryDayLocation'
      }
      const response = await mutation(BOOK_FLIGHT, variables)
      if (response.error) {
        this.loadingDialog.close()
        this.$showError(response.error, this)
        this.$sentry(response.error, 'confirmBooking')
        this.loading = false
      }
      if (response.result) {
        this.loadingDialog.close()
        this.loading = false
        this.$showSuccess(this.$t('new_entity_success'))
        this.$emit('create')
      }
    },
    async getItineraryPassengers() {
      try {
        this.loadingDialog = ElLoading.service({
          fullscreen: true,
          background: '#F7F7F7',
          svg: useLoaderSvg(),
          svgViewBox: useLoaderSvgViewBox()
        })
        this.loading = true
        const passengers = await apolloUserPassengers({
          user_uuid: this.user.uuid
        })
        if (!passengers || (passengers && !passengers.getAllPassengers)) {
          throw showError({
            statusCode: 404,
            message: 'Page not found',
            fatal: true
          })
        }
        this.passengers = cloneDeep(passengers?.getAllPassengers?.data ?? [])
      } catch (err) {
        this.$showError(err, this.t)
        this.$sentry(err, 'getItineraryPassengers')
      } finally {
        this.loadingDialog.close()
        this.loading = false
      }
    },
    async getFlightPlaneSeats() {
      try {
        this.loadingDialog = ElLoading.service({
          fullscreen: true,
          text: this.$t('getting_flight_seats'),
          background: '#F7F7F7',
          svg: useLoaderSvg(),
          svgViewBox: useLoaderSvgViewBox()
        })
        this.loading = true
        const plane = await apolloFlightPlaneSeats({
          providerId: this.flight.provider_id
        })
        if (!plane || (plane && !plane.getFlightPlaneSeats)) {
          throw showError({
            statusCode: 404,
            message: 'Page not found',
            fatal: true
          })
        }
        this.planes = cloneDeep(plane.getFlightPlaneSeats)
        this.planes = this.planes.filter((i) => i.segment_code)
        // se añaden tantos pasos como escalas haya
        this.lastStep +=
          this.planes.filter((i) => i.plane_displays?.length)?.length ?? 0
      } catch (err) {
        if (
          err[0]?.message?.includes("Offer doesn't exist") ||
          err[0]?.message?.includes('Offer already expired')
        ) {
          this.showRefreshDialog()
        } else {
          this.$showError(err, this.t)
          this.$sentry(err, 'getFlightPlaneSeats')
        }
      } finally {
        this.loadingDialog.close()
        this.loading = false
      }
    },
    async getFlightServices() {
      try {
        this.loadingDialog = ElLoading.service({
          fullscreen: true,
          text: this.$t('getting_flight_services'),
          background: '#F7F7F7',
          svg: useLoaderSvg(),
          svgViewBox: useLoaderSvgViewBox()
        })
        this.loading = true
        const services = await apolloFlightServices({
          providerId: this.flight.provider_id
        })
        if (!services || (services && !services.getFlightServices)) {
          throw showError({
            statusCode: 404,
            message: 'Page not found',
            fatal: true
          })
        }
        this.servicesSteps = 0
        this.services = cloneDeep(services.getFlightServices)
        this.services = this.services.filter((i) => i.segment_code)
        const segments = []
        for (let service of this.services) {
          if (!segments.find((i) => i === service.segment_code)) {
            segments.push(service.segment_code)
            this.lastStep++
            this.servicesSteps++
          }
        }
      } catch (err) {
        if (
          err[0]?.message?.includes("Offer doesn't exist") ||
          err[0]?.message?.includes('Offer already expired')
        ) {
          this.showRefreshDialog()
        } else if (err[0]?.message?.includes('BAGGAGE')) {
          this.getFlightServices()
        } else {
          this.$showError(err, this.t)
          this.$sentry(err, 'getFlightServices')
        }
      } finally {
        this.loadingDialog.close()
        this.loading = false
      }
    },
    clearExpirationCountdown() {
      if (this.expirationInterval) {
        clearInterval(this.expirationInterval)
        this.expirationInterval = null
      }
    },
    startExpirationCountdown() {
      this.clearExpirationCountdown()

      this.expirationInterval = setInterval(() => {
        const nowLocal = moment()
        const targetLocal = this.offerExpiration.local()

        const duration = moment.duration(targetLocal.diff(nowLocal))

        if (duration.asMilliseconds() <= 0) {
          clearInterval(this.expirationInterval)
          this.showRefreshDialog()
        }
      }, 1000)
    },
    showRefreshDialog() {
      ElMessageBox.confirm(
        this.$t(
          'flight_fares_and_availability_are_frequently_updated_update_your_search_to_see_the_latest_options'
        ),
        this.$t('prices_may_have_changed'),
        {
          confirmButtonText: this.$t('update'),
          cancelButtonText: this.$t('cancel'),
          confirmButtonClass: 'el-button--primary dark',
          cancelButtonClass: 'el-button--secondary dark',
          customClass: 'custom-delete-box'
        }
      )
        .then(() => {
          this.$emit('back')
        })
        .catch(() => {
          this.$emit('back')
        })
    },
    resetSeatsAndServices() {
      for (let i = 0; i < this.reservationCopy.passengers.length; i++) {
        this.reservationCopy.passengers[i].seats = []
        this.reservationCopy.passengers[i].services = []
      }
    }
  }
})
</script>

<style scoped lang="scss">
</style>