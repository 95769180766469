<template>
  <div class="div-container">
    <div class="intro-container">
      <div class="intro-title">
        <span class="body2-strong"> {{ $t('search_flights') }}: </span>
      </div>
      <div class="intro-form" v-if="flightFormCopy">
        <div class="form-first-block">
          <div class="first-block">
            <el-select-v2
              :key="getAirportKey()"
              v-loading="getAirportKey() === 0"
              v-model="flightFormCopy.origin_uuid"
              :remote-method="searchAirports"
              :placeholder="$t('search_for_airport')"
              remote
              filterable
              autocomplete
              clearable
              clear-icon="Close"
              :fallback-placements="['top', 'bottom']"
              :popper-options="getSelectPopperOptions()"
              popper-class="custom-selector"
              :options="
                getSearchAirports({
                  uuid: flightFormCopy.origin_uuid,
                  name: flightFormCopy.origin_name,
                  city: flightFormCopy.origin_city
                })
              "
              style="width: 100%"
              @change="setAirportValue($event, 'origin')"
            />
          </div>
          <div class="second-block">
            <ElButton type="icon rounded" @click="swapLocations()">
              <Icons name="24px/flights" />
            </ElButton>
          </div>
          <div class="third-block">
            <el-select-v2
              :key="getDestinationAirportKey()"
              v-loading="getDestinationAirportKey() === 0"
              v-model="flightFormCopy.destination_uuid"
              :remote-method="searchDestinationAirports"
              :placeholder="$t('search_for_airport')"
              remote
              filterable
              autocomplete
              clearable
              clear-icon="Close"
              :fallback-placements="['top', 'bottom']"
              :popper-options="getSelectPopperOptions()"
              popper-class="custom-selector"
              :options="
                getSearchDestinationAirports({
                  uuid: flightFormCopy.destination_uuid,
                  name: flightFormCopy.destination_name,
                  city: flightFormCopy.destination_city
                })
              "
              style="width: 100%"
              @change="setAirportValue($event, 'destination')"
            />
          </div>
        </div>
        <div class="form-second-block">
          <div
            class="first-block"
            :class="
              flightFormCopy.type === 'ONE_WAY' ? 'one-way' : 'round-trip'
            "
          >
            <ElDatePicker
              v-model="flightFormCopy.start_date"
              type="date"
              format="DD/MM/YYYY"
              class="!w-full"
              :placeholder="$t('select_date')"
              :disabled-date="isDisableDates"
              popper-class="custom-datepicker"
              :disabled="true"
              :editable="false"
              :clearable="false"
            />
          </div>
          <div class="second-block" v-if="flightFormCopy.type === 'ROUND_TRIP'">
            <ElDatePicker
              v-model="flightFormCopy.end_date"
              type="date"
              format="DD/MM/YYYY"
              class="!w-full"
              :placeholder="$t('select_date')"
              :disabled-date="isDisableDates"
              popper-class="custom-datepicker"
              :editable="false"
              :clearable="false"
            />
          </div>
          <div class="third-block">
            <ElButton type="icon rounded" @click="filter()">
              <Icons name="24px/search" />
            </ElButton>
          </div>
        </div>
      </div>
      <div class="intro-filter" v-if="flightFormCopy">
        <el-dropdown
          trigger="click"
          v-if="flightFormCopy.type"
          popper-class="flights-dropdown"
          :teleported="false"
        >
          <div class="selector-container">
            <Icons name="16px/flights_white" />
            <span class="body2">
              {{ $t(`flight_types.${flightFormCopy.type?.toLowerCase()}`) }}
            </span>
            <Icons name="16px/chevron_down_white" />
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-for="item in flightTypes" :key="item.value">
                <ElCheckbox
                  class="background-blue rounded xl"
                  v-model="item.selected"
                  @change=";(flightFormCopy.type = item.value), setFilters()"
                />
                <span class="body2">
                  {{ item.label }}
                </span></el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown
          trigger="click"
          v-if="flightFormCopy.type"
          popper-class="flights-dropdown"
          :teleported="false"
          :hide-on-click="false"
        >
          <div class="selector-container">
            <span class="body2">
              {{ $t('travellers') }} ({{
                flightFormCopy.passengers.adults +
                flightFormCopy.passengers.children +
                flightFormCopy.passengers.infants
              }})
            </span>
            <Icons name="16px/chevron_down_white" />
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <div class="first-block">
                  <span class="body2-strong">{{ $t('adults') }}</span>
                  <span class="body2">{{ $t('adults_subtitle') }}</span>
                </div>
                <div class="second-block">
                  <ElInputNumber
                    type="number"
                    step="1"
                    min="1"
                    v-model="flightFormCopy.passengers.adults"
                  />
                </div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="first-block">
                  <span class="body2-strong">{{ $t('children') }}</span>
                  <span class="body2">{{ $t('children_subtitle') }}</span>
                </div>
                <div class="second-block">
                  <ElInputNumber
                    type="number"
                    step="1"
                    min="0"
                    v-model="flightFormCopy.passengers.children"
                  />
                </div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="first-block">
                  <span class="body2-strong">{{ $t('infants') }}</span>
                  <span class="body2">{{ $t('infants_subtitle') }}</span>
                </div>
                <div class="second-block">
                  <ElInputNumber
                    type="number"
                    step="1"
                    min="0"
                    v-model="flightFormCopy.passengers.infants"
                  />
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown
          trigger="click"
          v-if="flightFormCopy.type"
          popper-class="flights-dropdown"
          :teleported="false"
          :hide-on-click="false"
        >
          <div class="selector-container">
            <span class="body2">
              {{ $t('baggages') }} ({{
                flightFormCopy.baggage.checked + flightFormCopy.baggage.hand
              }})
            </span>
            <Icons name="16px/chevron_down_white" />
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <div class="first-block">
                  <span class="body2-strong">{{ $t('cabin_baggage') }}</span>
                </div>
                <div class="second-block">
                  <ElInputNumber
                    type="number"
                    step="1"
                    min="0"
                    max="1"
                    v-model="flightFormCopy.baggage.hand"
                  />
                </div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="first-block">
                  <span class="body2-strong">{{ $t('checked_baggage') }}</span>
                </div>
                <div class="second-block">
                  <ElInputNumber
                    type="number"
                    step="1"
                    min="0"
                    max="2"
                    v-model="flightFormCopy.baggage.checked"
                  />
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="flights-container" v-if="showResults">
      <div class="flights-title">
        <span class="heading3">{{ $t('available_flights') }}</span>
      </div>
      <div class="flights-filter">
        <ElButton type="secondary" @click="filterDrawer = true">
          {{ $t('filter_and_sort') }}
          <Icons name="16px/filtering_white" />
        </ElButton>
      </div>
      <div
        class="flights-list"
        v-if="flights?.searchFlights?.data?.length"
        v-infinite-scroll="loadMoreItems"
      >
        <div
          class="flight"
          v-for="(flight, index) in flights.searchFlights.data"
          :key="index"
          :class="flight.outbound && flight.return ? 'two-way' : 'one-way'"
        >
          <div class="flight-timeline">
            <div
              class="type"
              v-for="type in ['outbound', 'return']"
              :key="type"
            >
              <div
                :class="
                  type === 'outbound' ? 'flight-outbound' : 'flight-return'
                "
                v-if="flight[type]"
              >
                <div class="flight-title">
                  <span class="caption-strong">
                    {{
                      getNameFormatted(
                        flight[type].segments[0].marketingCarrier.name
                      )
                    }}
                  </span>
                </div>
                <div class="flight-info">
                  <div class="first-block">
                    <img
                      v-if="flight[type].segments[0].marketingCarrier.logo"
                      :src="flight[type].segments[0].marketingCarrier.logo.url"
                      alt="carrier-logo"
                    />
                  </div>
                  <div class="second-block">
                    <span class="body2-strong">
                      {{ flight[type].departure.time }}
                    </span>
                    <span class="caption">
                      {{ flight[type].departure.airport_code }},
                      {{ getFlightDate(flight[type].departure.date) }}
                    </span>
                  </div>
                  <div class="third-block">
                    <div class="num-stops">
                      <span
                        class="small"
                        :class="
                          flight[type].number_of_stops === 0 ? 'direct' : ''
                        "
                        >{{
                          getFlightNumStops(flight[type].number_of_stops)
                        }}</span
                      >
                    </div>
                    <div class="flight-divider">
                      <div class="dot first" />
                      <div class="divider" />
                      <div class="dot second" />
                    </div>
                    <div class="duration">
                      <span class="small">{{
                        getFlightDuration(flight[type].duration)
                      }}</span>
                    </div>
                  </div>
                  <div class="fourth-block">
                    <span class="body2-strong">
                      {{ flight[type].arrival.time }}
                    </span>
                    <span class="caption">
                      {{ flight[type].arrival.airport_code }},
                      {{ getFlightDate(flight[type].arrival.date) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flight-price">
            <div class="price-info">
              <span class="body1-strong">
                {{
                  `${
                    flight.price.consumer_price
                      ? Math.round(
                          parseFloat(flight.price.consumer_price / 100)
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                      : 0
                  }${getCurrencySymbol(
                    flight.price.consumer_currency.toLowerCase() ?? 'usd'
                  )}`
                }}
              </span>
              <span class="caption" v-if="numPassengers > 1">
                {{
                  $t('for_number_travellers', {
                    number: numPassengers
                  })
                }}
              </span>
            </div>
            <div class="price-actions">
              <ElButton type="link dark arrow" @click="getFlight(flight)">
                {{ $t('view_details') }}
                <Icons name="16px/arrow_right" />
              </ElButton>
            </div>
          </div>
        </div>
      </div>
      <div class="content-empty" v-else>
        <div class="empty-title">
          <span class="heading3">
            {{ $t('we_couldn_t_find_any_exact_matches_for_that_search') }}.
            {{ $t('try') }}
            <span
              class="heading3 underline cursor-pointer"
              @click="filterDrawer = true"
            >
              {{ $t('changing_or_removing_some_of_your_filters') }}
            </span>
          </span>
        </div>
        <div class="empty-icon">
          <Icons name="24px/heart_filled" />
        </div>
      </div>
    </div>
    <el-drawer
      v-model="filterDrawer"
      direction="rtl"
      modal-class="left-drawer"
      :size="width > 1100 ? '475px' : '100%'"
      @close="filterDrawer = false"
    >
      <template #header>
        <span class="dashboard-heading">{{ $t('filter_and_sort') }}</span>
      </template>
      <template #default>
        <ElForm require-asterisk-position="right" label-position="top">
          <ElRow :gutter="20">
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem :label="$t('sort_by')">
                <ElSelect
                  v-model="flightFormCopy.sort"
                  placeholder=""
                  filterable
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  class="landing-select body2-strong"
                  popper-class="custom-selector"
                >
                  <ElOption
                    v-for="item in sortByOptions"
                    :label="item.label"
                    :value="item.value"
                    :key="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-collapse v-model="filterCollapse">
                <el-collapse-item name="1">
                  <template #title>
                    <span class="body2-strong">
                      {{ $t('stops') }}
                    </span>
                    <Icons
                      name="16px/minus"
                      class="filter-icon"
                      v-if="filterCollapse.includes('1')"
                    />
                    <Icons name="16px/plus" class="filter-icon" v-else />
                  </template>
                  <div class="filter-flights-container">
                    <div
                      v-for="item in stopsOptions"
                      :key="item.value"
                      class="stop"
                    >
                      <ElCheckbox
                        class="background-blue rounded xl"
                        v-model="item.selected"
                        @change="selectStopItem(item)"
                      />
                      <span class="body2">
                        {{ item.label }}
                      </span>
                    </div>
                  </div>
                </el-collapse-item>
                <el-collapse-item name="2">
                  <template #title>
                    <span class="body2-strong">
                      {{ $t('outbound_flight_times') }}
                    </span>
                    <Icons
                      name="16px/minus"
                      class="filter-icon"
                      v-if="filterCollapse.includes('2')"
                    />
                    <Icons name="16px/plus" class="filter-icon" v-else />
                  </template>
                  <label class="body2 form-price-label">
                    {{ $t('departure') }}:
                    {{
                      getFilterFlightTimesFormat(timeFilter.outbound.departure)
                    }}
                  </label>
                  <div class="form-price">
                    <el-slider
                      range
                      v-model="timeFilter.outbound.departure"
                      :max="287"
                      :show-tooltip="false"
                      show-stops
                      @change="
                        changeTimeFilter($event, 'outbound', 'departure')
                      "
                    />
                  </div>
                  <label class="body2 form-price-label">
                    {{ $t('arrival') }}:
                    {{
                      getFilterFlightTimesFormat(timeFilter.outbound.arrival)
                    }}
                  </label>
                  <div class="form-price">
                    <el-slider
                      range
                      v-model="timeFilter.outbound.arrival"
                      :max="287"
                      :show-tooltip="false"
                      show-stops
                      @change="changeTimeFilter($event, 'outbound', 'arrival')"
                    />
                  </div>
                </el-collapse-item>
                <el-collapse-item name="3">
                  <template #title>
                    <span class="body2-strong">
                      {{ $t('return_flight_times') }}
                    </span>
                    <Icons
                      name="16px/minus"
                      class="filter-icon"
                      v-if="filterCollapse.includes('3')"
                    />
                    <Icons name="16px/plus" class="filter-icon" v-else />
                  </template>
                  <label class="body2 form-price-label">
                    {{ $t('departure') }}:
                    {{
                      getFilterFlightTimesFormat(timeFilter.return.departure)
                    }}
                  </label>
                  <div class="form-price">
                    <el-slider
                      range
                      v-model="timeFilter.return.departure"
                      :max="287"
                      :show-tooltip="false"
                      show-stops
                      @change="changeTimeFilter($event, 'return', 'departure')"
                    />
                  </div>
                  <label class="body2 form-price-label">
                    {{ $t('arrival') }}:
                    {{ getFilterFlightTimesFormat(timeFilter.return.arrival) }}
                  </label>
                  <div class="form-price">
                    <el-slider
                      range
                      v-model="timeFilter.return.arrival"
                      :max="287"
                      :show-tooltip="false"
                      show-stops
                      @change="changeTimeFilter($event, 'return', 'arrival')"
                    />
                  </div>
                </el-collapse-item>
                <el-collapse-item name="4">
                  <template #title>
                    <span class="body2-strong">
                      {{ $t('stopover_duration') }}
                    </span>
                    <Icons
                      name="16px/minus"
                      class="filter-icon"
                      v-if="filterCollapse.includes('4')"
                    />
                    <Icons name="16px/plus" class="filter-icon" v-else />
                  </template>
                  <label class="body2 form-price-label">
                    {{
                      getFilterFlightDurationsFormat(
                        durationFilter.scale.slider
                      )
                    }}
                  </label>
                  <div class="form-price">
                    <el-slider
                      range
                      v-model="durationFilter.scale.slider"
                      :max="48"
                      :show-tooltip="false"
                      show-stops
                      @change="changeDurationFilter($event, 'scale')"
                    />
                  </div>
                </el-collapse-item>
                <el-collapse-item name="5">
                  <template #title>
                    <span class="body2-strong">
                      {{ $t('flight_duration') }}
                    </span>
                    <Icons
                      name="16px/minus"
                      class="filter-icon"
                      v-if="filterCollapse.includes('5')"
                    />
                    <Icons name="16px/plus" class="filter-icon" v-else />
                  </template>
                  <label class="body2 form-price-label">
                    {{
                      getFilterFlightDurationsFormat(
                        durationFilter.flight.slider
                      )
                    }}
                  </label>
                  <div class="form-price">
                    <el-slider
                      range
                      v-model="durationFilter.flight.slider"
                      :max="48"
                      :show-tooltip="false"
                      show-stops
                      @change="changeDurationFilter($event, 'flight')"
                    />
                  </div>
                </el-collapse-item>
                <el-collapse-item name="6">
                  <template #title>
                    <span class="body2-strong">
                      {{ $t('what_price_range') }}
                    </span>
                    <Icons
                      name="16px/minus"
                      class="filter-icon"
                      v-if="filterCollapse.includes('6')"
                    />
                    <Icons name="16px/plus" class="filter-icon" v-else />
                  </template>
                  <div class="form-price">
                    <el-slider
                      v-model="priceFilter"
                      range
                      show-stops
                      :show-tooltip="false"
                      :max="50000"
                      @change="changePriceRangeFilter($event)"
                    />
                  </div>
                  <div class="form-price-input-from">
                    <label class="caption">{{ $t('from') }}</label>
                    <ElInput
                      class="landing-input"
                      type="number"
                      v-model="priceFilterMin"
                      @change="changePriceRangeFilter([$event, null])"
                    />
                  </div>
                  <div class="form-price-input-to">
                    <label class="caption">{{ $t('to_uppercase') }}</label>
                    <ElInput
                      class="landing-input"
                      type="number"
                      v-model="priceFilterMax"
                      @change="changePriceRangeFilter([null, $event])"
                    />
                  </div>
                </el-collapse-item>
                <el-collapse-item name="7">
                  <template #title>
                    <span class="body2-strong">
                      {{ $t('airlines') }}
                    </span>
                    <Icons
                      name="16px/minus"
                      class="filter-icon"
                      v-if="filterCollapse.includes('7')"
                    />
                    <Icons name="16px/plus" class="filter-icon" v-else />
                  </template>
                  <UtilsFilterAirlines
                    @selectedAirline="flightFormCopy.airlines = $event"
                  />
                </el-collapse-item>
              </el-collapse>
            </ElCol>
          </ElRow>
        </ElForm>
      </template>
      <template #footer>
        <ElButton type="secondary" @click="resetFilter()" :loading="loading">
          {{ $t('clear') }}
        </ElButton>
        <ElButton type="primary" @click="filter()" :loading="loading">
          {{ $t('show_flights') }}
        </ElButton>
      </template>
    </el-drawer>
    <ItinerariesRoutesFlightsFlightDetailDrawer
      :value="showFlightDrawer"
      :flight-selected="flightSelected"
      :flight-form="flightForm"
      :num-passengers="numPassengers"
      @close="showFlightDrawer = false"
      @confirmBooking="confirmBooking($event)"
    />
  </div>
</template>

<script>
import moment from 'moment'
import 'moment-timezone'
import 'moment/dist/locale/es'
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/de'
import 'moment/dist/locale/sv'
import 'moment/dist/locale/ar'
import { ElLoading, ElMessageBox } from 'element-plus'
import { cloneDeep } from 'lodash'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    flightForm: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  setup() {
    const { width, height } = useBreakpoints()
    const { t } = useI18n()
    const { $sentry } = useNuxtApp()
    const router = useRouter()
    const route = useRoute()
    const config = useRuntimeConfig()

    const initialVariables = []

    let objectToReturn = {
      width,
      height,
      t,
      router,
      route,
      $sentry,
      config
    }
    for (const variable of initialVariables) {
      objectToReturn = Object.assign(objectToReturn, variable)
    }

    return objectToReturn
  },
  data() {
    return {
      loading: false,
      loadingDialog: null,
      totalPages: null,
      currentPage: null,
      page: 1,
      flights: null,
      flightTypes: getFlightTypes(),
      flightFormCopy: {
        type: null,
        origin_uuid: null,
        origin_name: null,
        origin_city: null,
        destination_uuid: null,
        destination_name: null,
        destination_city: null,
        start_date: null,
        end_date: null,
        sort: '',
        stops: null,
        airlines: null,
        passengers: {
          adults: 1,
          children: 0,
          infants: 0
        },
        baggage: {
          hand: 0,
          checked: 0
        }
      },
      stopsOptions: [
        {
          value: 'ANY',
          label: this.$t('any_number_of_stops'),
          selected: true
        },
        {
          value: 'DIRECT',
          label: this.$t('non_stop_only'),
          selected: false
        },
        {
          value: 'ONE_STOP_OR_LESS',
          label: this.$t('one_stop_or_fewer'),
          selected: false
        },
        {
          value: 'TWO_STOPS_OR_MORE',
          label: this.$t('two_stops_or_more'),
          selected: false
        }
      ],
      numPassengers: 1,
      filterDrawer: false,
      filterCollapse: ['1', '2', '3', '4', '5', '6', '7'],
      flightSelected: null,
      showResults: false,
      showFlightDrawer: false,
      priceFilterMin: ref(''),
      priceFilterMax: ref(''),
      priceFilter: ref([0, 50000]),
      timeFilter: {
        outbound: {
          departure: [0, 287],
          arrival: [0, 287],
          departureMinTime: '00:00',
          departureMaxTime: '23:55',
          arrivalMinTime: '00:00',
          arrivalMaxTime: '23:55'
        },
        return: {
          departure: [0, 287],
          arrival: [0, 287],
          departureMinTime: '00:00',
          departureMaxTime: '23:55',
          arrivalMinTime: '00:00',
          arrivalMaxTime: '23:55'
        }
      },
      durationFilter: {
        scale: {
          slider: [0, 48],
          min: 0,
          max: 24
        },
        flight: {
          slider: [0, 48],
          min: 0,
          max: 24
        }
      },
      expirationInterval: null,
      offerExpiration: null
    }
  },
  computed: {
    sortByOptions() {
      return [
        {
          value: 'CHEAPEST',
          label: this.$t('more_economical')
        },
        {
          value: 'FASTEST',
          label: this.$t('quicker')
        }
      ]
    }
  },
  watch: {
    flightForm(newVal) {
      if (newVal) {
        this.flightFormCopy = cloneDeep(newVal)
        this.setFilters()
        this.searchFlights(true)
      }
    }
  },
  mounted() {
    if (this.flightForm) {
      this.flightFormCopy = cloneDeep(this.flightForm)
      this.setFilters()
      this.searchFlights(true)
      initialAirports()
      searchAirports(
        this.flightFormCopy.origin_name,
        {
          uuid: this.flightFormCopy.origin_uuid,
          name: this.flightFormCopy.origin_name,
          city: this.flightFormCopy.origin_city
        },
        null
      )

      searchDestinationAirports(
        this.flightFormCopy.destination_name,
        {
          uuid: this.flightFormCopy.destination_uuid,
          name: this.flightFormCopy.destination_name,
          city: this.flightFormCopy.destination_city
        },
        null
      )
    } else {
      this.$emit('back')
    }
  },
  beforeUnmount() {
    this.clearExpirationCountdown()
  },
  methods: {
    async searchFlights(refetch = false) {
      try {
        this.loading = true
        this.loadingDialog = ElLoading.service({
          fullscreen: true,
          text: this.$t('loading_flights'),
          background: '#F7F7F7',
          svg: useLoaderSvg(),
          svgViewBox: useLoaderSvgViewBox()
        })
        let variables = {
          input: {
            type: this.flightFormCopy.type,
            origin: this.flightFormCopy.origin_name,
            destination: this.flightFormCopy.destination_name,
            start_date: this.flightFormCopy.start_date
              ? moment(this.flightFormCopy.start_date).format('YYYY-MM-DD')
              : null,
            passengers: this.flightFormCopy.passengers
          },
          baggage: {},
          price: {},
          stopoverTimeRange: {},
          durationRange: {},
          outboundDepartureTimeRange: {},
          outboundArrivalTimeRange: {},
          returnDepartureTimeRange: {},
          returnArrivalTimeRange: {},
          airlines: null,
          stops: null,
          sort: null,
          page: this.page,
          first: 30
        }

        if (this.flightFormCopy.type === 'ROUND_TRIP') {
          variables.input.end_date = this.flightFormCopy.end_date
            ? moment(this.flightFormCopy.end_date).format('YYYY-MM-DD')
            : null
        }
        if (this.flightFormCopy.baggage?.checked) {
          variables.baggage.checked = this.flightFormCopy.baggage.checked
        }
        if (this.flightFormCopy.baggage?.hand) {
          variables.baggage.hand = this.flightFormCopy.baggage.hand
        }
        if (
          !this.flightFormCopy.baggage.hand &&
          !this.flightFormCopy.baggage.checked
        ) {
          delete variables.baggage
        }

        if (this.flightFormCopy.stops) {
          variables.stops = this.flightFormCopy.stops
        } else {
          delete variables.stops
        }

        if (this.flightFormCopy.airlines?.length) {
          variables.airlines = {
            airlines_codes: this.flightFormCopy.airlines
          }
        } else {
          delete variables.airlines
        }

        if (this.flightFormCopy.sort) {
          variables.sort = this.flightFormCopy.sort
        } else {
          delete variables.sort
        }

        if (!isNaN(this.flightFormCopy.price?.max)) {
          variables.price.max = this.flightFormCopy.price.max
        }

        if (!isNaN(this.flightFormCopy.price?.min)) {
          variables.price.min = this.flightFormCopy.price.min
        }

        if (
          !this.flightFormCopy.price?.max &&
          !this.flightFormCopy.price?.min
        ) {
          delete variables.price
        }

        if (
          this.durationFilter.scale.min !== 0 ||
          this.durationFilter.scale.max !== 24
        ) {
          variables.stopoverTimeRange = {
            minDuration: this.durationFilter.scale.min * 60,
            maxDuration: this.durationFilter.scale.max * 60
          }
        } else {
          delete variables.stopoverTimeRange
        }

        if (
          this.durationFilter.flight.min !== 0 ||
          this.durationFilter.flight.max !== 24
        ) {
          variables.durationRange = {
            minDuration: this.durationFilter.flight.min * 60,
            maxDuration: this.durationFilter.flight.max * 60
          }
        } else {
          delete variables.durationRange
        }

        if (
          this.timeFilter.outbound.departureMinTime !== '00:00' ||
          this.timeFilter.outbound.departureMaxTime !== '23:55'
        ) {
          variables.outboundDepartureTimeRange = {
            min: this.timeFilter.outbound.departureMinTime + ':00',
            max: this.timeFilter.outbound.departureMaxTime + ':00'
          }
        } else {
          delete variables.outboundDepartureTimeRange
        }

        if (
          this.timeFilter.outbound.arrivalMinTime !== '00:00' ||
          this.timeFilter.outbound.arrivalMaxTime !== '23:55'
        ) {
          variables.outboundArrivalTimeRange = {
            min: this.timeFilter.outbound.arrivalMinTime + ':00',
            max: this.timeFilter.outbound.arrivalMaxTime + ':00'
          }
        } else {
          delete variables.outboundArrivalTimeRange
        }

        if (
          this.timeFilter.return.departureMinTime !== '00:00' ||
          this.timeFilter.return.departureMaxTime !== '23:55'
        ) {
          variables.returnDepartureTimeRange = {
            min: this.timeFilter.return.departureMinTime + ':00',
            max: this.timeFilter.return.departureMaxTime + ':00'
          }
        } else {
          delete variables.returnDepartureTimeRange
        }

        if (
          this.timeFilter.return.arrivalMinTime !== '00:00' ||
          this.timeFilter.return.arrivalMaxTime !== '23:55'
        ) {
          variables.returnArrivalTimeRange = {
            min: this.timeFilter.return.arrivalMinTime + ':00',
            max: this.timeFilter.return.arrivalMaxTime + ':00'
          }
        } else {
          delete variables.returnArrivalTimeRange
        }

        const flights = await apolloFlights(
          variables,
          true,
          {},
          refetch ? 'network-only' : null
        )
        this.numPassengers =
          this.flightFormCopy.passengers.adults +
          this.flightFormCopy.passengers.children +
          this.flightFormCopy.passengers.infants

        const data = flights?.searchFlights?.data
        this.flights = {
          searchFlights: {
            data: (this.flights?.searchFlights?.data ?? [])
              .concat(data)
              .reduce(
                (unique, item) =>
                  unique.find((i) => i.provider_id === item.provider_id)
                    ? unique
                    : [...unique, item],
                []
              ),
            paginatorInfo: flights?.searchFlights?.paginatorInfo
          }
        }
        this.totalPages = this.flights?.searchFlights?.paginatorInfo?.lastPage
        this.currentPage =
          this.flights?.searchFlights?.paginatorInfo?.currentPage
        this.showFlightDrawer = false
        this.filterDrawer = false
        this.flightSelected = null
        this.clearExpirationCountdown()
        if (this.flights.searchFlights.data?.length) {
          this.offerExpiration = moment.utc(
            this.flights.searchFlights.data[0].offer_expiration
          )

          this.startExpirationCountdown()
        }
      } catch (err) {
        if (
          err?.length &&
          err[0]?.error?.message?.includes(
            'No available journey for this search.'
          )
        ) {
          //
          this.flights = null
        } else {
          this.$showError(err, this.t)
          this.$sentry(err, 'searchFlights')
        }
      } finally {
        this.loading = false
        this.showResults = true
        this.loadingDialog.close()
      }
    },
    isDisableDates(date) {
      if (date) {
        moment.locale('en')
        let dayRaw = moment(date)
        let today = moment()
        return dayRaw.isBefore(today, 'day')
      }
    },
    swapLocations() {
      this.page = 1
      let originUuid = this.flightFormCopy.origin_uuid
      let originName = this.flightFormCopy.origin_name
      let originCity = this.flightFormCopy.origin_city
      this.flightFormCopy.origin_uuid = this.flightFormCopy.destination_uuid
      this.flightFormCopy.origin_name = this.flightFormCopy.destination_name
      this.flightFormCopy.origin_city = this.flightFormCopy.destination_city
      this.flightFormCopy.destination_uuid = originUuid
      this.flightFormCopy.destination_name = originName
      this.flightFormCopy.destination_city = originCity
      this.resetFlights()
      this.searchFlights(true)
    },
    setFilters() {
      this.flightTypes = this.flightTypes.map((item) => {
        item.selected = item.value === this.flightFormCopy.type
        return item
      })
      this.stopsOptions = this.stopsOptions.map((elem) => {
        let selected = false
        if (
          this.flightFormCopy.stops &&
          elem.value === this.flightFormCopy.stops
        ) {
          selected = true
        } else if (!this.flightFormCopy.stops && elem.value === 'ANY') {
          selected = true
        }
        return {
          value: elem.value,
          label: elem.label,
          selected
        }
      })
    },
    resetFilter() {
      this.page = 1
      this.resetFlights()
      this.flightFormCopy.sort = ''
      this.flightFormCopy.stops = null
      this.flightFormCopy.airlines = null
      this.timeFilter = {
        outbound: {
          departure: [0, 287],
          arrival: [0, 287],
          departureMinTime: '00:00',
          departureMaxTime: '23:55',
          arrivalMinTime: '00:00',
          arrivalMaxTime: '23:55'
        },
        return: {
          departure: [0, 287],
          arrival: [0, 287],
          departureMinTime: '00:00',
          departureMaxTime: '23:55',
          arrivalMinTime: '00:00',
          arrivalMaxTime: '23:55'
        }
      }
      this.durationFilter = {
        scale: {
          slider: [0, 48],
          min: 0,
          max: 24
        },
        flight: {
          slider: [0, 48],
          min: 0,
          max: 24
        }
      }
      this.filterDrawer = false
      this.priceFilterMin = ''
      this.priceFilterMax = ''
      this.priceFilter = [0, 50000]
      this.searchFlights(true)
    },
    filter() {
      if (
        this.flightFormCopy.type === 'ROUND_TRIP' &&
        !this.flightFormCopy.end_date
      ) {
        this.$showError(this.$t('return_date_is_required'))
        return
      }
      if (
        this.flightFormCopy.type === 'ROUND_TRIP' &&
        this.flightFormCopy.start_date &&
        this.flightFormCopy.end_date &&
        moment(this.flightFormCopy.start_date).isAfter(
          this.flightFormCopy.end_date
        )
      ) {
        this.$showError(
          this.$t('return_date_must_be_greater_than_departure_date')
        )
        return
      }
      this.page = 1
      this.resetFlights()
      const stop = this.stopsOptions.find((item) => item.selected)
      if (stop?.value !== 'ANY') {
        this.flightFormCopy.stops = stop.value
      } else {
        this.flightFormCopy.stops = null
      }
      if (this.priceFilterMin || this.priceFilterMax) {
        this.flightFormCopy.price = {
          min: parseInt(this.priceFilterMin ?? 0),
          max: parseInt(this.priceFilterMax ?? 50000)
        }
      }
      this.filterDrawer = false
      this.searchFlights(true)
    },
    resetFlights() {
      this.flights = {
        searchFlights: {
          data: [],
          paginatorInfo: {}
        }
      }
    },
    getFlightDate(date) {
      if (date) {
        moment.locale('es')
        return moment(date).format('DD MMM')
      }
      return ''
    },
    setAirportValue(event, field) {
      if (field === 'origin' && getSearchAirports()?.length && event) {
        const airport = getSearchAirports().find((item) => item.value === event)
        this.flightFormCopy[`${field}_name`] = airport.name
        this.flightFormCopy[`${field}_city`] = airport.city
      } else if (
        field === 'destination' &&
        getSearchDestinationAirports()?.length &&
        event
      ) {
        const airport = getSearchDestinationAirports().find(
          (item) => item.value === event
        )
        this.flightFormCopy[`${field}_name`] = airport.name
        this.flightFormCopy[`${field}_city`] = airport.city
      }
    },
    getFlightNumStops(stops) {
      if (stops === 0) {
        return this.$t('direct')
      }
      if (stops === 1) {
        return this.$t('num_stops_single', { stop: 1 })
      }
      if (stops > 1) {
        return this.$t('num_stops_plural', { stop: stops })
      }
    },
    getFlightDuration(duration) {
      const hours = duration.split(':')[0]
      const minutes = duration.split(':')[1]

      if (hours === '00') {
        return `${minutes}m`
      }
      if (hours !== '00' && minutes !== '00') {
        return `${parseInt(hours) < 10 ? `${parseInt(hours)}` : hours}h ${
          parseInt(minutes) < 10 ? `${parseInt(minutes)}` : minutes
        }m`
      }
      if (hours !== '00' && minutes === '00') {
        return `${parseInt(hours) < 10 ? `${parseInt(hours)}` : hours}h`
      }
    },
    selectStopItem(item) {
      this.stopsOptions = this.stopsOptions.map((elem) => {
        return {
          value: elem.value,
          label: elem.label,
          selected: elem.value === item.value && item.selected
        }
      })
    },
    async loadMoreItems() {
      if (
        this.page === this.currentPage &&
        this.currentPage < this.totalPages
      ) {
        this.page++
        this.searchFlights(true)
      }
    },
    async getFlight(item) {
      try {
        this.loadingDialog = ElLoading.service({
          fullscreen: true,
          text: this.$t('getting_flight_details'),
          background: '#F7F7F7',
          svg: useLoaderSvg(),
          svgViewBox: useLoaderSvgViewBox()
        })
        this.loading = true
        const flight = await apolloFlight({
          providerId: item.provider_id
        })
        if (!flight || (flight && !flight.getFlightDetails)) {
          throw showError({
            statusCode: 404,
            message: 'Page not found',
            fatal: true
          })
        }
        this.flightSelected = cloneDeep(flight.getFlightDetails)
        this.showFlightDrawer = true
      } catch (err) {
        if (
          err[0]?.message?.includes("Offer doesn't exist") ||
          err[0]?.message?.includes('Oferta caducada')
        ) {
          this.showRefreshDialog()
        } else {
          this.$showError(err, this.t)
          this.$sentry(err, 'getFlight')
        }
      } finally {
        this.loadingDialog.close()
        this.loading = false
      }
    },
    getNameFormatted(name) {
      return `${name.charAt(0).toUpperCase()}${name.slice(1).toLowerCase()}`
    },
    changePriceRangeFilter(event) {
      if (event[0] !== null && event[1] !== null) {
        this.priceFilterMin = event[0] ?? 0
        this.priceFilterMax = event[1] ?? 50000
      } else if (event[0] && event[1] === null) {
        this.priceFilterMin = parseInt(event[0])
        this.priceFilterMax = this.priceFilterMax ? this.priceFilterMax : 50000
        this.priceFilter = [this.priceFilterMin, this.priceFilterMax]
      } else if (event[0] === null && event[1]) {
        this.priceFilterMin = this.priceFilterMin ? this.priceFilterMin : 0
        this.priceFilterMax = parseInt(event[1])
        this.priceFilter = [this.priceFilterMin, this.priceFilterMax]
      }
    },
    changeTimeFilter(event, field, type) {
      if (event[0] !== null && event[1] !== null) {
        this.timeFilter[field][`${type}MinTime`] =
          this.getFilterFlightTimeFormat(event[0])
        this.timeFilter[field][`${type}MaxTime`] =
          this.getFilterFlightTimeFormat(event[1])
      }
    },
    getFilterFlightTimesFormat(times) {
      if (times[0] === 0 && times[1] === 287) {
        return this.$t('at_any_time')
      }
      return `${this.getFilterFlightTimeFormat(
        times[0]
      )} - ${this.getFilterFlightTimeFormat(times[1])}`
    },
    getFilterFlightTimeFormat(time) {
      const totalMinutes = time * 5
      const hours = Math.floor(totalMinutes / 60)
      const minutes = totalMinutes % 60

      const formattedHours = String(hours).padStart(2, '0')
      const formattedMinutes = String(minutes).padStart(2, '0')

      return `${formattedHours}:${formattedMinutes}`
    },
    changeDurationFilter(event, field) {
      if (event[0] !== null && event[1] !== null) {
        this.durationFilter[field].min = this.getFilterFlightDurationFormat(
          event[0]
        )
        this.durationFilter[field].max = this.getFilterFlightDurationFormat(
          event[1]
        )
      }
    },
    getFilterFlightDurationsFormat(durations) {
      if (durations[0] === 0 && durations[1] === 48) {
        return this.$t('any_duration')
      }
      return `${this.getFilterFlightDurationFormat(
        durations[0]
      )}h - ${this.getFilterFlightDurationFormat(durations[1])}h`
    },
    getFilterFlightDurationFormat(duration) {
      return duration / 2
    },
    clearExpirationCountdown() {
      if (this.expirationInterval) {
        clearInterval(this.expirationInterval)
        this.expirationInterval = null
      }
    },
    startExpirationCountdown() {
      this.clearExpirationCountdown()

      this.expirationInterval = setInterval(() => {
        const nowLocal = moment()
        const targetLocal = this.offerExpiration.local()

        const duration = moment.duration(targetLocal.diff(nowLocal))

        if (duration.asMilliseconds() <= 0) {
          clearInterval(this.expirationInterval)
          this.showRefreshDialog()
        }
      }, 1000)
    },
    showRefreshDialog() {
      ElMessageBox.confirm(
        this.$t(
          'flight_fares_and_availability_are_frequently_updated_update_your_search_to_see_the_latest_options'
        ),
        this.$t('prices_may_have_changed'),
        {
          confirmButtonText: this.$t('update'),
          cancelButtonText: this.$t('cancel'),
          confirmButtonClass: 'el-button--primary dark',
          cancelButtonClass: 'el-button--secondary dark',
          customClass: 'custom-delete-box'
        }
      )
        .then(() => {
          this.searchFlights(true)
        })
        .catch(() => {
          this.searchFlights(true)
        })
    },
    confirmBooking(event) {
      const passengers = []
      let index = 0
      Object.entries(this.flightForm.passengers).forEach(([type, count]) => {
        for (let i = 0; i < count; i++) {
          passengers.push({
            index: index.toString(),
            passengerTypeIndex: i.toString(),
            uuid: '',
            first_name: '',
            last_name: '',
            phone: '',
            phone_country: '+1',
            email: '',
            date_of_birth: '',
            gender: '',
            document_type: '',
            document_type_country: 'US',
            document_number: '',
            expiration_date: '',
            no_expiry: false,
            passengerType:
              type === 'adults'
                ? 'ADULT'
                : type === 'children'
                ? 'CHILD'
                : 'INFANT',
            seats: [],
            services: []
          })
          index++
        }
      })
      this.$emit('flightForm', this.flightForm)
      this.$emit('confirmBooking', {
        flight: {
          ...this.flightSelected
        },
        passengers
      })
    }
  }
})
</script>

<style scoped lang="scss">
.div-container {
  margin: 0 !important;
  padding: 0 !important;
  .intro-container {
    border-radius: 8px 8px 0 0;
    @media (min-width: 1100px) {
      padding: 20px;
    }
    @media (max-width: 1100px) {
      padding: 20px;
    }
    background-color: var(--brand-off-black);
    color: var(--brand-white);
    position: relative;
    .intro-form {
      margin-top: 16px;
      margin-bottom: 16px;
      .form-first-block {
        @media (min-width: 1100px) {
          display: inline-block;
          width: 70%;
        }
        @media (max-width: 1100px) {
          margin-bottom: 24px;
        }
        .first-block {
          display: inline-block;
          width: calc(50% - 8px - 20px);
          margin-right: 8px;
        }
        .second-block {
          display: inline-block;
          width: 40px;
          margin-right: 8px;
        }
        .third-block {
          display: inline-block;
          @media (min-width: 1100px) {
            width: calc(50% - 8px - 40px);
          }
          @media (max-width: 1100px) {
            width: calc(50% - 8px - 20px);
          }
        }
      }
      .form-second-block {
        @media (min-width: 1100px) {
          display: inline-block;
          width: 30%;
        }
        .first-block {
          display: inline-block;
          margin-right: 8px;
          &.one-way {
            width: calc(100% - 8px - 40px);
          }
          &.round-trip {
            width: calc(50% - 4px - 4px - 20px);
          }
        }
        .second-block {
          display: inline-block;
          width: calc(50% - 4px - 4px - 20px);
          margin-right: 8px;
        }
        .third-block {
          display: inline-block;
          width: 40px;
        }
      }
    }
    .intro-filter {
      padding-top: 8px;
      @media (max-width: 1100px) {
        display: flex;
        justify-content: space-between;
      }
      .el-dropdown {
        @media (min-width: 1100px) {
          &:not(:last-of-type) {
            margin-right: 6px;
          }
        }
        .selector-container {
          i {
            display: inline-block;
            position: relative;
            top: 3px;
            @media (min-width: 1100px) {
              &:nth-of-type(1) {
                margin-right: 8px;
              }
            }
            @media (max-width: 1100px) {
              &:nth-of-type(1) {
                margin-right: 7px;
              }
            }
          }
          .body2 {
            color: var(--brand-white);
            display: inline-block;
            @media (min-width: 1100px) {
              margin-right: 8px;
            }
          }
        }
        :deep(.flights-dropdown) {
          width: 280px;
          background-color: var(--brand-off-black);
          border: 1px solid transparent !important;
          box-shadow: 0 0 0 0 !important;
          padding: 0 !important;
          .el-scrollbar {
            .el-dropdown__list {
              .el-dropdown-menu {
                padding: 16px !important;
                background-color: var(--brand-off-black);
                .el-dropdown-menu__item {
                  padding-left: 0 !important;
                  padding-right: 0 !important;
                  &:hover,
                  &:focus {
                    background-color: transparent !important;
                  }
                  &:not(:last-of-type) {
                    margin-bottom: 16px;
                  }
                  .el-checkbox {
                    margin-right: 8px;
                  }
                  .body2 {
                    color: var(--brand-white);
                  }

                  .first-block {
                    width: calc(40% - 2px);
                    margin-right: 2px;
                    white-space: normal;
                    span {
                      display: block;
                      &:nth-of-type(1) {
                        color: var(--brand-white);
                      }
                      &:nth-of-type(2) {
                        color: var(--brand-off-white);
                      }
                    }
                  }
                  .second-block {
                    text-align: right;
                    width: calc(50% - 2px);
                  }
                }
              }
            }
          }
          .el-popper__arrow {
            display: none;
          }
        }
      }
    }
  }

  .flights-container {
    width: 100%;
    text-align: left;
    display: block;
    position: relative;
    background-color: var(--brand-white);
    @media (min-width: 1100px) {
      padding: 16px 20px 0px 20px;
    }
    @media (max-width: 1100px) {
      padding: 16px 20px 0px 20px;
    }
    .flights-title {
      display: inline-block;
      .heading3 {
        color: var(--brand-off-black);
      }
      @media (min-width: 700px) {
        width: calc(100% - 141px);
      }
      @media (max-width: 700px) {
        margin-bottom: 4px;
      }
    }
    .flights-filter {
      @media (min-width: 700px) {
        display: inline-block;
        .el-button {
          width: 100%;
          position: relative;
          top: -9px;
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          i {
            position: absolute !important;
            right: 24px !important;
            margin-right: 0 !important;
          }
        }
      }
      @media (max-width: 700px) {
        position: absolute;
        top: 12px;
        right: 20px;
      }
    }

    .flights-list {
      margin-top: 8px;
      @media (min-width: 1100px) {
        max-height: 63vh;
      }
      @media (max-width: 1100px) {
        max-height: 45vh;
      }
      overflow-y: auto;
      .flight {
        background: var(--brand-off-white);
        &:not(:last-of-type) {
          margin-bottom: 32px;
        }
        position: relative;
        .flight-timeline {
          @media (min-width: 1100px) {
            display: inline-block;
            width: calc(100% - 189px);
            border-right: 1px solid var(--brand-white);
          }
          @media (max-width: 1100px) {
            width: 100%;
            border-bottom: 1px solid var(--brand-off-black);
          }
        }
        .flight-price {
          @media (min-width: 1100px) {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            width: 189px;
            padding: 24px;
            position: absolute;
            top: 0;
            .price-info {
              display: block;
              text-align: right;
              .body1-strong {
                display: block;
                color: var(--brand-off-black);
              }
              .caption {
                display: block;
                color: var(--brand-mid-grey);
                margin-bottom: 16px;
              }
            }
            .price-actions {
              display: block;
            }
          }
          @media (max-width: 1100px) {
            width: 100%;
            height: 52px;
            padding: 8px 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .price-info {
              .body1-strong {
                display: block;
              }
              .caption {
                display: block;
                color: var(--brand-mid-grey);
              }
            }
          }
        }
        .flight-outbound {
          @media (min-width: 1100px) {
            padding: 24px;
            height: 112px;
          }
          @media (max-width: 1100px) {
            padding: 16px;
            height: 96px;
          }
        }
        .flight-return {
          @media (min-width: 1100px) {
            padding: 24px;
            height: 112px;
          }
          @media (max-width: 1100px) {
            padding: 16px;
            height: 96px;
          }
        }
        &.two-way {
          .flight-price {
            @media (min-width: 1100px) {
              height: calc(112px * 2);
            }
          }
          .flight-outbound {
            border-bottom: 1px solid var(--brand-white);
            border-radius: 3px 3px 0px 0px;
          }
          .flight-return {
            border-radius: 3px 3px 0px 0px;
          }
        }
        &.one-way {
          .flight-price {
            @media (min-width: 1100px) {
              height: 112px;
            }
          }
          .flight-outbound {
            border-radius: 3px;
          }
        }
        .flight-title {
          width: 100%;
          margin-bottom: 8px;
          color: var(--text-secondary);
        }
        .flight-info {
          .first-block {
            display: inline-block;
            width: 40px;
            height: 40px;
            margin-right: 8px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 50%;
              border: 1px solid var(--brand-off-black);
            }
          }
          .second-block {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 80px;
            height: 40px;
            vertical-align: top;
            margin-right: 8px;
            .body2-strong {
              display: block;
            }
            .caption {
              display: block;
            }
          }
          .third-block {
            display: inline-block;
            height: 40px;
            vertical-align: top;
            width: calc(100% - 40px - 8px - 80px - 8px - 80px - 8px);
            position: relative;
            text-align: center;
            .num-stops {
              height: calc(40px / 2);
              .small {
                position: relative;
                top: -2px;
              }

              &.direct {
                .small {
                  color: var(--validation-positive);
                }
              }
            }
            .duration {
              height: calc(40px / 2);
              .small {
                position: relative;
                top: -1px;
              }
            }
            .flight-divider {
              width: 100%;
              position: relative;
              top: -1px;
              .divider {
                width: 100%;
                height: 1px;
                background-color: var(--brand-off-black);
                position: absolute;
                top: 1px;
              }
              .dot {
                background-color: var(--brand-off-black);
                height: 7px;
                width: 7px;
                border-radius: 50%;
                &.first {
                  position: absolute;
                  top: -2px;
                  left: 0px;
                }
                &.second {
                  position: absolute;
                  top: -2px;
                  right: 0;
                }
              }
            }
          }
          .fourth-block {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            height: 40px;
            vertical-align: top;
            width: 80px;
            margin-left: 8px;
            text-align: right;
            .body2-strong {
              display: block;
            }
            .caption {
              display: block;
            }
          }
        }
      }
    }

    .content-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (min-width: 1100px) {
        padding: 120px 0;
      }

      @media (max-width: 1100px) {
        padding: 120px 24px;
      }

      text-align: center;

      .empty-title {
        white-space: wrap;

        @media (min-width: 1100px) {
          width: 775px;
        }

        .heading3 {
          color: var(--brand-off-black);

          &.underline {
            color: var(--brand-blue) !important;
          }
        }
      }

      .empty-subtitle {
        margin-top: 24px;
        color: var(--brand-mid-grey);

        .subtitle-tooltip {
          i {
            display: inline-block;
            margin-right: 8px;
            position: relative;
            top: 3px;
          }
        }
      }

      .empty-icon {
        margin-top: 24px;
      }

      &.dark {
        .empty-title {
          .heading3 {
            color: var(--brand-white);

            &.underline {
              color: var(--brand-white) !important;
            }
          }
        }
      }

      &.not-list {
        @media (min-width: 1100px) {
          max-width: 100vw;
        }
      }
    }
  }

  .el-overlay {
    .left-drawer {
      .el-drawer__body {
        .el-collapse {
          margin-top: 32px;
          margin-bottom: 32px;
          border: 0 !important;
          .el-collapse-item {
            border: 0 !important;
            :deep(.el-collapse-item__header) {
              background-color: var(--brand-white) !important;
              border: 0 !important;
              position: relative;
              i.el-icon {
                svg {
                  display: none;
                }
              }
              i.filter-icon {
                position: absolute;
                right: 0 !important;
              }
            }
            :deep(.el-collapse-item__wrap) {
              border-bottom: 1px solid var(--brand-white) !important;
              .el-collapse-item__content {
                background-color: var(--brand-white) !important;
                .filter-flights-container {
                  max-height: 300px;
                  padding-bottom: 10px;
                  overflow-y: auto;
                  .stop {
                    display: flex;
                    &:not(:last-of-type) {
                      margin-bottom: 10px;
                    }
                    span.body2 {
                      margin-left: 8px;
                    }

                    padding-top: 16px;
                  }
                }
                .form-price-label {
                  margin-top: 16px;
                }
                .form-price {
                  padding: 16px;
                }
                .form-price-input-from,
                .form-price-input-to {
                  width: calc(50% - 4px);
                  display: inline-block;
                }

                .form-price-input-from {
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>