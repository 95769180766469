import { useI18n } from 'vue-i18n';
import { flightTypes } from '~/models/flight-types';

export const getFlightTypes = () => {
  const { t } = useI18n();
  return flightTypes.map((item) => {
    return {
      label: t('flight_types.' + item.toLowerCase()),
      value: item.toUpperCase(),
      selected: false
    };
  });
}
